import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import reducer from "./reducers";
import reducerSubscription from "../components/souscription/store/reducerSouscription";
import userReducer from "../components/CreateUser/store/userReducer"

const reducers = combineReducers({
  global: reducer,
  reducerSubscription,
  userReducer,
});
const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunkMiddleware),
    process.env.NODE_ENV === "development"
    // window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : function (f) {
          return f;
        }
  )
);

export default store;

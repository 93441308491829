import React from "react";
import { useHistory, useLocation } from "react-router";
import NavBarHome from "../../home/NavBarHome";
import Footer from "../../Footer/Footer";

const SuccesIncl = () => {
  const history = useHistory();
  const location = useLocation();
  const numSinistre = location.state;
  const onSubmit = () => {
    history.push("/");
  };
  return (
    <>
    <NavBarHome dark relative />
      <h2 className="succ" onClick={onSubmit}>
        Nous vous confirmons que votre déclaration de sinistre N°{numSinistre} a
        bien été enregistrée
      </h2>
      <Footer />
      </>
  );
};

export default SuccesIncl;

import React from "react";

const Rembourssement = () => {
  return (
    <div className="body-sinistre">
      <div className="tab-scroll">
        <div className="row col-md-12 tab-sinistres margin0">
          <div className="row col-md-12 tab-container">
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Numéro de contrat</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Numéro de contrat</h6>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-3 col-3">
              <h6 className="title-tab">Sinistres</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Montant remboursé</h6>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <img width="100" src="/assets/images/dash-3.svg" />
            <h3>Vous n'avez pas encore de remboursement en cours</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rembourssement;

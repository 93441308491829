import React, { useEffect, useState } from "react";
import "./Souscription.css";
import { useHistory, useLocation } from "react-router";
import Stepper from "./Components/Stepper";
import NavBar from "./Components/NavBar";
import { useSelector, useDispatch } from "react-redux";
import InputCustom from "../SubComponents/inputCustom/InputCustom";
import SelectCustom from "../SubComponents/selectCustom/SelectCustom";
import InputFileCustom from "../SubComponents/inputFileCustom/InputFileCustom";
import adminService from "../../services/adminService/adminService";
import { setStepTwoAction } from "./store/actionSouscription";
import moment from "moment";
import { toast } from "react-toastify";
import contractService from "../../services/contract/contractService";
const SouscriptionPage2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const detailPhone = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step0
  );
  const formule = useSelector(({ userReducer }) => userReducer.formule);
  const [list, setList] = useState([]);
  const [listMarque, setListMarque] = useState([]);
  const stateStep2 = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step2
  );
  const [listProductInfo, setListProductInfo] = useState({});
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setListProductInfo((listProductInfo) => ({
      ...listProductInfo,
      [name]: { value, id },
    }));
  };
  //console.log(listProductInfo);
  const onSubmit = async (e) => {
    e.preventDefault();
    // if(list[0]?.product?.name === "Smartphone"){
    //console.log(listProductInfo);
    // if (
    //   !(list[0]?.product?.name === "Optique") &&
    //   !(
    //     listProductInfo["Numero serie"]?.value.length >= 15 &&
    //     listProductInfo["Numero serie"]?.value.length <= 17
    //   )
    // ) {
    //   toast.error("Votre IMEI doit contenir entre 15 et 17 chiffres");
    // } else
    if (Object.keys(listProductInfo).length == list.length - 2) {
      let bodyFormData = new FormData();
      if (list[0]?.product?.name === "Smartphone") {
        bodyFormData.append("file", listProductInfo.photoAv.value);
        await contractService.upload(bodyFormData).then((res) => {
          let img1 = { value: res.data, id: listProductInfo?.photoAv?.id };
          let date = {
            value: moment(detailPhone.dateAchat).format("YYYY-MM-DD"),
            id: list[2].idproductInfo,
          };
          let valeurP = {
            value: detailPhone.amount,
            id: list[3].idproductInfo,
          };
          setListProductInfo((listProductInfo) => ({
            ...listProductInfo,
            photoAv: img1,
            dateachat: date,
            Valeurduproduit: valeurP,
          }));
        });
        setTimeout(() => {
          history.push({
            pathname: "/Souscription/Etape3",
          });
        }, 1500);
      } else {
        bodyFormData.append("file", listProductInfo.photoAv.value);
        await contractService.upload(bodyFormData).then((res) => {
          let img1 = { value: res.data, id: listProductInfo?.photoAv?.id };
          let date = {
            value: moment(detailPhone.dateAchat).format("DD/MM/YYYY"),
            id: list[2].idproductInfo,
          };
          let valeurP = {
            value: detailPhone.amount,
            id: list[3].idproductInfo,
          };
          setListProductInfo((listProductInfo) => ({
            ...listProductInfo,
            photoAv: img1,
            dateachat: date,
            Valeurduproduit: valeurP,
          }));
        });
        setTimeout(() => {
          history.push({
            pathname: "/Souscription/Etape3",
          });
        }, 1500);
      }
    } else {
      toast.error("Tous les champs sont obligatoires.");
    }
  };
  dispatch(setStepTwoAction(listProductInfo));

  React.useEffect(() => {
    if (stateStep2) {
      setListProductInfo(stateStep2);
    }
  }, [stateStep2]);
  React.useEffect(() => {
    if (!formule) {
      history.push("/");
    } else
      adminService
        .getProductByName(formule?.fomula?.family?.familyName)
        .then((res) => {
          adminService.getMarqueByProduct(res.data.idproduct).then((res) => {
            setListMarque(res.data);
          });
          adminService.getProductInfo(res.data.idproduct).then((res) => {
            //console.log(res.data);
            setList(res.data.sort((a, b) => a.orderDisplay - b.orderDisplay));
          });
        });
  }, []);
  console.log(list);
  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12 margin0">
      <NavBar text="Souscription" />

      <Stepper activeStep={0} />

      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 form-souscription d-flex-j-c">
        <div className="row col-md-8">
          <p className="col-md-12 mon-smartphone">
            {list[0]?.product?.name === "Optique"
              ? "Mes lunettes"
              : "Mon " + list[0]?.product?.name}
          </p>
          {list?.map((el, key) => {
            if (el.type == "select")
              return (
                <SelectCustom
                  id={el.idproductInfo}
                  value={listProductInfo[el.name]?.value}
                  key={key}
                  label={el.label}
                  onChange={handleChange}
                  name={el.name}
                  required
                  mrq={listMarque}
                />
              );
            if (el.label == "Prix d’achat")
              return (
                <InputCustom
                  key={key}
                  id={el.idproductInfo}
                  value={detailPhone.amount}
                  onChange={handleChange}
                  name={el.name}
                  label={el.label}
                  type={el.type}
                  disabled
                />
              );
            if (
              el.label == "Date d’achat inférieur à 1 mois" ||
              el.label == "Date d’achat"
            )
              return (
                <InputCustom
                  key={key}
                  id={el.idproductInfo}
                  value={moment(detailPhone.dateAchat).format("DD/MM/YYYY")}
                  onChange={handleChange}
                  name={el.name}
                  label={el.label}
                  disabled
                />
              );
            if (el.type == "text")
              return (
                <InputCustom
                  key={key}
                  id={el.idproductInfo}
                  value={listProductInfo[el.name]?.value || ""}
                  onChange={handleChange}
                  name={el.name}
                  label={el.label}
                  type={el.type}
                  required
                />
              );
          })}
          <p
            className="col-md-12 mon-smartphone"
            style={{ marginTop: "40px", marginBottom: "10px" }}
          >
            Téléchargez la photo de votre {list[0]?.product?.name} avec le
            numéro{" "}
            {list[0]?.product?.name == "Smartphone" ? "IMEI" : "de série"}
          </p>
          {list[0]?.product?.name == "Smartphone" ? (
            <p style={{ fontSize: "12px" }}>
              Tapez: *#06# sur le clavier de votre mobile et faites une capture
              d’écran
            </p>
          ) : (
            <></>
          )}
          {list?.map((el, key) => {
            if (el.type === "file")
              return (
                <InputFileCustom
                  id={el.idproductInfo}
                  key={key}
                  onChange={handleChange}
                  name={el.name}
                  label={el.label}
                  required
                />
              );
          })}

          <p className="col-md-12 mon-smartphone" style={{ marginTop: "7px" }}>
            Ma Garantie
          </p>
          <div className="col-md-5">
            <div
              className="div-extend-formule"
              style={{ width: "80%", height: "auto" }}
            >
              <h3 className="text-align">Formule</h3>
              <img
                className="img-formule-extend"
                style={{ maxWidth: "150px" }}
                src={
                  `${process.env.PUBLIC_URL}/assets/images/` +
                  formule?.fomula.name +
                  ".svg"
                }
              />
              <div className="d-flex">
                <p
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    marginTop: "15%",
                    fontFamily: "Century Gothic",
                  }}
                >
                  {(formule?.fomula.price /12)?.toFixed(3)} DT / mois
                </p>
                <p>{(formule?.fomula.price)?.toFixed(3)} DT / an</p>

                {formule.listBreakDown?.map((br, ke) =>
                  br.value ? (
                    <div className="row col-md-12 convention-formule" key={ke}>
                      <i class="fa fa-check sous col-md-2"></i>
                      <p className="col-md-10" style={{ fontSize: "12px" }}>
                        {br.name}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )
                )}
                {formule?.fomula?.vol_protection ? (
                  <div className="row col-md-12 detail-formule">
                    <p
                      className="col-md-12"
                      style={{
                        fontSize: "14px",
                        color: "#e31279",
                        fontWeight: "700",
                      }}
                    >
                      Option VOL
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="row col-md-12 convention-formule">
                <p
                  className="col-md-10"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  Plafond de garantie {formule?.fomula.ceilingGuarantee} DT
                </p>
              </div>
              {formule?.fomula?.vol_protection ? (
                <div className="row col-md-12 convention-formule">
                  <p
                    className="col-md-10"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "15px",
                    }}
                  >
                    Plafond de garantie VOL{" "}
                    {formule?.fomula.ceiling_guarantee_vol} DT
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <button
              type="button"
              id="souscription-etape1"
              class="btn btn-primary btn-continuer"
              onClick={onSubmit}
            >
              Continuer
            </button>
            <button
              type="button"
              class="btn btn-primary btn-annuler"
              onClick={() => history.goBack()}
            >
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SouscriptionPage2;

import React, { useEffect, useState } from "react";
import contractService from "../../../services/contract/contractService";
import { AiOutlineDownload } from "react-icons/ai";
import FileSaver from "file-saver";
import moment from "moment";
const MesDoc = () => {
  const [list, setList] = useState([]);
  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("user")).id;
    contractService.getAllContractsByCustomer(id).then((response) => {
      setList(response.data);
    });
  }, []);
  console.log(list);
  const downloadPdf = (data, el) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    FileSaver.saveAs(blob, "contrat " + el.cin);
  };

  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Mes documents Télécharger
      </h4>
      <div className="tab-scroll">
        <div className="row col-md-12 tab-sinistres margin0">
          <div className="row col-md-12 tab-container">
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">N° contrat/ Formule/ Marque</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Date d'effet</h6>
            </div>
            <div className="col-md-3 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Date d'échéance</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Modéle</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Produit</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Télécharger</h6>
            </div>
          </div>
          {list.length != 0 ? (
            list?.map((el, key) => {
            if ( el.status === "V" || el.canalVente === "Distributeur") 
                return (
                  <div className="row col-md-12 row-tab">
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{el.formattedName}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{moment(el.startDate).format("DD/MM/yyyy")}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{moment(el.endDate).format("DD/MM/yyyy")}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                      {el.family == "Optique" ? el.marque : el.model}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family}
                      </h6>
                    </div>
                    <div
                      className="col-md-2 col-lg-2 col-sm-2 col-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          contractService
                            .generateContratBase64(el.idcontract)
                            .then(async (res) => {
                              const words = res.data.split("%%EOF");
                              await downloadPdf(words[1], el);
                            });
                        }}
                      >
                        <AiOutlineDownload className="doc-download" />
                      </div>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2"></div>
                  </div>
                );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <img width="100" src="/assets/images/dash-1.svg" />
              <h3>Vous n'avez pas encore des documents d'assurance actif</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MesDoc;

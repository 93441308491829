const couvert = [
    {
        tag: 'TV',
        elems: [
            {
                src: '/assets/images/icons/TV/',
                title: "Casse toutes causes"
            },
            {
                src: '/assets/images/icons/TV/',
                title: "Oxydation"
            },
            {
                src: '/assets/images/icons/TV/',
                title: "Infiltration"
            },
            {
                src: '/assets/images/icons/TV/',
                title: 'Haut-parleur dysfonctionnel'
            },
            {
                src: '/assets/images/icons/TV/',
                title: "Écran TV cassé"
            },
        ]
    }, {
        tag: 'PC Portable',
        elems: [
            {
                src: '/assets/images/icons/PC Portable/',
                title: "Pc Gamer"
            },
            {
                src: '/assets/images/icons/PC Portable/',
                title: "Haut-parleur dysfonctionnel"
            },
            {
                src: '/assets/images/icons/PC Portable/',
                title: "Casse du clavier"
            },
            {
                src: '/assets/images/icons/PC Portable/',
                title: "Oxydation & Humidité"
            },
            {
                src: '/assets/images/icons/PC Portable/',
                title: "Écran cassé"
            },
            {
                src: '/assets/images/icons/PC Portable/',
                title: "Infiltration"
            },
            {
                src: '/assets/images/icons/PC Portable/',
                title: "Tactile dysfonctionnel"
            },

            {
                src: '/assets/images/icons/PC Portable/',
                title: "Coque brisée"
            },
        ]
    },
    {
        tag: 'MacBook',
        elems: [
            {
                src: '/assets/images/icons/MacBook/',
                title: "Haut-parleur dysfonctionnel"
            },
            {
                src: '/assets/images/icons/MacBook/',
                title: "Casse du clavier"
            },
            {
                src: '/assets/images/icons/MacBook/',
                title: "Oxydation & Humidité"
            },
            {
                src: '/assets/images/icons/MacBook/',
                title: "Écran cassé"
            },
            {
                src: '/assets/images/icons/MacBook/',
                title: "Coque brisée"
            },
            {
                src: '/assets/images/icons/MacBook/',
                title: "Tactile dysfonctionnel"
            },
        ]
    },
    {
        tag: 'Tablette',
        elems: [
            {
                src: '/assets/images/icons/Tablette/',
                title: "Caméra cassée"
            },
            {
                src: '/assets/images/icons/Tablette/',
                title: "Face arrière brisée"
            },
            {
                src: '/assets/images/icons/Tablette/',
                title: "Tactile dysfonctionnel"
            },
            {
                src: '/assets/images/icons/Tablette/',
                title: "Écran cassé"
            },
            {
                src: '/assets/images/icons/Tablette/',
                title: "Micro et haut-parleur dysfonctionnels"
            },
            {
                src: '/assets/images/icons/Tablette/',
                title: "Infiltration"
            },
        ]
    },
    {
        tag: 'Montre Connectée',
        elems: [
            {
                src: '/assets/images/icons/Montre Connectée/',
                title: "Écran cassé"
            },
            {
                src: '/assets/images/icons/Montre Connectée/',
                title: "Infiltration"
            },
            {
                src: '/assets/images/icons/Montre Connectée/',
                title: "Tactile dysfonctionnel"
            },
            {
                src: '/assets/images/icons/Montre Connectée/',
                title: "Micro & haut parleur"
            },
        ]
    },

]
module.exports = { couvert }
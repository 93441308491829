const couvert = [
  {
    elems: [
      {
        src: "/assets/images/ecran ca",
        title: "Casse accidentelle",
      },
      {
        src: "/assets/images/humi",
        title: "Oxydation & Humidité",
      },
      {
        src: "/assets/images/infiltra",
        title: "Infiltration",
      },
      {
        src: "/assets/images/assis",
        title: "Assistance téléphonique",
      },
      {
        src: "/assets/images/court",
        title: "Court circuit",
      },
    ],
  },
];
module.exports = { couvert };

import React from "react";
import NavBar from "./Components/NavBar";

const Error404 = () => {
  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12 margin0">
      <NavBar />
      <div>
        <div
          style={{
            marginTop: "6%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "25px", fontFamily: "system-ui" }}>
          Votre paiement a échoué. Prière de contacter votre Banque
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error404;

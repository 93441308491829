import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import NavBarHome from "../home/NavBarHome";
import "./SmartPhoneDetails.css";
import DatePicker, { registerLocale } from "react-datepicker";
import { addDays } from "date-fns";
import { subDays } from "date-fns";
import { useDispatch } from "react-redux";
import { savedataStepZero } from "../souscription/store/actionSouscription";
import formuleService from "../../services/formule/formuleService";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import familyService from "../../services/family/familyService";
import fr from "date-fns/locale/fr";
import DocumentMeta from "react-document-meta";
import { saveFormule } from "../CreateUser/store/userAction";
import { Accordion } from "react-bootstrap";
import data from "../HomePageFooter/helpData.json";
import adminService from "../../services/adminService/adminService";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Input,
  Label,
} from "reactstrap";
registerLocale("fr", fr);
const Smartphonedetails = () => {
  const meta = {
    title: "Assurance & Garantie Smartphone , Casse ou Infiltration By Garanty",
    description:
      "Formules de garantie et assurance pour téléphone contre casse, infiltration, oxydation. Souscription du contrat d'assurance et déclaration de sinistre en ligne sur Garanty",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      'http-equiv': {
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
        expires: '0',
        pragma: 'no-cache',
      },
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [formuleList, setFormuleList] = useState([]);
  const [keyFormula, setKeyFormula] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [simulation, setSimulation] = useState([]);
  const [showData, setShowData] = useState(true);
  const [allData, setAllData] = useState(data);
  const [oppen, setOppen] = useState(false);
  const [volFormula, setVolFromula] = useState(null);
  const [fo, setFo] = useState(null);
  const [vol, setVol] = useState(false);
  const modaltoggle = () => {
    setOppen(!oppen);
  };
  const subscribe = (index) => {
    setFo(simulation[index]);
    let idF = simulation[index].fomula.idformula;
    // formuleService.getFormulaByParentId(idF).then((res) => {
    //   if (res.status == 200) {
    //     setVolFromula(res.data);
    //     modaltoggle();
    //     console.log(simulation);
    //   } else {
    dispatch(saveFormule(simulation[index]));
    if (localStorage.getItem("user")) {
      history.push({
        pathname: "/Souscription/Etape1",
      });
    } else {
      history.push({
        pathname: "/connexion",
      });
    }
    // }
    // });
  };
  const contunierVolOption = () => {
    if (vol) {
      dispatch(saveFormule({ ...fo, fomula: volFormula }));
      if (localStorage.getItem("user")) {
        history.push({
          pathname: "/Souscription/Etape1",
        });
      } else {
        history.push({
          pathname: "/connexion",
        });
      }
    } else {
      dispatch(saveFormule(fo));
      if (localStorage.getItem("user")) {
        history.push({
          pathname: "/Souscription/Etape1",
        });
      } else {
        history.push({
          pathname: "/connexion",
        });
      }
    }
  };
  const [formuleDto, setFormuleDto] = useState({
    amount: "",
    dateAchat: "",
    idFamille: location.state,
  });
  const [familyList, setFamilyList] = useState();
  const [newsletter, setNewsletter] = useState({ email: "" });
  const handelChange = (e) => {
    const { name, value } = e.target;
    setNewsletter((newsletter) => ({ ...newsletter, [name]: value }));
  };
  useEffect(() => {
    familyService.getAllFamily().then((res) => {
      setFamilyList(res.data.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 4 ));
    });
    formuleService.getAllFormulaById(location.state).then((res) => {
      setFormuleList(
        res?.data?.sort((a, b) => a?.fomula?.price - b?.fomula?.price).filter((el=> el.fomula.other == 1))
      );
    });
    localStorage.setItem("idFamily", location.state);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormuleDto((formuleDto) => ({ ...formuleDto, [name]: value }));
  };

  const Continuer = async (e) => {
    e.preventDefault();
    if (formuleDto.amount && formuleDto.dateAchat) {
      localStorage.setItem("amount", formuleDto.amount);
      dispatch(savedataStepZero(formuleDto));
      await formuleService
        .calculateFormula(formuleDto)
        .then(async (response) => {
          if (response.status === 200) {
            setSimulation(response.data);
            setShow(true);
            window.scrollTo(0, 900);
          } else {
            toast.error("Format incorrect du coût d'achat");
          }
        });
    } else if (!formuleDto.amount) {
      toast.error("Enter votre prix d'achat du produit");
    } else if (!formuleDto.dateAchat) {
      toast.error("Enter votre date d'achat du produit");
    }
  };
  return (
    <DocumentMeta {...meta} className="container-fluid">
      <NavBarHome listFamily={familyList} />
      <div className="row col-md-12 header-container-slidee1"></div>
      <div className="row col-md-12 margin0 d-flex-j-c sumilateur">
        <div className="col-md-12 ">
          <h1 className="enfin-garantie" style={{ color: "#232e85" }}>
            Enfin une Garantie smartphone...
          </h1>
          <p className="simulez-prix1" style={{ color: "#232e85" }}>
            Simulez le tarif de votre Garantie Smartphone
          </p>
          <div className="row col-md-12 simulation-input">
            <div className="row col-md-8">
              <div className="col-md-4 aide">
                <input
                  name="amount"
                  type="number"
                  className="input-prix"
                  placeholder="Coût d'achat"
                  value={formuleDto.amount}
                  onChange={handleChange}
                  required
                />
                <div class="invalid-feedback">Please provide an amount.</div>
              </div>
              <div className="col-md-4 aide">
                <DatePicker
                  selected={startDate}
                  minDate={subDays(new Date(), 30)}
                  maxDate={addDays(new Date(), 0)}
                  dateFormat="dd/MM/yyyy"
                  className="input-prix"
                  locale="fr"
                  placeholderText="Date d’achat"
                  value={formuleDto.dateAchat}
                  onChange={(date) => {
                    setStartDate(date);
                    handleChange({
                      target: { name: "dateAchat", value: date },
                    });
                  }}
                />
              </div>
              <div className="col-md-4 aide div-btn-calculate">
                <div className="calcule-btn">
                  <button
                    id="continuer"
                    className="calcule-btn"
                    onClick={Continuer}
                  >
                    Continuer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-lg-12 col-md-12 formule-offer"
        style={!show ? { display: "none" } : {}}
      >
        <div className="row col-md-8">
          <div
            className="col-md-12 text-center"
            style={{ marginBottom: "30px", marginTop: "50px" }}
          >
            <h2 className="simulez-prix">
              LA MEILLEURE PROTECTION POUR VOTRE SMARTPHONE
            </h2>
          </div>
          <div className="formula-bloc">
            {simulation.map((el, i) => (
              <div className="formule-bloc">
                <div className="div-extend-formule" style={{ height: "auto" }}>
                  <h3 className="text-align">Formule</h3>
                  <img
                    className="img-formule-extend"
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      el?.fomula?.name +
                      ".svg"
                    }
                  />
                  <div
                    className="d-flex"
                    style={
                      i <= 0 && el?.fomula?.ceilingGuarantee < 900
                        ? { marginBottom: "48px" }
                        : i <= 1 && el?.fomula?.ceilingGuarantee <= 900
                        ? { marginBottom: "25px" }
                        : {}
                    }
                  >
                    <p
                      style={{
                        fontSize: "28px",
                        fontWeight: "bold",
                        marginTop: "15%",
                        fontFamily: "Century Gothic",
                      }}
                    >
                      {(el?.fomula?.price/12)?.toFixed(3)} DT / mois
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "6px",
                      }}
                    >
                      {el?.fomula?.price?.toFixed(3)} DT / an
                    </p>
                    <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Prix de smartphone de {el?.fomula?.minValueProd}
                      {el?.fomula?.maxValueProd >= 9998
                        ? " et +"
                        : `${-el?.fomula?.maxValueProd} DT`}
                    </p>

                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        1 Smartphone couvert
                      </p>
                    </div>

                    {el?.listBreakDown?.map((br, ke) =>
                      br.value ? (
                        <>
                          <div
                            key={ke}
                            className="row col-md-12 detail-formule"
                          >
                            <p
                              className="col-md-10"
                              style={{ fontSize: "12px" }}
                            >
                              {br.name}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-10" style={{ fontSize: "12px" }}>
                        {el?.fomula?.nbrIntervYear > 1
                          ? "2 interventions /an"
                          : "1 intervention /an"}
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        Assistance téléphonique 7J/7
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p
                        className="col-md-12"
                        style={{
                          fontSize: "14px",
                          color: "#e31279",
                          fontWeight: "700",
                        }}
                      >
                        Option VOL
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        marginTop: "15px",
                      }}
                    >
                      Plafond de garantie {el?.fomula?.ceilingGuarantee} DT
                    </p>
                  </div>
                  <div
                    className="simulation-btn"
                    key={i}
                    onClick={() => subscribe(i)}
                    style={{ marginTop: "20px" }}
                  >
                    <p
                      style={{
                        font: "normal normal bold 14px/40px Century Gothic",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                      }}
                    >
                      Souscrire
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 specialiste-ass"
        style={{ paddingTop: "30px", marginTop: "5%" }}
      >
        <h2
          className="title-footer col-lg-9 col-md-10 col-sm-12 col-12"
          style={{ marginBottom: "10px" }}
        >
          COMMENT ÇA MARCHE?
        </h2>
        <div
          className="row col-md-10 col-sm-10"
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "0",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">1</div>

              <p className="parg-marche">
                Simulez le prix de votre garantie en indiquant le coût et la
                date d'achat de votre produit.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">2</div>

              <p className="parg-marche">
                Créez votre compte Garanty en 2 minutes, complétez vos
                informations personnelles puis validez.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">3</div>
              <p className="parg-marche">
                Souscrivez en indiquant les informations personnelles. Ajoutez
                les documents demandés (CIN et la photo de votre équipement)
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">4</div>
              <p className="parg-marche">
                Téléchargez la notice d'information et prenez connaissance des
                conditions générales
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div
              className="bloc-marche"
              style={{ background: "linear-gradient(#fd8cc4, #e31279)" }}
            >
              <p className="parg-marche" style={{ color: "#ffffff" }}>
                Payez et signez votre bulletin d'adhésion. Votre équipement est
                désormais protégé.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-lg-12 col-md-12 formule-offer"
        style={{ marginTop: "5%" }}
      >
        <div
          className="row col-lg-12 col-md-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-12 col-lg-12 col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="col-md-12 text-center">
              <h2 className="simulez-prix">
                NOS FORMULES DE GARANTIES SMARTPHONES
              </h2>
              <p className="col-8" style={{ marginBottom: "3%" }}>
                Nous vous proposons plusieurs formules de garantie selon le prix
                d’achat de votre téléphone portable. Découvrez le détail de
                chaque formule et assurez votre smartphone pour une utilisation
                en toute sérénité !
              </p>
            </div>
            <div
              className="col-xl-12 col-lg-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="form1" style={{ paddingRight: "1%" }}>
                {formuleList.map((el, index) => (
                  <div
                    className={
                      keyFormula === index
                        ? "col-12 formula-select"
                        : "col-12 formula"
                    }
                    key={index}
                    onClick={() => {
                      setKeyFormula(index);
                    }}
                  >
                    <div
                      className="form"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3
                        className="dispnone"
                        style={
                          keyFormula === index
                            ? { color: "#ffffff", paddingLeft: "5px" }
                            : { color: "#888888", paddingLeft: "5px" }
                        }
                      >
                        Formule
                      </h3>
                      <img
                        className={
                          keyFormula === index
                            ? "img-formula-select"
                            : "img-formula"
                        }
                        src={
                          `${process.env.PUBLIC_URL}/assets/images/` +
                          el.fomula?.name +
                          ".svg"
                        }
                      />
                    </div>
                    <div className="col-2 dispnone"></div>
                    <div className="col-4 text-formula">
                      <p
                        style={
                          keyFormula === index
                            ? { color: "#ffffff" }
                            : { color: "#888888" }
                        }
                      >
                        {(el?.fomula?.price/12)?.toFixed(3)} DT / mois
                      </p>
                      <p
                        style={
                          keyFormula === index
                            ? { color: "#ffffff" }
                            : { color: "#888888" }
                        }
                      >
                        {el?.fomula?.price?.toFixed(3)} DT / an
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="form2" style={{ paddingLeft: "1%" }}>
                <div className="div-extend-formule">
                  <h3 className="text-align">Formule</h3>
                  <img
                    className="img-formule-extend"
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      formuleList[keyFormula]?.fomula?.name +
                      ".svg"
                    }
                  />
                  <div className="d-flex">
                    <p
                      style={{
                        fontSize: "23px",
                        fontWeight: "bold",
                        marginTop: "15%",
                        fontFamily: "Century Gothic",
                      }}
                    >
                      {(formuleList[keyFormula]?.fomula?.price / 12).toFixed(3)}{" "}
                      DT / mois
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "6px",
                      }}
                    >
                      {formuleList[keyFormula]?.fomula?.price?.toFixed(3)} DT /
                      an
                    </p>
                    <p style={{ fontWeight: "bold", fontSize: "11px" }}>
                      Prix de smartphone de{" "}
                      {formuleList[keyFormula]?.fomula?.minValueProd}
                      {formuleList[keyFormula]?.fomula?.maxValueProd >= 9998
                        ? " et +"
                        : `${-formuleList[keyFormula]?.fomula
                            ?.maxValueProd} DT`}
                    </p>

                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        1 Smartphone couvert
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-10" style={{ fontSize: "12px" }}>
                        {formuleList[keyFormula]?.fomula?.nbrIntervYear > 1
                          ? "2 interventions /an"
                          : "1 intervention /an"}
                      </p>
                    </div>
                    {formuleList[keyFormula]?.listBreakDown?.map((br, ke) =>
                      br.value ? (
                        <>
                          <div
                            key={ke}
                            className="row col-md-12 detail-formule"
                          >
                            <p
                              className="col-md-10"
                              style={{ fontSize: "12px" }}
                            >
                              {br.name}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                    <div className="row col-md-12 detail-formule">
                      <p
                        className="col-md-12"
                        style={{
                          fontSize: "14px",
                          color: "#e31279",
                          fontWeight: "700",
                        }}
                      >
                        Option VOL
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        marginTop: "15px",
                      }}
                    >
                      Plafond de garantie{" "}
                      {formuleList[keyFormula]?.fomula?.ceilingGuarantee} DT
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 d-flex-j-c" style={{ marginTop: "5%" }}>
        <div className="col-md-8">
          <h2 className="title-bloc-couvert">
            NOUS AVONS CHOISI LES MEILLEURES GARANTIES POUR VOUS
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{ marginRight: "0", marginTop: "6%" }}
          >
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert"
                src={`${process.env.PUBLIC_URL}/assets/images/12.png`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "30px",
                }}
              >
                <h3 className="title-parahraphe-couvert">
                  Oxydation & Humidité
                </h3>
                <p className="parahraphe-couvert">
                  Un choc thermique important qui crée de la condensation ou
                  simplement l’humidité d’une salle de bain peuvent suffire à
                  oxyder les composants internes de votre smartphone.
                  L’oxydation empêche la circulation électrique dans votre
                  portable, qui devient inutilisable. Mais pas de soucis, vous
                  êtes aussi couverts!
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert"
                src={`${process.env.PUBLIC_URL}/assets/images/21.png`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "45px",
                }}
              >
                <h3 className="title-parahraphe-couvert">Infiltration</h3>
                <p className="parahraphe-couvert">
                  Malheureusement, tous les modèles ne sont pas waterproof… Nous
                  réparons ou remplaçons votre smartphone en cas de dommages dus
                  à des infiltrations. Cependant sachez que les dégâts liés à
                  une chute dans une piscine ou dans des toilettes ne sont pas
                  couverts.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert"
                src={`${process.env.PUBLIC_URL}/assets/images/31.png`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "52px",
                }}
              >
                <h3 className="title-parahraphe-couvert">Écran Cassé</h3>
                <p className="parahraphe-couvert">
                  Vous avez fait tomber votre téléphone accidentellement et il
                  ne fonctionne plus ? Déclarez simplement votre sinistre en
                  ligne ou par téléphone. Une fois votre déclaration approuvée,
                  un de nos réparateurs réparera ou remplacera votre smartphone.
                  À noter que les dommages purement esthétiques ne rentrent pas
                  dans cette catégorie.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert"
                src={`${process.env.PUBLIC_URL}/assets/images/41.png`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "34px",
                }}
              >
                <h3 className="title-parahraphe-couvert">Court-Circuit</h3>
                <p className="parahraphe-couvert">
                  Un choc thermique important qui crée de la condensation ou
                  simplement l'humidité d'une salle de bain peuvent suffir à
                  oxyder les composants internes de votre smartphone.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert"
                src={`${process.env.PUBLIC_URL}/assets/images/51.png`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "43px",
                }}
              >
                <h3 className="title-parahraphe-couvert">
                  Casse totale accidentelle
                </h3>
                <p className="parahraphe-couvert">
                  Votre téléphone est assuré dans son intégralité. Au même titre
                  que l'écran, les boutons et pièces extérieures sont couverts
                  par notre garantie, si les dégâts reçus ne vous permettent
                  plus d'utiliser votre smartphone correctement. Soit on répare
                  l'appareil, soit on le remplace par un autre appareil, égal ou
                  équivalent.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert"
                src={`${process.env.PUBLIC_URL}/assets/images/61.png`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "52px",
                }}
              >
                <h3 className="title-parahraphe-couvert">
                  Face arrière cassée
                </h3>
                <p className="parahraphe-couvert">
                  Un choc thermique important qui crée de la condensation ou
                  simplement l'humidité d'une salle de bain peuvent suffir à
                  oxyder les composants internes de votre smartphone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 d-flex-j-c margin0"
        style={{ backgroundColor: "rgb(12 1 95)", marginTop: "5%" }}
      >
        <div className="col-md-8">
          <h2 className="title-bloc-couvert" style={{ color: "#e72283" }}>
            AVANTAGES DE GARANTY
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">100%</h3>
              <h3 className="lasttitle100">Adapté à vos besoins</h3>
              <p className="paragraphe-avantage">
                L'assurance personnalisable et adaptée qui vous protège contre
                les risques du quotidien.
              </p>
            </div>
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">100%</h3>
              <h3 className="lasttitle100">Digital</h3>
              <p className="paragraphe-avantage">
                Vous pouvez souscrire votre contrat totalement en ligne. Notre
                modèle est 100% digital et approuvé par des algorithmes et des
                équipes disponibles et à votre service.
              </p>
            </div>
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">7J/7</h3>
              <h3 className="lasttitle100">
                Garanty , c'est aussi simple que ça
              </h3>
              <p className="paragraphe-avantage">
                Une couverture sur laquelle vous pouvez compter, de la
                protection de votre appareil à l'assistance de nos experts 7j/7.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 sinistre-garanty"
        style={{ marginTop: "5%" }}
      >
        <div className="col-md-9 gr-container">
          <h2 className="text-1">
          EN CAS DE SINISTRE,
            <br />
            GARANTY A DÉJÀ TOUT PRÉVU
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "20px",
              marginBottom: "50px",
            }}
          >
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Vous déclarez votre sinistre
                </h3>
                <p className="parahraphe-couvert1">
                  En ligne depuis votre espace client ou par téléphone au 71 169
                  000
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/1.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Garanty vous répond
                </h3>
                <p className="parahraphe-couvert1">
                  Vous recevrez une réponse sous 24h
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/2.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Nous récupérons votre équipement
                </h3>
                <p className="parahraphe-couvert1">
                  Nous vous envoyons un technicien sous 24h sans frais
                  supplémentaires
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/3.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">Mon équipement</h3>
                <p className="parahraphe-couvert1">
                  Est réparé, remplacé ou remboursé
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/4.png`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 proteger-aimez">
        <div className="col-md-12 col-lg-12 col-sm-12 col-12 div-FAQ">
          <h2 className="text-1" style={{ margin: "0" }}>
            QUESTIONS FRÉQUENTES
          </h2>
        </div>
        {showData ? (
          <div className="card-box-4">
            <Accordion>
              <Fragment>
                {allData[0].list.map((subEl, i) => {
                  return (
                    <Accordion.Item
                      key={i}
                      className="faq-drop-item  "
                      eventKey={i.toString()}
                    >
                      <Accordion.Header>
                        <p className="faq-sub-title"> {subEl.title}</p>
                      </Accordion.Header>
                      <Accordion.Body className="faq-text">
                        {subEl.text}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Fragment>
            </Accordion>
          </div>
        ) : (
          <></>
        )}
        <div
          className="col-md-12 col-lg-12 col-sm-12 col-12 div-arrow-FAQ"
          onClick={() => {
            setShowData(!showData);
          }}
        >
          <img
            className={showData ? "img-arrow-faq-hide" : "img-arrow-faq-show"}
            src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
          />
        </div>
      </div>
      <div className="row col-md-12 d-flex-j">
        <h2 className="text-1" style={{ marginBottom: "2%" }}>
          RECEVEZ NOTRE NEWSLETTER
        </h2>
      </div>
      <div
        className="row col-md-12 col-lg-12 col-sm-12 col-12 newsletter d-flex-a"
        style={{ height: "140px" }}
      >
        <div
          className="row col-md-7 col-lg-7 col-sm-7 col-7"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-9">
            <input
              style={{ paddingLeft: "15px" }}
              className="newletter-input"
              name="email"
              type="text"
              placeholder="Votre adresse e-mail....."
              onChange={handelChange}
            />
          </div>
          <div className="col-md-3">
            <button
              className="newletter-btn"
              onClick={() => {
                if (!newsletter.email) {
                  toast.error("Entrez vous votre e-mail");
                } else {
                  adminService.addToNewsletter(newsletter).then((res) => {
                    if (res.status === 202) {
                      toast.success(
                        "Vous êtes enregistré dans notre newsletter."
                      );
                      history.push({ pathname: "/newsletter?envoyer=ok" });
                      window.scrollTo(500, 0);
                    } else if (res.status === 204) {
                      toast.error("Email déjà enregistré");
                    }
                  });
                }
              }}
            >
              s'abonner
            </button>
          </div>
        </div>
      </div>
      <Footer listFamily={familyList} />
      {/* <Modal
        isOpen={oppen}
        toggle={modaltoggle}
        centered
        style={{ display: "flex", justifyContent: "center", maxWidth: "750px" }}
      >
        <ModalHeader toggle={modaltoggle}>
          <div
            style={{
              font: "normal normal bold 25px/27px Century Gothic",
              color: "#0C015F",
            }}
          >
            Avant de continuer, souhaitez-vous ajouter l'option VOL?
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="form-row">
            <Col md="8 mb-4">
              <Col
                md={{
                  size: 10,
                }}
              >
                <Input
                  id="checkbox2"
                  type="checkbox"
                  onChange={() => {
                    setVol(!vol);
                  }}
                />
                <Label check className="text-popup">
                  Ajouter la protection contre le VOL
                </Label>
              </Col>
            </Col>
            <Col md="4 mb-4">
              <p className="text-popup">{volFormula?.vol_protection} DT/an</p>
            </Col>
            <Col md="6 mb-4">
              <p className="text-popup">
                Plafond de garantie VOL est {volFormula?.ceiling_guarantee_vol}
              </p>
            </Col>
          </div>
          <div>
            <div
              className="form1"
              style={{ paddingRight: "1%", width: "80% " }}
            >
              <div
                className={
                  "col-12 formula-select"
                  // : "col-12 formula"
                }
              >
                <div
                  className="form"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <h3
                    className="dispnone"
                    style={
                      {
                        color: "#ffffff",
                        paddingLeft: "5px",
                        fontSize: "23px",
                        display: "contents",
                      }
                      // : {
                      //     color: "#888888",
                      //     paddingLeft: "5px",
                      //     fontSize: "23px",
                      //   }
                    }
                  >
                    Formule
                  </h3>
                  <img
                    className={
                      "img-formula-select"
                      // : "img-formula"
                    }
                    style={{ width: "62%" }}
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      fo?.fomula?.name +
                      ".svg"
                    }
                  />
                </div>
                <div className="col-2 dispnone"></div>
                <div className="col-4 text-formula" style={{ display: "flex" }}>
                  <p
                    style={
                      { color: "#ffffff", fontWeight: "600" }
                      // : { color: "#888888" }
                    }
                  >
                    {vol
                      ? (volFormula?.price /12)?.toFixed(3)
                      : (fo?.fomula?.price /12)?.toFixed(3)}{" "}
                    DT / mois
                  </p>
                  <p
                    style={
                      { color: "#ffffff", fontWeight: "600" }
                      // : { color: "#888888" }
                    }
                  >
                    {vol
                      ? (volFormula?.price)?.toFixed(3)
                      : (fo?.fomula?.price)?.toFixed(3)}{" "}
                    DT / an
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={contunierVolOption}
            className="calcule-btn"
            style={{ width: "30%" }}
          >
            Continuer
          </Button>
        </ModalFooter>
      </Modal> */}
    </DocumentMeta>
  );
};

export default Smartphonedetails;

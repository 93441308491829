import Souscrire from "../components/souscrire/Souscrire";
import Inscrit from "../components/inscrit/inscrit";
import CreateCompte from "../components/CreateUser/CreateCompte";
import CreateCompte1 from "../components/CreateUser/CreateCompte1";
import CreateCompte2 from "../components/CreateUser/CreateCompte2";
import Home from "../components/home/Home";
import Souscription from "../components/souscription/Souscription";
import SouscriptionPage2 from "../components/souscription/SouscriptionPage2";
import NoticeInfo from "../components/souscription/NoticeInformation";
import SouscriptionPage3 from "../components/souscription/SouscriptionPage3";
import Confirmation from "../components/souscription/Confirmation";
import AproposNous from "../components/Apropos/AproposNous";
import Payment from "../components/souscription/Payment";
import Dashboard from "../components/dashboard/Dashboard";
import NewsletterMembership from "../components/souscription/NewsletterMembership";
import SuccessRequest from "../components/souscription/SuccessRequest";
import Smartphonedetails from "../components/SmartPhone/SmartPhoneDetails";
import MultimediaDetails from "../components/Multi/MultimediaDetails";
import Optiquedetails from "../components/optique/OptiqueDetails";
import Help from "../components/help/Help";
import ResetPassword from "../components/inscrit/ResetPassword";
import contact from "../components/contact/Contact";
import Error404 from "../components/souscription/Error404";
import DevenirPartenaire from "../components/devenirPartenaire/DevenirPartenaire";
import QrCode from "../pages/QrCode";
import ElectroDetail from "../components/electro/ElectroDetail"
import CreateSinistreIncl from "../components/dashboard/sinistreInclusion/CreateSinistreIncl"
import Etape1 from "../components/dashboard/sinistreInclusion/Etape1"
import Etape2 from "../components/dashboard/sinistreInclusion/Etape2"
import SuccesIncl from "../components/dashboard/sinistreInclusion/SuccesIncl"
import OptiqueInclusion from "../components/optique/OptiqueInclusion";
import MeublesDetails from "../components/Meubles/MeublesDetails";
export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/`,
    exact: true,
    Component: Home,
  },
  {
    path: `${process.env.PUBLIC_URL}/home`,
    exact: true,
    Component: Home,
  },
  {
    path: `${process.env.PUBLIC_URL}/newsletter?envoyer=ok`,
    exact: true,
    Component: Home,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/souscrire`,
    exact: true,
    Component: Souscrire,
  },
  {
    path: `${process.env.PUBLIC_URL}/connexion`,
    exact: true,
    Component: Inscrit,
  },
  {
    path: `${process.env.PUBLIC_URL}/inscription?=ok`,
    exact: true,
    Component: Inscrit,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/CreateUser/step1`,
    exact: true,
    Component: CreateCompte,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/CreateUser/step2`,
    exact: true,
    Component: CreateCompte1,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/CreateUser/step3`,
    exact: true,
    Component: CreateCompte2,
  },
  {
    path: `${process.env.PUBLIC_URL}/Souscription/Etape1`,
    exact: true,
    Component: Souscription,
  },
  {
    path: `${process.env.PUBLIC_URL}/Souscription/Etape2`,
    exact: true,
    Component: SouscriptionPage2,
  },
  {
    path: `${process.env.PUBLIC_URL}/Souscription/Etape4`,
    exact: true,
    Component: NoticeInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/Souscription/Etape3`,
    exact: true,
    Component: SouscriptionPage3,
  },
  {
    path: `${process.env.PUBLIC_URL}/confirmation`,
    exact: true,
    Component: Confirmation,
  },
  {
    path: `${process.env.PUBLIC_URL}/apropos`,
    exact: true,
    Component: AproposNous,
  },
  {
    path: `${process.env.PUBLIC_URL}/garanties/smartphone`,
    exact: true,
    Component: Smartphonedetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/garanties/meubles`,
    exact: true,
    Component: MeublesDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/garanties/multimedia`,
    exact: true,
    Component: MultimediaDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/garanties/electromenager`,
    exact: true,
    Component: ElectroDetail,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/resetpassword`,
    exact: true,
    Component: ResetPassword,
  },
  {
    path: `${process.env.PUBLIC_URL}/garanties/optique`,
    exact: true,
    Component: Optiquedetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/garanties/optique_maladie`,
    exact: true,
    Component: OptiqueInclusion,
  },
  {
    path: `${process.env.PUBLIC_URL}/payment`,
    exact: true,
    Component: Payment,
  },
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: Dashboard },
  {
    path: `${process.env.PUBLIC_URL}/membership`,
    exact: false,
    Component: NewsletterMembership,
  },
  {
    path: `${process.env.PUBLIC_URL}/success`,
    exact: false,
    Component: SuccessRequest,
  },
  {
    path: `${process.env.PUBLIC_URL}/devenir-partenaire`,
    exact: false,
    Component: DevenirPartenaire,
  },
  {
    path: `${process.env.PUBLIC_URL}/contact=confirmation?=ok`,
    exact: false,
    Component: contact,
  },
  {
    path: `${process.env.PUBLIC_URL}/contact`,
    exact: false,
    Component: contact,
  },
  {
    path: `${process.env.PUBLIC_URL}/ba_check`,
    exact: false,
    Component: QrCode,
  },

  {
    path: `${process.env.PUBLIC_URL}/404`,
    exact: false,
    Component: Error404,
  },
  {
    path: `${process.env.PUBLIC_URL}/inclusion/sinistre`,
    exact: false,
    Component: CreateSinistreIncl,
  },
  {
    path: `${process.env.PUBLIC_URL}/inclusion/etape1`,
    exact: false,
    Component: Etape1,
  },
  {
    path: `${process.env.PUBLIC_URL}/inclusion/etape2`,
    exact: false,
    Component: Etape2,
  },
  {
    path: `${process.env.PUBLIC_URL}/inclusion/succes_incl`,
    exact: false,
    Component: SuccesIncl,
  },
];

import React, { useState, useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useHistory } from "react-router";
import sinistreService from "../../../services/sinistre/SinistreService";
import { data } from "./Fakedata";
import "../Pages/Sinistres.css";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

const Sinistres = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const [index, setIndex] = useState(null);

  const Verticalcentermodaltoggle = () => {
    setVerticalcenter(!Verticalcenter);
  };
  const closeModal = () => {
    setVerticalcenter(false);
  };

  useEffect(() => {
    // const id = JSON.parse(localStorage.getItem("user")).id;
    // sinistreService.getSinistreByCustomer(id)
    // .then((res) => {
    //   setList(res.data);
    // });
    // setList(data);
  }, []);

  return (
    <div style={{ width: "100%", padding: "44px" }}>
      <Modal
        isOpen={Verticalcenter}
        toggle={Verticalcentermodaltoggle}
        centered
      >
        <ModalHeader
          toggle={Verticalcentermodaltoggle}
          style={{ flexDirection: "column" }}
        >
          <h6
            style={{
              font: "normal normal bold 25px/27px Century Gothic",
              color: "#0C015F",
            }}
          >
            Tous les sinistres
          </h6>
        </ModalHeader>
        <ModalBody>
          <>
            <Row>
              <p
                style={{
                  fontSize: "15px",
                  color: "green",
                  textAlign: "center",
                }}
              ></p>
            </Row>
          </>

          {index != null && (
            <div>
              <Col md="10 mb-4">
                <Label htmlFor="validationCustom01">Numéro de contrat:</Label>
                <h6>{list[index].numContrat}</h6>
              </Col>
              <Col md="10 mb-4">
                <Label htmlFor="validationCustom01">Numéro du sinistre:</Label>
                <h6>{list[index].num}</h6>
              </Col>
              <Col md="10 mb-4">
                <Label htmlFor="validationCustom01">
                  Date de prise en charge du sinistre:
                </Label>
                <h6>{list[index].date}</h6>
              </Col>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={closeModal}>
            {"close"}
          </Button>
        </ModalFooter>
      </Modal>
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Tous les sinistres
      </h4>
      <div
        className="row col-md-12"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div className="nomenclature-container">
          <h6 className="nomenclature">Ouverture Dossier</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#AFD3E3" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">En cours de traitement</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#029AD9" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Produit récupéré</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#1A307C" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">En cours de réparation</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#F8548F" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Produit Réparé</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#914CA9" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Réglé</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#EEE501" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Rejeté</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#DE0609" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Livré</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#26876B" }}
          ></div>
        </div>
      </div>
      <div className="tab-scroll">
        <div className="row col-md-12 tab-sinistres margin0">
          <div className="row col-md-12 tab-container">
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Numéro de contrat</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Numéro du sinistre</h6>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-3 col-3">
              <h6 className="title-tab">Date de prise en charge du sinistre</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Etat</h6>
            </div>
          </div>
          {list.length != 0 ? (
            list?.map((el, i) => {
              return (
                <div className="row col-md-12 row-tab">
                  <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                    <h6 className="value-tab">{el.numContrat}</h6>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                    <h6 className="value-tab">{el.num}</h6>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-3 col-3">
                    <h6 className="value-tab">{el.date}</h6>
                  </div>
                  <div
                    className="col-md-2 col-lg-2 col-sm-2 col-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h6
                      className="value-tab"
                      style={{ marginRight: "5px", width: "fit-content" }}
                    >
                      En cours de traitement
                    </h6>
                    <div
                      className="boule-couleur"
                      style={{ backgroundColor: "#029AD9" }}
                    ></div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-3 col-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      paddingTop: "10px",
                      
                    }} >
                  <div
                    
                    type="button"
                    onClick={() => {
                      console.log(i);
                      localStorage.setItem("index",i);
                      history.push("/dashboard/details")
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                  <button className="buttonEdit"
                    onClick={() => {
                      console.log(i);
                      setIndex(i);
                      Verticalcentermodaltoggle();
                    }}>Traiter</button>
                    </div>
                  <hr />
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <img width="100" src="/assets/images/dash-2.svg" />
              <h3>Aucune déclaration de sinistre en cours</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sinistres;

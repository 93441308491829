import React from "react";
import "./cardShortened.css";
function CardShortened({ color, icon, text, textColor,onClick }) {
  return (
    <div style={{ backgroundColor: color }} className="card-container" onClick={onClick}>
      <p style={{ font: "normal normal bold 18px/25px Century Gothic", marginBottom: "0px", color: textColor || "black" }}>{text}</p>
      <img width="50" src={icon} />
    </div>
  );
}

export default CardShortened;

import React, { Fragment } from "react";
import "../Souscription.css";
const array = ["Mon Produit", "Mes informations personnelles", "Confirmation", "Paiment"];

function Stepper({ activeStep }) {
  return (
    <div style={{ height: "250px" }} className=" col-12 m-0 p-0  question-souscription">
      <div className="row  col-6 ">
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {array.map((el, key) => {
            return (
              <Fragment key={key}>
                <div style={{ height: "130px", display: "flex", flexDirection: "column" }}>
                  <div className={activeStep === key ? "div-steps-active " : activeStep > key ? "div-steps-done" : "div-steps-wait"}></div>
                  <p className={activeStep === key ? "text-step-active " : "text-step"}>{el}</p>
                </div>
                {el == "Paiment" ? null : <span className={activeStep >= key + 1 ? "hr-active" : "hr"} />}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Stepper;

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./createCompte.css";
import contractService from "../../services/contract/contractService";
import NavBarHome from "../home/NavBarHome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer/Footer";
import familyService from "../../services/family/familyService";
import { saveAs } from "file-saver";
const CreateCompte = () => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [typeInsc, setTypeInsc] = useState(checked);
  const [customerDto, setCustomerDto] = useState({
    adress: "",
    cin: "",
    city: "",
    codeVerification: "",
    dateCreation: new Date().toISOString(),
    email: "",
    firstName: "",
    isblacklisted: 0,
    iscgu: 0,
    isnewsletter: 0,
    lastName: "",
    password: "",
    phone: "",
    postalCode: 0,
    socialReason: "",
    street: "",
    taxRegistrationNumber: "",
    typeCustomer: "P",
  });
  const [familyList, setFamilyList] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    familyService.getAllFamily().then((res) => {
      setFamilyList(res.data.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 4 ));
    });
  }, []);

  const saveFile = () => {
    saveAs(
      `${process.env.PUBLIC_URL}/assets/pdf/Mentions légales et CGU GARANTY.pdf`,
      "Mentions légales et CGU GARANTY.pdf"
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDto((customerDto) => ({ ...customerDto, [name]: value }));
  };

  const validateEmail = (email) => {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(email);
  };

  const valider = (e) => {
    e.preventDefault();
    if (!validateEmail(customerDto.email)) {
      toast.error("Adresse email non valide");
    } else if (!(customerDto.phone.length == 8)) {
      toast.error("Le numéro téléphone doit être sur 8 chiffres");
    } else if (!(customerDto.cin.length == 8)) {
      toast.error("Le numéro CIN doit être sur 8 chiffres");
    } else if (
      customerDto.email &&
      customerDto.cin &&
      customerDto.firstName &&
      customerDto.lastName &&
      customerDto.iscgu &&
      customerDto.phone
    ) {
      contractService
        .checkStepers({
          cin: customerDto.cin,
          email: customerDto.email,
          phone: customerDto.phone,
          typeCustomer: customerDto.typeCustomer,
          taxRegistrationNumber: "hi",
        })
        .then((response) => {
          if (response.data == 0) {
            history.push({
              pathname: "/home/CreateUser/step2",
              state: customerDto,
            });
          } else if (response.data == 1) {
            toast.error("Cette adresse email est déjà utilisée.");
          } else if (response.data == 2) {
            toast.error("Ce numéro de téléphone est déjà utilisé.");
          } else if (response.data == 3) {
            toast.error("Ce numéro de Cin est déjà utilisée.");
          }
        });
    } else {
      toast.error("Tout les champs sont obligatoire.");
    }
  };
  return (
    <div className="container-fluid">
      <NavBarHome dark relative />

      <div className="row col-md-12 margin0 d-flex-j">
        <h2
          style={{
            textAlign: "center",
            font: "normal normal bold 26px/24px Century Gothic",
            color: "#0C015F",
            marginTop: "70px",
          }}
        >
          Créer votre compte Garanty en 3 étapes !
        </h2>
        <div className="row col-md-10 steppr-user">
          <div
            className="row col-md-9 d-flex-j"
            style={{ marginTop: "70px", position: "relative" }}
          >
            <hr />
            <div className="col-md-4 d-flex-a flex-d-c">
              <div
                className="div-icon-steps d-flex-a"
                style={{ backgroundColor: "#AA74E8" }}
              >
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/personal-information.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#000000",
                  marginTop: "20px",
                }}
              >
                Vos données personnelles
              </h3>
            </div>
            <div className="col-md-4 d-flex-a flex-d-c">
              <div className="div-icon-steps d-flex-a">
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/secure.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#CCCCCC",
                  marginTop: "20px",
                }}
              >
                Votre mot de passe
              </h3>
            </div>
            <div className="col-md-4 d-flex-a flex-d-c">
              <div className="div-icon-steps d-flex-a">
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/checked.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#CCCCCC",
                  marginTop: "20px",
                }}
              >
                Validation
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="row col-md-12 d-flex-j" style={{ marginBottom: "70px" }}>
        <div className="col-md-9">
          <div className="col-md-12 input-radio">
            <div class="form-check" style={{ paddingRight: "40px" }}>
              <input
                class="form-check-input"
                type="radio"
                name="P"
                id="flexRadioDefault1"
                value={checked}
                checked={!checked}
                onClick={() => {
                  setChecked(false);
                }}
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Vous êtes une personne Physique ?
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="M"
                value={checked}
                id="flexRadioDefault2"
                checked={checked}
                onClick={() => {
                  setChecked(true);
                }}
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Vous êtes une personne Morale (une société)
              </label>
            </div>
          </div>
          <div className="col-md-12 d-flex-j flex-d-c marginTop40">
            {checked ? (
              <div
                className="row col-md-12 marginTop20"
                style={{ marginLeft: "0" }}
              >
                <div
                  className="col-md-6 d-flex-j flex-d-c"
                  style={{ paddingLeft: 0 }}
                >
                  <label className="label">
                    Identifiant unique ou Matricule fiscale
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="firstName"
                    className="input1"
                    type="text"
                    placeholder="Identifiant unique ou Matricule fiscale"
                    // value={customerDto.firstName}
                    // onChange={handleChange}
                  />
                </div>
                <div
                  className="col-md-6 d-flex-j flex-d-c"
                  style={{ paddingRight: 0 }}
                >
                  <label className="label">
                    Nom de la société<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="firstName"
                    className="input1"
                    type="text"
                    placeholder="Nom de la société"
                    // value={customerDto.firstName}
                    // onChange={handleChange}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-6 d-flex-j flex-d-c">
                <label className="label">Type d'inscription</label>
                <select className="input2">
                  <option value="">Saisir mes données manuellement </option>
                </select>
              </div>
            )}
          </div>
          <div
            className="row col-md-12 marginTop20"
            style={{ marginLeft: "0" }}
          >
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingLeft: 0 }}
            >
              <label className="label">
                {checked ? "Prénom du gérant" : "Prénom"}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="firstName"
                className="input1"
                type="text"
                placeholder="Entrez votre prénom"
                value={customerDto.firstName}
                onChange={handleChange}
              />
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                {checked ? "Nom du gérant" : "Nom"}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="lastName"
                className="input1"
                type="text"
                placeholder="Entrez votre nom de famille"
                value={customerDto.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div
            className="row col-md-12 marginTop20"
            style={{ marginLeft: "0" }}
          >
            {checked ? (
              <div
                className="col-md-6 d-flex-j flex-d-c"
                style={{ paddingLeft: 0 }}
              >
                <label className="label">
                  Activité<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="activity"
                  className="input2"
                  type="text"
                  placeholder="Entrer votre Activité"
                  value={customerDto.activity}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div
                className="col-md-6 d-flex-j flex-d-c"
                style={{ paddingLeft: 0 }}
              >
                <label className="label">
                  Adresse email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="email"
                  className="input2"
                  type="text"
                  placeholder="Entrer votre Email"
                  value={customerDto.email}
                  onChange={handleChange}
                />
              </div>
            )}
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Numéro de téléphone<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="phone"
                className="input1"
                type="number"
                placeholder="Entrer votre Numéro XX XXX XXX"
                value={customerDto.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          {checked ? (
            <div className="col-md-12 d-flex-j flex-d-c marginTop20">
              <label className="label">
                Adresse email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="email"
                className="input2"
                type="text"
                placeholder="Entrer votre Email"
                value={customerDto.email}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="col-md-12 d-flex-j flex-d-c marginTop20">
              <label className="label">
                Numéro carte d’identité<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="cin"
                className="input1"
                type="number"
                placeholder="Entrez votre Numéro carte d'identité"
                value={customerDto.cin}
                onChange={handleChange}
              />
            </div>
          )}

          <div
            className="col-md-12 d-flex-j flex-d-c check-input"
            style={{ marginBottom: "20px" }}
          >
            <label class="checkbox-div" style={{ height: "25px" }}>
              Je veux m'inscrire à la newsletter Garanty
              <input
                type="checkbox"
                name="isnewsletter"
                value="1"
                onChange={handleChange}
              />
              <span class="checkmark"></span>
            </label>
            <label
              class="checkbox-div"
              style={{ marginTop: "30px", height: "25px" }}
            >
              J'ai lu et j'accepte les termes et les conditions d'utilisation{" "}
              <a href="#" onClick={saveFile}>
                {" "}
                ( Télécharger les conditions )
              </a>{" "}
              .<span style={{ color: "red" }}>*</span>
              <input
                type="checkbox"
                name="iscgu"
                value="1"
                onChange={handleChange}
              />
              <span class="checkmark"></span>
            </label>
          </div>

          <div className="row col-md-12 marginTop20 margin0">
            <div
              className="col-md-6 d-flex-j flex-d-c"
              // style={{ paddingRight: 0 }}
            >
              <div
                className="calcule-btn"
                style={{
                  backgroundColor: "#D4D4D4",
                  border: "1px solid #D4D4D4",
                }}
              >
                <button
                  className="calcule-btn"
                  style={{
                    backgroundColor: "#D4D4D4",
                    border: "1px solid #D4D4D4",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/connexion",
                    });
                  }}
                >
                  Retour
                </button>
              </div>
            </div>
            <div className="col-md-6 d-flex-j flex-d-c">
              <div className="calcule-btn" id="step1validation">
                <button
                  className="calcule-btn"
                  id="step1validation"
                  onClick={valider}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer listFamily={familyList} />
    </div>
  );
};

export default CreateCompte;

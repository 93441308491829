import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import NavBarHome from "../home/NavBarHome";
// import "./SmartPhoneDetails.css";
import DatePicker, { registerLocale } from "react-datepicker";
import { addDays } from "date-fns";
import { subDays } from "date-fns";
import { useDispatch } from "react-redux";
import { savedataStepZero } from "../souscription/store/actionSouscription";
import formuleService from "../../services/formule/formuleService";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import familyService from "../../services/family/familyService";
import fr from "date-fns/locale/fr";
import DocumentMeta from "react-document-meta";
import { saveFormule } from "../CreateUser/store/userAction";
import { Accordion, Carousel } from "react-bootstrap";
import data from "../HomePageFooter/helpData.json";
import Data from "./multimediaData";
import dataa from "./CouvertImage.json";
import adminService from "../../services/adminService/adminService";
registerLocale("fr", fr);
const ElectroDetail = () => {
  const meta = {
    title:
      "Assurance & Garantie Électroménager , Casse ou Infiltration By Garanty",
    description:
      "Formules de garantie et assurance pour votre appareil Électroménager contre casse, infiltration, oxydation. Souscription du contrat d'assurance et déclaration de sinistre en ligne sur Garanty",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      'http-equiv': {
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
        expires: '0',
        pragma: 'no-cache',
      },
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [formuleList, setFormuleList] = useState([]);
  const [keyFormula, setKeyFormula] = useState(0);
  const [sousFamily, setSousFamily] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [simulation, setSimulation] = useState([]);
  const [showData, setShowData] = useState(true);
  const [famIndex, setFamIndex] = useState(0);
  const [allData, setAllData] = useState(data);
  const subscribe = (index) => {
    dispatch(saveFormule(simulation[index]));
    localStorage.setItem("idFamily", formuleDto.idFamille);
    if (localStorage.getItem("user")) {
      history.push({
        pathname: "/Souscription/Etape1",
      });
    } else {
      history.push({
        pathname: "/connexion",
      });
    }
  };
  const [formuleDto, setFormuleDto] = useState({
    amount: "",
    dateAchat: "",
    idFamille: location.state,
  });
  const [familyList, setFamilyList] = useState();
  const [newsletter, setNewsletter] = useState({ email: "" });
  const [couvert, setCouvert] = useState(Data.couvert);
  const handelChange = (e) => {
    const { name, value } = e.target;
    setNewsletter((newsletter) => ({ ...newsletter, [name]: value }));
  };
  useEffect(() => {
    familyService.getFamilybyParentId(location.state).then(async (res) => {
      setSousFamily(res.data.sort((a, b) => a.displayOrder - b.displayOrder));
      if (res.data) {
        await formuleService
          .getAllFormulaById(res.data[0]?.idfamily)
          .then((res) => {
            setFormuleList(
              res?.data?.sort((a, b) => a?.fomula?.price - b?.fomula?.price)
            );
          });
      }
    });
  }, []);

  const handleProduct = (value, name) => {
    setFormuleDto({ ...formuleDto, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormuleDto((formuleDto) => ({ ...formuleDto, [name]: value }));
  };

  const changeFormulaList = (ind) => {
    formuleService.getAllFormulaById(sousFamily[ind].idfamily).then((res) => {
      setFormuleList(
        res?.data?.sort((a, b) => a?.fomula?.price - b?.fomula?.price)
      );
    });
  };
  const Continuer = async (e) => {
    e.preventDefault();
    if (formuleDto.amount && formuleDto.dateAchat) {
      localStorage.setItem("amount", formuleDto.amount);
      dispatch(savedataStepZero(formuleDto));
      await formuleService
        .calculateFormula(formuleDto)
        .then(async (response) => {
          if (response.status === 200) {
            setSimulation(response.data);
            setShow(true);
            window.scrollTo(0, 900);
          } else {
            toast.error("Format incorrect du coût d'achat");
          }
        });
    } else if (!formuleDto.amount) {
      toast.error("Enter votre prix d'achat du produit");
    } else if (!formuleDto.dateAchat) {
      toast.error("Enter votre date d'achat du produit");
    }
  };
  return (
    <DocumentMeta {...meta} className="container-fluid">
      <NavBarHome listFamily={familyList} />
      <Carousel
        pause={false}
        indicators={false}
        controls={false}
        // activeIndex={element}
      >
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee4">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee8">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee9">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee10">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="row col-md-12 margin0 d-flex-j-c sumilateur">
        <div className="col-md-12 ">
          <h1 className="enfin-garantie" style={{ color: "#232e85" }}>
            Enfin une Garantie Électroménager ...
          </h1>
          <p className="simulez-prix1" style={{ color: "#232e85" }}>
            Simulez le tarif de votre Garantie Électroménager
          </p>
          <div className="row col-md-12 simulation-input">
            <div className="row col-md-8">
              <div className="col-md-3 aide">
                <select
                  name="amount"
                  className="input-prix"
                  onChange={(e) => handleProduct(e.target.value, "idFamille")}
                  required
                >
                  <option value="">sélectionner le produit</option>
                  {sousFamily.map((el) => (
                    <option value={el.idfamily}>{el.familyName}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 aide">
                <input
                  name="amount"
                  type="number"
                  className="input-prix"
                  placeholder="Coût d'achat"
                  value={formuleDto.amount}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">
                  Please provide an amount.
                </div>
              </div>
              <div className="col-md-3 aide">
                <DatePicker
                  selected={startDate}
                  minDate={subDays(new Date(), 30)}
                  maxDate={addDays(new Date(), 0)}
                  dateFormat="dd/MM/yyyy"
                  className="input-prix"
                  locale="fr"
                  placeholderText="Date d’achat"
                  value={formuleDto.dateAchat}
                  onChange={(date) => {
                    setStartDate(date);
                    handleChange({
                      target: { name: "dateAchat", value: date },
                    });
                  }}
                />
              </div>
              <div className="col-md-3 aide div-btn-calculate">
                <div className="calcule-btn">
                  <button
                    id="continuer"
                    className="calcule-btn"
                    onClick={Continuer}
                  >
                    Continuer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-lg-12 col-md-12 formule-offer"
        style={!show ? { display: "none" } : {}}
      >
        <div className="row col-md-8">
          <div
            className="col-md-12 text-center"
            style={{ marginBottom: "30px", marginTop: "50px" }}
          >
            <h2 className="simulez-prix">
              LA MEILLEURE PROTECTION POUR VOTRE ÉLECTROMÉNAGER
            </h2>
          </div>
          <div className="formula-bloc">
            {simulation.map((el, i) => (
              <div className="formule-bloc">
                <div className="div-extend-formule" style={{ height: "auto" }}>
                  <h3 className="text-align">Formule</h3>
                  <img
                    className="img-formule-extend"
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      el?.fomula?.name +
                      ".svg"
                    }
                  />
                  <div
                    className="d-flex"
                    style={
                      i <= 0 && el?.fomula?.ceilingGuarantee < 900
                        ? { marginBottom: "48px" }
                        : i <= 1 && el?.fomula?.ceilingGuarantee <= 900
                        ? { marginBottom: "25px" }
                        : {}
                    }
                  >
                    <p
                      style={{
                        fontSize: "28px",
                        fontWeight: "bold",
                        marginTop: "15%",
                        fontFamily: "Century Gothic",
                      }}
                    >
                      {(el?.fomula?.price/12)?.toFixed(3)} DT / mois
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "6px",
                      }}
                    >
                      {el?.fomula?.price?.toFixed(3)} DT / an
                    </p>
                    <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Prix du produit de {el?.fomula?.minValueProd}
                      {el?.fomula?.maxValueProd >= 9998
                        ? " et +"
                        : `${-el?.fomula?.maxValueProd} DT`}
                    </p>

                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        1 Produit couvert
                      </p>
                    </div>

                    {el?.listBreakDown?.map((br, ke) =>
                      br.value ? (
                        <>
                          <div
                            key={ke}
                            className="row col-md-12 detail-formule"
                          >
                            <p
                              className="col-md-10"
                              style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                            >
                              {br.name}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-10" style={{ fontSize: "12px" }}>
                        {el?.fomula?.nbrIntervYear > 1
                          ? "2 interventions /an"
                          : "1 intervention /an"}
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        Assistance téléphonique 7J/7
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        marginTop: "15px",
                      }}
                    >
                      Plafond de garantie {el?.fomula?.ceilingGuarantee} DT
                    </p>
                  </div>
                  <div
                    className="simulation-btn"
                    key={i}
                    onClick={() => subscribe(i)}
                    style={{ marginTop: "20px" }}
                  >
                    <p
                      style={{
                        font: "normal normal bold 14px/40px Century Gothic",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                      }}
                    >
                      Souscrire
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 specialiste-ass"
        style={{ paddingTop: "30px", marginTop: "5%" }}
      >
        <h2
          className="title-footer col-lg-9 col-md-10 col-sm-12 col-12"
          style={{ marginBottom: "10px" }}
        >
          COMMENT ÇA MARCHE?
        </h2>
        <div
          className="row col-md-10 col-sm-10"
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "0",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">1</div>

              <p className="parg-marche">
                Simulez le prix de votre garantie en indiquant le coût et la
                date d'achat de votre produit.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">2</div>

              <p className="parg-marche">
                Créez votre compte Garanty en 2 minutes, complétez vos
                informations personnelles puis validez.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">3</div>
              <p className="parg-marche">
                Souscrivez en indiquant les informations personnelles. Ajoutez
                les documents demandés (CIN et la photo de votre équipement)
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">4</div>
              <p className="parg-marche">
                Téléchargez la notice d'information et prenez connaissance des
                conditions générales
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div
              className="bloc-marche"
              style={{ background: "linear-gradient(#fd8cc4, #e31279)" }}
            >
              <p className="parg-marche" style={{ color: "#ffffff" }}>
                Payez et signez votre bulletin d'adhésion. Votre équipement est
                désormais protégé.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-lg-12 col-md-12 formule-offer"
        style={{ marginTop: "5%" }}
      >
        <div
          className="row col-lg-12 col-md-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-12 col-lg-12 col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="col-md-12 text-center">
              <h2 className="simulez-prix">
                NOS FORMULES DE GARANTIES ÉLECTROMÉNAGER
              </h2>
              <p className="col-8" style={{ marginBottom: "3%" }}>
                Nous vous proposons plusieurs formules de garantie selon le prix
                d’achat de votre produit. Découvrez le détail de chaque formule
                et assurez votre produit pour une utilisation en toute
                sérénité !
              </p>
            </div>
            <div
              className="col-xl-12 col-lg-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="div-mult-fam-btn elec-fam">
                {sousFamily.map((el, index) => (
                  <div
                    className="col-md-3"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      changeFormulaList(index);
                      setFamIndex(index);
                      setKeyFormula(0);
                    }}
                  >
                    <p
                      className={
                        famIndex === index
                          ? "txt-img-elec-fam-hover"
                          : "txt-img-elec-fam"
                      }
                    >
                      {el.familyName}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-12 gr-container1">
              <div
                className="row col-md-12 margin0 d-flex-j"
                style={{ position: "relative" }}
              >
                <div
                  className="row col-md-12"
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {dataa.map((el, inx) => {
                    if (inx === famIndex) {
                      return el.listeImage.map((img) => (
                        <div
                          className="img-mult-covert d-flex-a"
                          style={{ justifyContent: "space-around" }}
                        >
                          <img
                            className="img-couvert-eclectro"
                            src={
                              `${process.env.PUBLIC_URL}/assets/images/` +
                              el.familyName +
                              "/" +
                              img +
                              ".png"
                            }
                          />
                          <p
                            className="txt-img-mult-fam"
                            style={{
                              textDecoration: "none",
                              fontSize: "16px",
                              color: "#e60074",
                            }}
                          >
                            {img}
                          </p>
                        </div>
                      ));
                    }
                  })}
                </div>
              </div>
            </div>
            <div
              className="col-xl-12 col-lg-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="form1" style={{ paddingRight: "1%" }}>
                {formuleList.map((el, index) => (
                  <div
                    className={
                      keyFormula === index
                        ? "col-12 formula-select"
                        : "col-12 formula"
                    }
                    key={index}
                    onClick={() => {
                      setKeyFormula(index);
                    }}
                  >
                    <div
                      className="form"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3
                        className="dispnone"
                        style={
                          keyFormula === index
                            ? { color: "#ffffff", paddingLeft: "5px" }
                            : { color: "#888888", paddingLeft: "5px" }
                        }
                      >
                        Formule
                      </h3>
                      <img
                        className={
                          keyFormula === index
                            ? "img-formula-select"
                            : "img-formula"
                        }
                        src={
                          `${process.env.PUBLIC_URL}/assets/images/` +
                          el.fomula?.name +
                          ".svg"
                        }
                      />
                    </div>
                    <div className="col-2 dispnone"></div>
                    <div className="col-4 text-formula">
                      <p
                        style={
                          keyFormula === index
                            ? { color: "#ffffff" }
                            : { color: "#888888" }
                        }
                      >
                        {(el?.fomula?.price/12)?.toFixed(3)} DT / mois
                      </p>
                      <p
                        style={
                          keyFormula === index
                            ? { color: "#ffffff" }
                            : { color: "#888888" }
                        }
                      >
                        {el?.fomula?.price?.toFixed(3)} DT / an
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="form2" style={{ paddingLeft: "1%" }}>
                <div className="div-extend-formule">
                  <h3 className="text-align">Formule</h3>
                  <img
                    className="img-formule-extend"
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      formuleList[keyFormula]?.fomula?.name +
                      ".svg"
                    }
                  />
                  <div className="d-flex">
                    <p
                      style={{
                        fontSize: "23px",
                        fontWeight: "bold",
                        marginTop: "15%",
                        fontFamily: "Century Gothic",
                      }}
                    >
                      {formuleList[keyFormula]?.fomula?.price?.toFixed(3)} DT /
                      mois
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "6px",
                      }}
                    >
                      {formuleList[keyFormula]?.fomula?.price?.toFixed(3)} DT /
                      an
                    </p>
                    <p style={{ fontWeight: "bold", fontSize: "11px" }}>
                      Prix du produit de{" "}
                      {formuleList[keyFormula]?.fomula?.minValueProd}
                      {formuleList[keyFormula]?.fomula?.maxValueProd >= 9998
                        ? " et +"
                        : `${-formuleList[keyFormula]?.fomula
                            ?.maxValueProd} DT`}
                    </p>

                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        1 Produit couvert
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-10" style={{ fontSize: "12px" }}>
                        {formuleList[keyFormula]?.fomula?.nbrIntervYear > 1
                          ? "2 interventions /an"
                          : "1 intervention /an"}
                      </p>
                    </div>
                    {formuleList[keyFormula]?.listBreakDown?.map((br, ke) =>
                      br.value ? (
                        <>
                          <div
                            key={ke}
                            className="row col-md-12 detail-formule"
                          >
                            <p
                              className="col-md-10"
                              style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                            >
                              {br.name}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        marginTop: "15px",
                      }}
                    >
                      Plafond de garantie{" "}
                      {formuleList[keyFormula]?.fomula?.ceilingGuarantee} DT
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 d-flex-j-c" style={{ marginTop: "5%" }}>
        <div className="col-md-8">
          <h2 className="title-bloc-couvert">
            NOUS AVONS CHOISI LES MEILLEURES GARANTIES POUR VOUS
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{ marginRight: "0", marginTop: "6%" }}
          >
            {couvert[0].elems.map((elem, i) => (
              <div
                style={{ height: 100 }}
                key={i}
                className="col-md-6 col-sm-6 bloc-paragraphe-couvert"
              >
                <div
                  style={{
                    width: 120,
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    className=""
                    style={{
                      height: "100px",
                      paddingLeft: 0,
                    }}
                    src={process.env.PUBLIC_URL + elem.src + ".png"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "30px",
                    height: "100%",
                  }}
                >
                  <h3 className="title-parahraphe-couvert">{elem.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 d-flex-j-c margin0"
        style={{ backgroundColor: "rgb(12 1 95)", marginTop: "5%" }}
      >
        <div className="col-md-8">
          <h2 className="title-bloc-couvert" style={{ color: "#e72283" }}>
            AVANTAGES DE GARANTY
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">100%</h3>
              <h3 className="lasttitle100">Adapté à vos besoins</h3>
              <p className="paragraphe-avantage">
                L'assurance personnalisable et adaptée qui vous protège contre
                les risques du quotidien.
              </p>
            </div>
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">100%</h3>
              <h3 className="lasttitle100">Digital</h3>
              <p className="paragraphe-avantage">
                Vous pouvez souscrire votre contrat totalement en ligne. Notre
                modèle est 100% digital et approuvé par des algorithmes et des
                équipes disponibles et à votre service.
              </p>
            </div>
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">7J/7</h3>
              <h3 className="lasttitle100">
                Garanty , c'est aussi simple que ça
              </h3>
              <p className="paragraphe-avantage">
                Une couverture sur laquelle vous pouvez compter, de la
                protection de votre appareil à l'assistance de nos experts 7j/7.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 sinistre-garanty"
        style={{ marginTop: "5%" }}
      >
        <div className="col-md-9 gr-container">
          <h2 className="text-1">
            EN CAS DE SINISTRE,
            <br />
            GARANTY A DÉJÀ TOUT PRÉVU
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "20px",
              marginBottom: "50px",
            }}
          >
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Vous déclarez votre sinistre
                </h3>
                <p className="parahraphe-couvert1">
                  En ligne depuis votre espace client ou par téléphone au 71 169
                  000
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/1.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Garanty vous répond
                </h3>
                <p className="parahraphe-couvert1">
                  Vous recevrez une réponse sous 24h
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/2.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Nous récupérons votre équipement
                </h3>
                <p className="parahraphe-couvert1">
                  Nous vous envoyons un technicien sous 24h sans frais
                  supplémentaires
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/3.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">Mon équipement</h3>
                <p className="parahraphe-couvert1">
                  Est réparé, remplacé ou remboursé
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/4.png`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 proteger-aimez">
        <div className="col-md-12 col-lg-12 col-sm-12 col-12 div-FAQ">
          <h2 className="text-1" style={{ margin: "0" }}>
            QUESTIONS FRÉQUENTES
          </h2>
        </div>
        {showData ? (
          <div className="card-box-4">
            <Accordion>
              <Fragment>
                {allData[0].list.map((subEl, i) => {
                  return (
                    <Accordion.Item
                      key={i}
                      className="faq-drop-item  "
                      eventKey={i.toString()}
                    >
                      <Accordion.Header>
                        <p className="faq-sub-title"> {subEl.title}</p>
                      </Accordion.Header>
                      <Accordion.Body className="faq-text">
                        {subEl.text}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Fragment>
            </Accordion>
          </div>
        ) : (
          <></>
        )}
        <div
          className="col-md-12 col-lg-12 col-sm-12 col-12 div-arrow-FAQ"
          onClick={() => {
            setShowData(!showData);
          }}
        >
          <img
            className={showData ? "img-arrow-faq-hide" : "img-arrow-faq-show"}
            src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
          />
        </div>
      </div>
      <div className="row col-md-12 d-flex-j">
        <h2 className="text-1" style={{ marginBottom: "2%" }}>
          RECEVEZ NOTRE NEWSLETTER
        </h2>
      </div>
      <div
        className="row col-md-12 col-lg-12 col-sm-12 col-12 newsletter d-flex-a"
        style={{ height: "140px" }}
      >
        <div
          className="row col-md-7 col-lg-7 col-sm-7 col-7"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-9">
            <input
              style={{ paddingLeft: "15px" }}
              className="newletter-input"
              name="email"
              type="text"
              placeholder="Votre adresse e-mail....."
              onChange={handelChange}
            />
          </div>
          <div className="col-md-3">
            <button
              className="newletter-btn"
              onClick={() => {
                if (!newsletter.email) {
                  toast.error("Entrez vous votre e-mail");
                } else {
                  adminService.addToNewsletter(newsletter).then((res) => {
                    if (res.status === 202) {
                      toast.success(
                        "Vous êtes enregistré dans notre newsletter."
                      );
                      history.push({ pathname: "/newsletter?envoyer=ok" });
                      window.scrollTo(500, 0);
                    } else if (res.status === 204) {
                      toast.error("Email déjà enregistré");
                    }
                  });
                }
              }}
            >
              s'abonner
            </button>
          </div>
        </div>
      </div>
      <Footer listFamily={familyList} />
    </DocumentMeta>
  );
};

export default ElectroDetail;

import React, { Fragment } from "react";
import "../Souscription.css";
const array = ["Besoin", "Coordonnées", "Confirmation"];

function StepperContact({ activeStep }) {
  return (
    <div
      style={{ backgroundColor: "#ffffff" }}
      className=" col-12 m-0 p-0  question-souscription"
    >
      <div className="row  col-6 ">
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent:"center",
            alignItems:"center"
          }}
        >
          {array.map((el, key) => {
            return (
              <Fragment key={key}>
                <div
                  style={{
                    height: "130px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent:"center",
                    alignItems:"center"
                  }}
                >
                  <div
                    className={
                      activeStep === key
                        ? "div-steps-active-con "
                        : activeStep > key
                        ? "div-steps-done-con"
                        : "div-steps-wait-con"
                    }
                  ></div>
                  <p
                    className={
                      activeStep === key
                        ? "text-step-active-con "
                        : activeStep > key
                        ? "text-step-con"
                        : "text-step-wait-con"
                    }
                  >
                    {el}
                  </p>
                </div>
                {el == "Confirmation" ? null : (
                  <span
                    className={
                      activeStep >= key + 1 ? "hr-active-con" : "hr-con"
                    }
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default StepperContact;

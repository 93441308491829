import React from "react";
import './CardPanne.css';
function CardPanne({ name , value , onChange}) {
  return (
    <div className="col-lg-3 col-md-3 col-sm-3 card-panne">
        <input name={name} className="increase" type="checkbox" value={value} onChange={onChange}/>
      <label className="label-panne">{name}</label>
    </div>
  );
}

export default CardPanne;

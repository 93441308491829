import { useEffect, useState } from "react";
import HomePageFooter from "../HomePageFooter";
import "./Home.css";
import { useHistory, useLocation } from "react-router";
import Optique from "../optique/optique";
import SmartPhone from "../SmartPhone/SmartPhone";
import { Carousel } from "react-bootstrap";
import NavBarHome from "./NavBarHome";
import { useDispatch, useSelector } from "react-redux";
import { getFamiliesList } from "../../store/actions";
import DocumentMeta from "react-document-meta";
import Multimedia from "../Multi/Multimedia";
import Electro from "../electro/Electro";
import Meubles from "../Meubles/Meubles";
const Home = () => {
  const meta = {
    title:
      "Garanty Tunisie :Assurance en ligne, Offres de garanties 100% Digitale",
    description:
      "Garanty l'assurance 100% digitale en Tunisie pour Smartphone, Optique, Multimédia, Electroménager.  Choisissez la formule de garantie la plus adaptée à votre équipement",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      'http-equiv': {
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
        expires: '0',
        pragma: 'no-cache',
      },
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
    
  };

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const opt = location.state;
  const [element, setElement] = useState();
  const familyList = useSelector(({ global }) => global.familiesList);
  const [switchColor, setSwitchColor] = useState("Meubles");
  const [familyId, setFamilyId] = useState(
    "b460b428-82b2-46a0-8877-400cf5366677"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    if (opt) {
      setElement(3);
      setFamilyId(opt[1]);
      setSwitchColor(opt[0]);
    } else {
      if (!familyList) {
        dispatch(getFamiliesList());
      }
      if (familyList) {
        setFamilyId(
          familyList.sort((a, b) => a.displayOrder - b.displayOrder)[0].idfamily
        );
        localStorage.setItem(
          "idFamily",
          "b460b428-82b2-46a0-8877-400cf5366677"
        );
      }
    }
  }, [familyList]);

  return (
    <DocumentMeta {...meta} className="container-fluid">
      <NavBarHome />
      <Carousel
        pause={false}
        indicators={true}
        // controls={false}
        activeIndex={element}
        interval={2500}
        fade
      >
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee0">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee00">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee1">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee3">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee2">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee4">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee11">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee5">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee6">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee7">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee8">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee9">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row col-md-12 header-container-slidee10">
            <div className="col-12"></div>
          </div>
        </Carousel.Item>
      </Carousel>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12 garanty-switch1">
        <div className="row col-lg-10 col-md-12 col-sm-12 col-12 garanty-switch2">
          {familyList?.map((el, key) => (
            <div key={key} className="col-lg-3 col-md-3 col-sm-3 col-3">
              <div
                className="garanty-switch"
                onClick={() => {
                  setElement(parseInt(el.displayOrder));
                  if (el.displayOrder) {
                    setSwitchColor(el.familyName);
                    setFamilyId(el.idfamily);
                    localStorage.setItem("idFamily", el.idfamily);
                  }
                }}
              >
                <div
                  className={
                    switchColor === el.familyName
                      ? "div-family-select-click"
                      : "div-family-select"
                  }
                >
                  <img
                    className="img-garanty-type"
                    style={
                      el.familyName === "Optique"
                        ? { maxWidth: "90%", marginTop: "12px" }
                        : el.familyName === "Électroménager"
                        ? { maxWidth: "90%" }
                        : {}
                    }
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      el.familyName +
                      ".png"
                    }
                  />
                </div>
                <div className="text-granty-type">
                  <h2
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "20px",
                    }}
                  >
                    Garantie
                    <span
                      style={
                        switchColor === el.familyName
                          ? {
                              color: "rgb(252, 6, 231)",
                              fontWeight: "bold",
                              fontSize: "23px",
                              margin: "0",
                            }
                          : {
                              fontWeight: "bold",
                              fontSize: "23px",
                              margin: "0",
                            }
                      }
                    >
                      {el.familyName}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {switchColor === "Smartphone" ? (
        <SmartPhone key="12" idfamily={familyId} />
      ) : switchColor === "Optique" ? (
        <Optique key="12" idfamily={familyId} />
      ) : switchColor === "Multimédia" ? (
        <Multimedia key={"12"} idfamily={familyId} />
      ) : switchColor === "Meubles" ? (
        <Meubles key={"12"} idfamily={familyId} />
      ) : (
        <Electro key="12" idfamily={familyId} />
      )}

      <HomePageFooter
        listFamily={familyList}
        text={switchColor}
        element={element}
      />
    </DocumentMeta>
  );
};

export default Home;

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import customerService from "../../services/customer/customerService";
import { saveUser } from "./store/userAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NavBarHome from "../home/NavBarHome";
import Footer from "../Footer/Footer";
import familyService from "../../services/family/familyService";
const CreateCompte2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [confirmCode, setConfirmCodeCode] = useState({
    cin: location.state.cin,
    code: "",
  });
  const [mailInscription, setMailInscription] = useState({
    sendTo: location.state.email,
    subject: "Inscription au Garanty",
    text: "",
    type: "inscription",
  });
  const [familyList, setFamilyList] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    familyService.getAllFamily().then((res) => {
      setFamilyList(res.data.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 4 ));
    });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfirmCodeCode((code) => ({ ...code, [name]: value }));
  };
  const codeRen = () => {
    customerService.sendCode(location.id);
  };
  const valider = async (e) => {
    e.preventDefault();
    if (!confirmCode.code) {
      toast.error("Prière de saisir le code d'activation reçu par SMS");
    } else {
      await customerService
        .checkCodeConfirmation(confirmCode)
        .then(async (response) => {
          if (response.status === 200) {
            localStorage.setItem("customer", JSON.stringify(response.data));
            dispatch(saveUser(response.data));
            history.push({
              pathname: "/inscription?=ok",
            });
            await customerService.sendMail(mailInscription);
          } else if (response.status === 201) {
            toast.error("Votre code est erroné");
          }
        });
    }
  };

  return (
    <div className="container-fluid">
      <NavBarHome dark relative />

      <div
        className="row col-md-12 margin0 d-flex-j"
        style={{ marginBottom: "20px" }}
      >
        <h2
          style={{
            textAlign: "center",
            font: "normal normal bold 26px/24px Century Gothic",
            color: "#0C015F",
            marginTop: "70px",
          }}
        >
          Créer votre compte Garanty en 3 étapes !
        </h2>
        <div className="row col-md-10 steppr-user">
          <div
            className="row col-md-9 d-flex-j"
            style={{ marginTop: "70px", position: "relative" }}
          >
            <hr />
            <div className="col-md-4 d-flex-a flex-d-c">
              <div className="div-icon-steps d-flex-a">
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/personal-information.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#CCCCCC",
                  marginTop: "20px",
                }}
              >
                Vos données personnelles
              </h3>
            </div>
            <div className="col-md-4 d-flex-a flex-d-c">
              <div className="div-icon-steps d-flex-a">
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/secure.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#CCCCCC",
                  marginTop: "20px",
                }}
              >
                Votre mot de passe
              </h3>
            </div>
            <div className="col-md-4 d-flex-a flex-d-c">
              <div
                className="div-icon-steps d-flex-a"
                style={{ backgroundColor: "#AA74E8" }}
              >
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/checked.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#000000",
                  marginTop: "20px",
                }}
              >
                Validation
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="row col-md-12 d-flex-j">
        <div className="col-md-9">
          <div className="col-md-12 d-flex-j flex-d-c marginTop20">
            <label className="label">
              Veuillez saisir votre code d'activation pour créer votre compte
            </label>
            <input
              name="code"
              className="input2"
              type="text"
              placeholder="   Saisir mes données manuellement"
              value={confirmCode.code}
              onChange={handleChange}
            />
          </div>
          <div
            className="row col-md-12 marginTop20 margin0"
            style={{ marginTop: "40px" }}
          >
            <div className="col-md-6 d-flex-j flex-d-c">
              <div
                className="calcule-btn"
                style={{
                  backgroundColor: "#D4D4D4",
                  border: "1px solid #D4D4D4",
                }}
              >
                <button
                  className="calcule-btn"
                  style={{
                    backgroundColor: "#D4D4D4",
                    border: "1px solid #D4D4D4",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/home/CreateUser/step2",
                    });
                  }}
                >
                  Retour
                </button>
              </div>
            </div>
            <div className="col-md-6 d-flex-j flex-d-c">
              <div className="calcule-btn" id="step2validation">
                <button className="calcule-btn" id="step2validation" onClick={valider}>
                Valider
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex-j marginTop20">
            <p
              className="code-envoie"
              onClick={codeRen}
              style={{ cursor: "pointer" }}
            >
              Ré-envoyer un code d'activation
            </p>
          </div>
        </div>
      </div>
      <Footer listFamily={familyList} />
    </div>
  );
};

export default CreateCompte2;

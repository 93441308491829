import React, { Fragment, useState } from "react";
import { Accordion } from "react-bootstrap";
import Footer from "../Footer/Footer";
import NavBarHome from "../home/NavBarHome";
import "./help.css";
import data from "./helpData.json";

function Help() {
  const [allData, setAllData] = useState(data);
  const showAll = (index) => {
    let newData = [...allData];
    newData[index].all = true;
    setAllData(newData);
  };
  return (
    <div>
      <NavBarHome dark relative />
      <div
        style={{
          width: "70%",
          margin: "auto",
          marginBlock: "50px",
          font: "normal normal normal 16px/34px Century Gothic",
          letterSpacing: "0px",
        }}
      >
        Accueil - Aide
      </div>
      <div className="card-box-4">
        <p
          style={{
            font: "normal normal bold 40px/40px Century Gothic",
          }}
        >
          FAQ
        </p>
        <Accordion>
          {allData.map((el, key) => {
            return (
              <Fragment key={key}>
                <p className="faq-title">
                  {el.title} ({el.list.length})
                </p>
                {el.list.slice(0, el.all ? undefined : 4).map((subEl, i) => {
                  return (
                    <Accordion.Item
                      key={i}
                      className="faq-drop-item  "
                      eventKey={i.toString()}
                    >
                      <Accordion.Header>
                        <p className="faq-sub-title"> {subEl.title}</p>
                      </Accordion.Header>
                      <Accordion.Body className="faq-text">
                        {subEl.text}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
                {!el.all && (
                  <p
                    onClick={() => showAll(key)}
                    style={{
                      marginTop: "20px",
                      color: "#0C015F",
                      font: "normal normal bold 20px/40px Century Gothic",
                      cursor: "pointer",
                    }}
                  >
                    Voir l'ensemble des {el.list.length} articles
                  </p>
                )}
              </Fragment>
            );
          })}
        </Accordion>
      </div>
      <Footer />
    </div>
  );
}

export default Help;

import React, { useEffect, useState } from "react";
import "./BreakDownType.css";
import ButtonsWrapper from "../../SubComponents/ButtonsWrapper ";
import { useHistory, useLocation } from "react-router";
import SinistreService from "../../../services/sinistre/SinistreService";
import { toast } from "react-toastify";
import InputFileCustom from "../../SubComponents/inputFileCustom/InputFileCustom";
import contractService from "../../../services/contract/contractService";
function BreakDownType() {
  const history = useHistory();
  const location = useLocation();
  const prod = location.state[1];
  const [sinistre, setSinistre] = useState(location.state[0]);
  const [photos, setPhotos] = useState();
  const handelChange = (e) => {
    const { name, value } = e.target;

    setSinistre((sinistre) => ({ ...sinistre, [name]: value }));
  };
  const selectFile = (e) => {
    const { name, value } = e.target;
    setPhotos((photos) => ({ ...photos, [name]: value }));
  };
  var dataform1 = new FormData();
  var dataform2 = new FormData();
  var dataform5 = new FormData();

  dataform1.append("file", photos?.photoAv);
  dataform2.append("file", photos?.photoAr);
  dataform5.append("file", photos?.facture);

  const onSubmit = () => {
    if (!photos?.facture) {
      toast.error("Veuillez télécharger votre facture d’achat");
      return;
    }
    if (!photos?.photoAv) {
      toast.error("Veuillez télécharger la photo de vos produit");
      return;
    }
    const photoAv = contractService.upload(dataform1);
    const photoAr = contractService.upload(dataform2);
    const facture = contractService.upload(dataform5);
    Promise.all(
      prod == "Optique" ? [photoAv, facture] : [photoAv, photoAr, facture]
    ).then((res) => {
      console.log(res);
      let result = "";
      let count = res.length;
      res.forEach((el, key) => {
        if (key != count - 1) {
          result += el.data + ",";
        } else {
          sinistre.facturephoto = el.data;
        }
      });

      sinistre.prodphoto = result;
      if (prod == "Optique" && sinistre.prodphoto && sinistre.facturephoto) {
        SinistreService.createSinistre(sinistre).then((res) => {
          if (res.status == 201) {
            toast.success("Récapitulatif de votre demande de prise en charge");
            history.push({
              pathname: "/dashboard/sinistre/success",
              state: res.data.num,
            });
          }
        });
      } else if (
        prod == "Smartphone" &&
        sinistre.prodphoto &&
        sinistre.facturephoto &&
        sinistre.ecran &&
        sinistre.prodfonc &&
        sinistre.facadearriere &&
        sinistre.ecrantac &&
        sinistre.prodallu &&
        sinistre.prodcharge &&
        sinistre.affecran &&
        sinistre.prodsound &&
        sinistre.connectfonc
      ) {
        SinistreService.createSinistre(sinistre).then((res) => {
          if (res.status == 201) {
            toast.success("Récapitulatif de votre demande de prise en charge");
            history.push({
              pathname: "/dashboard/sinistre/success",
              state: res.data.num,
            });
          }
        });
      } else {
        toast.error("Tous les champs sont obligatoires.");
      }
    });
  };
  const onCancel = () => {
    history.push("/dashboard/sinistre/gen-sinistre");
  };
  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Déclarer un sinistre <br />
        <br />
        Complétez le formulaire ci-dessous :
      </h4>
      <br />
      <div className="row col-md-10 col-lg-10 col-sm-10 col-10">
        {prod == "Optique" ? (
          <></>
        ) : (
          <>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Votre smartphone fonctionne :
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="prodfonc"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Totalement">Totalement </option>
                <option value="Partiellement">Partiellement</option>
                <option value="Pas du tout">Pas du tout</option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Votre smartphone s'allume t-il ?
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="prodallu"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Votre smartphone se charge t-il ?
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="prodcharge"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Est-ce que les connectiques suivantes fonctionnent:
                Casque/USB/Charge/Batterie ?
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="connectfonc"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
                <option value="je ne sais pas">Je ne sais pas</option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Votre écran ou écran tactile fonctionne-t-il ?{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="ecrantac"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                L'écran du smartphone est :
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="ecran"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Normal">Normal</option>
                <option value="Rayé">Rayé</option>
                <option value="Cassé ( il manque des morceaux d’écran)">
                  Cassé ( il manque des morceaux d’écran)
                </option>
                <option value="Brisé">Brisé</option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                L’affichage sur l’écran est :
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="affecran"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Partiel">Partiel</option>
                <option value="Normal">Normal</option>
                <option value="Aucun affichage">Aucun affichage</option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                La façade arrière de votre smartphone est :{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="facadearriere"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="Normal">Normal</option>
                <option value="Rayé">Rayé </option>
                <option value="">Choisir...</option>
                <option value="Cassé ( il manque des morceaux)">
                  Cassé ( il manque des morceaux)
                </option>
                <option value="Brisé">Brisé </option>
              </select>
            </div>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Le son de votre smartphone fonctionne t-il ?{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="prodsound"
                className="input-form"
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                placeholder="Choisir..."
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
                <option value="je ne sais pas">Je ne sais pas</option>
              </select>
            </div>
          </>
        )}
        {prod == "Optique" ? (
          <>
            <InputFileCustom
              name="facture"
              label="Télécharger la facture d'achat"
              onChange={selectFile}
            />
            <InputFileCustom
              name="photoAv"
              label="Téléchargez la photo de vos lunettes"
              onChange={selectFile}
            />
          </>
        ) : (
          <>
            <InputFileCustom
              name="facture"
              label="Télécharger la facture d'achat"
              onChange={selectFile}
            />
            <InputFileCustom
              name="photoAv"
              label="Télécharger la photo avant de votre smartphone"
              onChange={selectFile}
            />
            <InputFileCustom
              name="photoAr"
              label="Télécharger la photo arrière de votre smartphone"
              onChange={selectFile}
            />
          </>
        )}
      </div>

      <div style={{ float: "right", marginRight: "45px", marginTop: "25px" }}>
        <ButtonsWrapper onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default BreakDownType;

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { routes } from "./routes/index";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const Root = () => {
  return (
    <React.StrictMode>
      <BrowserRouter basename={`/`}>
        <Switch>
          <App>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              render={() => {
                return <Redirect to={`${process.env.PUBLIC_URL}`} />;
              }}
            />

            {routes.map(({ Component, path, exact }) => {
              return (
                <Route
                  key={path}
                  exact={exact}
                  path={`${process.env.PUBLIC_URL}${path}`}
                >
                  {(props) => {
                    return (
                      <div>
                        {props.match != null ? <Component {...props} /> : null}
                      </div>
                    );
                  }}
                </Route>
              );
            })}
          </App>
        </Switch>
      </BrowserRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

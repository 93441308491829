import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import customerService from "../../../services/customer/customerService";
import ButtonsWrapper from "../../SubComponents/ButtonsWrapper ";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

const MonCompte = () => {
  const history = useHistory();
  const [customer, setCustomer] = useState({});
  const [done, setDone] = useState(false);
  const [Verticalcenter, setVerticalcenter] = useState(false);

  const [customerUpd, setCustomerUpd] = useState({
    idcustomer: JSON.parse(localStorage.getItem("user")).id,
  });
  const [wrongPass, setWrongPass] = useState(false);
  const [idenPass, setIdenPass] = useState(false);
  const [updatePasDto, setUpdatePassDto] = useState({
    ancienpassword: "",
    comfirmPassword: "",
    idcustomer: JSON.parse(localStorage.getItem("user")).id,
    password: "",
  });

  const Verticalcentermodaltoggle = () => {
    setVerticalcenter(!Verticalcenter);
  };
  const handelChange = (e) => {
    const { name, value } = e.target;
    setCustomerUpd((customerUpd) => ({ ...customerUpd, [name]: value }));
    setUpdatePassDto((updatePasDto) => ({ ...updatePasDto, [name]: value }));
  };

  const handelChangePass = (e) => {
    const { name, value } = e.target;
    setUpdatePassDto((updatePasDto) => ({ ...updatePasDto, [name]: value }));
  };

  const dashboard = () => {
    history.push("/dashboard");
  };
  const updateCustomer = () => {
    customerService.updateCustomer(customerUpd).then((res) => {
      console.log(res);
    });
  };
  const closeModal = () => {
    setVerticalcenter(false);
  };
  const handleSubmit = () => {
    customerService.updatePassword(updatePasDto).then((res) => {
      if (res.data == 2) {
        setDone(true);
      } else if (res.data == 1) {
        setIdenPass(true);
      } else if (res.data == -1) {
        setWrongPass(true);
      }
    });
  };
  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("user")).id;
    customerService.getCustomerById(id).then((response) => {
      console.log(response.data);
      setCustomer(response.data);
    });
  }, []);
  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Mon compte
      </h4>
      <div style={{ marginRight: "8%" }}>
        <div
          style={{
            height: "50px",
            // width: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #dd6446",
            borderRadius: "5px",
            backgroundColor: "#dd6446",
            color: "#ffffff",
            float: "right",
            fontSize: "15px",
            cursor: "pointer",
            padding:"5px"
          }}
          onClick={Verticalcentermodaltoggle}
        >
          Changer le mot de passe
        </div>
      </div>
      <div className="body-sinistre" style={{ paddingLeft: "50px" }}>
        <div
          className="row col-md-12 col-lg-12 col-sm-12"
          style={{ paddingRight: "5px" }}
        >
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Civilité</label>
            <input
              style={{
                paddingLeft: "10px",
                color: "black",
                fontSize: "medium",
              }}
              className="input-form"
              name="civility"
              onChange={handelChange}
              disabled
              value={customer.civility}
            ></input>
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Nom</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="lastName"
              type="text"
              defaultValue={customer.lastName}
              onChange={handelChange}
              disabled
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Prénom</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="firstName"
              type="text"
              defaultValue={customer.firstName}
              onChange={handelChange}
              disabled
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Date de naissance</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="birthdate"
              type="date"
              defaultValue={customer.birthdate}
              onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Cin</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="cin"
              type="text"
              defaultValue={customer.cin}
              onChange={handelChange}
              disabled
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">E-mail</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="email"
              defaultValue={customer.email}
              onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Téléphone</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="phone"
              defaultValue={customer.phone}
              onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Adresse</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="adress"
              defaultValue={customer.adress}
              onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Code postal</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="postalCode"
              defaultValue={customer.postalCode}
              onChange={handelChange}
            />
          </div>
        </div>

        <div
          className="row col-md-12 col-lg-12 col-sm-12"
          style={{ paddingRight: "100px" }}
        >
          <div className="col-md-6 d-flex flex-d-c mb-3"></div>
          <div
            className="col-md-6 d-flex flex-d-c mb-3"
            // style={{ alignItems: "flex-end" }}
          >
            <ButtonsWrapper onSubmit={updateCustomer} onCancel={dashboard} />
          </div>
        </div>
      </div>
      <Modal
        isOpen={Verticalcenter}
        toggle={Verticalcentermodaltoggle}
        centered
      >
        <ModalHeader
          toggle={Verticalcentermodaltoggle}
          style={{ flexDirection: "column" }}
        >
          <h6
            style={{
              font: "normal normal bold 25px/27px Century Gothic",
              color: "#0C015F",
            }}
          >
            Changer Mon Mot de passe
          </h6>
        </ModalHeader>
        <ModalBody>
          {done ? (
            <>
              <Row>
                <p
                  style={{
                    fontSize: "15px",
                    color: "green",
                    textAlign: "center",
                  }}
                >
                  Votre mot de passe a été changé avec succès.
                </p>
              </Row>
            </>
          ) : (
            <>
              <Col md="10 mb-4">
                <Label htmlFor="validationCustom01">Ancien Mot de passe</Label>
                <Input
                  className="form-control"
                  name="ancienpassword"
                  type="password"
                  placeholder="Ancien Mot de passe"
                  onChange={handelChangePass}
                />
                {wrongPass ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontFamily: "Century Gothic",
                    }}
                  >
                    Mot de passe incorrect !!!!
                  </span>
                ) : (
                  <></>
                )}
              </Col>
              <Col md="10 mb-4">
                <Label htmlFor="validationCustom01">Nouveau Mot de passe</Label>
                <Input
                  className="form-control"
                  name="password"
                  type="password"
                  placeholder="Nouveau Mot de passe"
                  onChange={handelChangePass}
                />
                {idenPass ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontFamily: "Century Gothic",
                    }}
                  >
                    Veuillez saisir deux mots de passe identiques.
                  </span>
                ) : (
                  <></>
                )}
              </Col>
              <Col md="10 mb-4">
                <Label htmlFor="validationCustom01">
                  Confirmer Mot de passe
                </Label>
                <Input
                  className="form-control"
                  name={"comfirmPassword"}
                  type="password"
                  placeholder="Confirmer Mot de passe"
                  onChange={handelChangePass}
                />
                {idenPass ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontFamily: "Century Gothic",
                    }}
                  >
                    Veuillez saisir deux mots de passe identiques.
                  </span>
                ) : (
                  <></>
                )}
              </Col>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={done ? closeModal : handleSubmit}>
            {done ? "Continuer" : "Validé"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MonCompte;

import React, { useState } from "react";
import "./Souscription.css";
import contrtactService from "../../services/contract/contractService";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "./Components/NavBar";
import { savedataStepOne } from "./store/actionSouscription";
import adminService from "../../services/adminService/adminService";
import { toast } from "react-toastify";
import familyService from "../../services/family/familyService";
const Souscription = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector(({ userReducer }) => userReducer.user);
  const formule = useSelector(({ userReducer }) => userReducer.formule);
  const qts = useSelector(
    ({ reducerSubscription }) => reducerSubscription?.step1
  );

  const [confirmPack, setConfirmPack] = useState({
    checked: false,
  });
  const [listQuestions, setListQuestions] = useState([]);
  const [familyName, setFamilyName] = useState();
  React.useEffect(() => {
    if (!formule) {
      history.push("/");
    }
    adminService.getQuestions(localStorage.getItem("idFamily")).then((res) => {
      setListQuestions(res.data);
    });
    familyService
      .getFamilyById(localStorage.getItem("idFamily"))
      .then((res) => {
        setFamilyName(res.data.familyName);
      });
  }, []);

  const handelCheckPack = (e) => {
    if (confirmPack.checked === false) {
      const { name, value } = e.target;
      setConfirmPack((confirmPack) => ({ ...confirmPack, [name]: value }));
    } else {
      setConfirmPack({ checked: false });
    }
  };

  const [questions, setQuestions] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestions((questions) => ({ ...questions, [name]: value }));
  };
  //console.log(Object.values(questions));
  const contunier = (e) => {
    e.preventDefault();
    //console.log(Object.values(questions).includes("0"));
    if (
      confirmPack.checked &&
      Object.values(questions).length >= 3 &&
      !Object.values(questions).includes("0")
    ) {
      dispatch(savedataStepOne(questions));
      history.push({
        pathname: "/Souscription/Etape2",
      });
    } else if (familyName === "Smartphone") {
      toast.error(
        "Malheureusement, nous n'avons pas encore de solution d'assurance pour vous. Nous n'assurons que les smartphones qui ne sont pas endommagés, qui ont été achetés au cours de ce mois et pour lesquels vous avez une preuve d'achat."
      );
    } else {
      toast.error(
        "Malheureusement, nous n'avons pas encore de solution d'assurance pour vous. Nous n'assurons que les Produits qui ne sont pas endommagées, qui ont été achetés le jour de votre souscription et pour lesquels vous avez une preuve d'achat."
      );
    }
  };

  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12 margin0">
      <NavBar text="Souscription" />
      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 question-souscription">
        {/* <h2 className="question-title">J'atteste que :</h2> */}
      </div>
      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 form-souscription d-flex-j-c">
        <form className="col-md-8">
          <div className="checkbox-divv">
            <input
              className="checkboxx"
              type="checkbox"
              name="checked"
              value={true}
              onChange={handelCheckPack}
            ></input>
            <label className="label-checkboxx">
            Je souhaite adhérer au contrat d’assurance et j’atteste:
            être âgé(e) de plus de 18 ans au moment de mon adhésion
            Ma résidence en Tunisie.
            </label>
          </div>
          <div className="">
            <div className="div-form-oui-non">
              <h3 className="title-form-check">
                {listQuestions[0]?.questionValue}
              </h3>
              <div className="d-flex-j">
                <div class="form-check">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Oui
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name={listQuestions[0]?.idQuestion}
                    value="1"
                    onChange={handleChange}
                    id="oui1"
                  ></input>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="flexRadioDefault2">
                    Non
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name={listQuestions[0]?.idQuestion}
                    id="non1"
                    value="0"
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex-j div-form-oui-non">
              <h6 className="title-form-check">
                {listQuestions[1]?.questionValue}
              </h6>
              <div className="d-flex-j">
                <div class="form-check">
                  <label class="form-check-label" for="flexRadioDefault3">
                    Oui
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name={listQuestions[1]?.idQuestion}
                    value="1"
                    onChange={handleChange}
                    id="oui2"
                  ></input>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="flexRadioDefault4">
                    Non
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name={listQuestions[1]?.idQuestion}
                    value="0"
                    onChange={handleChange}
                    id="non2"
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex-j div-form-last">
              <h3 className="title-form-check">
                {listQuestions[2]?.questionValue}
              </h3>
              <div className="d-flex-j">
                <div class="form-check">
                  <label class="form-check-label" for="flexRadioDefault5">
                    Oui
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name={listQuestions[2]?.idQuestion}
                    value="1"
                    onChange={handleChange}
                    id="oui3"
                  ></input>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="flexRadioDefault6">
                    Non
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name={listQuestions[2]?.idQuestion}
                    value="0"
                    onChange={handleChange}
                    id="non3"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-primary btn-continuer"
              id="Question-souscription"
              onClick={contunier}
            >
              Continuer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Souscription;

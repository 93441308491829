import React from "react";

function InputCustom2({ name, type, label, onchange, adressCla,...props }) {
  const style = adressCla
  return (
    <div className={style ? "col-md-12 d-flex flex-d-c mb-3" : "col-md-6 d-flex flex-d-c mb-3"}>
      <label className="label">{label}<span style={{ color: "red" }}>*</span></label>
      <input
        style={{ paddingLeft: "10px", height: "54px", fontSize: "medium" }}
        className="input-form"
        placeholder={label}
        type={type}
        name={name}
        onChange={onchange}
        {...props}
      />
    </div>
  );
}

export default InputCustom2;

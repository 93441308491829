import React, { useState } from "react";
import ButtonsWrapper from "../../SubComponents/ButtonsWrapper ";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Button,
} from "reactstrap";
import customerService from "../../../services/customer/customerService";
import { useHistory } from "react-router-dom";
const Reclamation = () => {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => {
    setVerticalcenter(!Verticalcenter);
  };
  const [mail, setMail] = useState({
    emailcustomer: JSON.parse(localStorage.getItem("user")).email,
    sendTo: "contact@garanty.tn",
    subject: "",
    text: "",
    type: "reclamation",
  });
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handelChange = (e) => {
    const { value, name } = e.target;
    setMail((mail) => ({ ...mail, [name]: value }));
  };
  const onSubmit = () => {
    customerService.sendMailCustomer(mail).then((res) => {
      if (res.status == 200) {
        openModal();
      }
    });
  };
  const dashboard = () => {
    history.push("/dashboard");
  };
  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Réclamation
      </h4>
      <h6
        style={{
          font: "normal normal bold 15px/22px Century Gothic",
          color: "gray",
        }}
      >
        Votre réclamation concerne ?
      </h6>
      <div className="col-md-5 d-flex flex-d-c mb-3">
        <label className="label" style={{ marginTop: "25px" }}></label>
        <input
          style={{ paddingLeft: "10px" }}
          className="input-form"
          name="subject"
          type="text"
          value={mail.subject}
          onChange={handelChange}
        />
      </div>
      <div className="col-md-10 d-flex flex-d-c mb-3">
        <textarea
          style={{ fontSize: "large", paddingLeft: "10px" }}
          placeholder="Description..."
          name="text"
          id=""
          cols="20"
          rows="5"
          value={mail.text}
          onChange={handelChange}
        ></textarea>
      </div>
      <div
        className="col-md-10 d-flex flex-d-c mb-3"
        style={{ alignItems: "flex-end" }}
      >
        <ButtonsWrapper onSubmit={onSubmit} onCancel={dashboard} />
        {/* <ButtonsWrapper /> */}
      </div>
      <Modal isOpen={modalIsOpen} toggle={Verticalcentermodaltoggle} centered>
        <ModalHeader toggle={Verticalcentermodaltoggle}></ModalHeader>
        <ModalBody>
          <Row>
            <p
              style={{ fontSize: "15px", color: "green", textAlign: "center" }}
            >
              Votre réclamation est prise en charge !
            </p>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={dashboard}>
            Continuer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Reclamation;

import { contarctAxios } from "../../axiosConfig/InstancesAxios";
import contractService from "../contract/contractService";

export default {
  createCustomer: (Model) => {
    return contarctAxios.post("/createCustomer", Model);
  },

  updateCustomer: (model) => {
    return contarctAxios.post("/updateCustomer", model);
  },

  getCustomerById: (idCustomer) => {
    return contarctAxios.post("/getCustomerById", null, {
      params: {
        idCustomer,
      },
    });
  },

  sendCode: (idCustomer) => {
    return contarctAxios.post("/sendCode", null, {
      params: {
        idCustomer,
      },
    });
  },

  checkCodeConfirmation: (Model) => {
    return contarctAxios.post("/checkCodeConfirmation", Model);
  },

  sendMail: (Model) => {
    return contarctAxios.post("/sendMail", Model);
  },

  sendCodePassword: (Model) => {
    return contarctAxios.post("/sendCodePassword", Model);
  },

  checkCode: (Model) => {
    return contarctAxios.post("/checkCode", Model);
  },

  resetPassword: (Model) => {
    return contarctAxios.post("/resetPassword", Model);
  },

  logincustomer: (Model) => {
    return contarctAxios.post("garanti/login", Model);
  },

  updateCustomer: (Model) => {
    return contarctAxios.post("/updateCustomer", Model);
  },

  updateStatusOfCustomer: (Model) => {
    return contarctAxios.post("/updateStatusOfCustomer", Model);
  },

  createGoogleCustomer: (Model) => {
    return contarctAxios.post("/createGoogleCustomer", Model);
  },

  createFacebookCustomer: (Model) => {
    return contarctAxios.post("/createFacebookCustomer", Model);
  },

  updatePassword: (Model)=>{
    return contarctAxios.post("/updatePassword", Model)
  },

  sendMailCustomer: (Model)=>{
    return contarctAxios.post("/sendMailCustomer", Model)
  }
};

import React, { useState } from "react";
import { useHistory } from "react-router";
import sinistreService from "../../../services/sinistre/SinistreService";
import "./Sinistres.css";
const Sinistres = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  React.useEffect(() => {
    const id = JSON.parse(localStorage.getItem("user")).id;
    sinistreService.getSinistreByCustomer(id).then((res) => {
      setList(res.data);
    });
  }, []);
  const onSubmit = () => {
    history.push("/dashboard/sinistre/add-sinistre");
  };

  return (
    <div style={{ width: "100%", padding: "44px" }}>
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Déclaration de sinistre
      </h4>
      <div
        className="row col-md-12"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div className="nomenclature-container">
          <h6 className="nomenclature">Ouverture Dossier</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#AFD3E3" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">En cours de traitement</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#029AD9" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Produit récupéré</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#1A307C" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">En cours de réparation</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#F8548F" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Produit Réparé</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#914CA9" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Réglé</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#EEE501" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Rejeté</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#DE0609" }}
          ></div>
        </div>
        <div className="nomenclature-container">
          <h6 className="nomenclature">Livré</h6>
          <div
            className="boule-couleur"
            style={{ backgroundColor: "#26876B" }}
          ></div>
        </div>
      </div>
      <div className="tab-scroll">
        <div className="row col-md-12 tab-sinistres margin0">
          <div className="row col-md-12 tab-container">
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Numéro de contrat</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Numéro du sinistre</h6>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-3 col-3">
              <h6 className="title-tab">Date de prise en charge du sinistre</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">État</h6>
            </div>
          </div>
          {list.length != 0 ? (
            list?.map((el, key) => {
              return (
                <div className="row col-md-12 row-tab">
                  <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                    <h6 className="value-tab">{el.numContrat}</h6>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                    <h6 className="value-tab">{el.num}</h6>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-3 col-3">
                    <h6 className="value-tab">{el.date}</h6>
                  </div>
                  {/* <div className="col-md-3 col-lg-3 col-sm-3 col-3">
                  <h6 className="value-tab">{el.date}</h6>
                </div> */}
                  <div
                    className="col-md-2 col-lg-2 col-sm-2 col-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h6
                      className="value-tab"
                      style={{ marginRight: "5px", width: "fit-content" }}
                    >
                      En cours de traitement
                    </h6>
                    <div
                      className="boule-couleur"
                      style={{ backgroundColor: "#029AD9" }}
                    ></div>
                  </div>
                  <hr />
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <img width="100" src="/assets/images/dash-2.svg" />
              <h3>Aucune déclaration de sinistre en cours</h3>
            </div>
          )}
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary btn-continuer"
          onClick={onSubmit}
        >
          Déclarer un sinistre
        </button>
      </div>
    </div>
  );
};

export default Sinistres;

import React, { useEffect, useState } from "react";
import "./Souscription.css";
import { useHistory, useLocation } from "react-router";
import Stepper from "./Components/Stepper";
import NavBar from "./Components/NavBar";
import InputCustom2 from "../SubComponents/inputCustom/InputCustom2";
import InputDate from "../SubComponents/inputCustom/InputDate";
import InputFileCustom from "../SubComponents/inputFileCustom/InputFileCustom";
import { useDispatch, useSelector } from "react-redux";
import { savedataStepThree } from "./store/actionSouscription";
import { toast } from "react-toastify";
import contractService from "../../services/contract/contractService";
import { addDays } from "date-fns";
import moment from "moment";
const SouscriptionPage3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const [personelInfo, setPersonelInfo] = useState({});
  const [startDate, setStartDate] = useState((new Date(), -6570));
  const infoPerson = useSelector(
    ({ reducerSubscription }) => reducerSubscription?.step3
  );
  const customer = useSelector(({ userReducer }) => userReducer?.user);

  React.useEffect(() => {
    if (infoPerson) {
      setPersonelInfo(infoPerson);
    }
  }, [infoPerson]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonelInfo((personelInfo) => ({ ...personelInfo, [name]: value }));
  };

  //console.log(!customer.date_naissance && !personelInfo["Date De Naissance"]);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!personelInfo.Adresse) {
      toast.error("L'adresse est obligatoire.");
    } else if (!personelInfo["Code postal"]) {
      toast.error("Le Code postal est obligatoire.");
    } else if (!personelInfo.Ville) {
      toast.error("La Ville est obligatoire.");
    } else if (!personelInfo.PhotoCin) {
      toast.error("La copie recto de votre pièce d'identité est obligatoire.");
    } else if (!customer.date_naissance && !personelInfo["Date De Naissance"]) {
      toast.error("Date De Naissance est obligatoire.");
    } else {
      var data = new FormData();
      data.append("file", personelInfo.PhotoCin);
      await contractService.upload(data).then((res) => {
        setPersonelInfo((personelInfo) => ({
          ...personelInfo,
          PhotoCin: res.data,
        }));
      });
      setTimeout(() => {
        history.push({
          pathname: "/Souscription/Etape4",
        });
      }, 1500);
    }
  };
  //console.log(personelInfo);
  dispatch(savedataStepThree(personelInfo));
  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12 margin0">
      <NavBar text="Souscription" />
      <Stepper activeStep={1} />

      <div className="row col-md-12 d-flex-j">
        <div
          className="col-md-9 d-flex-d-c-w"
          style={{ marginTop: "60px", marginBottom: "60px" }}
        >
          <h2
            style={{
              marginTop: "40px",
              font: "normal normal bold 25px/27px Century Gothic",
              color: "#0C015F",
            }}
          >
            Mes informations Personnelles
          </h2>
          <div className="row col-md-10 " style={{ marginTop: "25px" }}>
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Civilité<span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="Civilité"
                className="input-form"
                style={{ color: "black", marginBottom: "14px", height: "54px" }}
                onChange={handleChange}
                value={customer.civility}
              >
                <option value="Mr">Mr</option>
                <option value="Mme">Mme</option>
                <option value="Mlle">Mlle</option>
              </select>
            </div>
          </div>
          <div className="row col-md-10" style={{ marginTop: "35px" }}>
            <InputCustom2
              name="Prénom"
              label="Prénom"
              onChange={handleChange}
              value={customer.firstName}
              disabled
            />
            <InputCustom2
              name="Nom"
              label="Nom"
              onChange={handleChange}
              value={customer.lastName}
              disabled
            />
          </div>
          <div className="row col-md-10  marginTop20">
            <InputDate
              name="Date De Naissance"
              label="Date de naissance"
              // onChange={handleChange}
              maxDate={addDays(new Date(), -6570)}
              selected={startDate}
              value={
                infoPerson?.["Date De Naissance"] ||
                moment(customer.date_naissance).format("DD/MM/yyyy")
              }
              onChange={(date) => {
                setStartDate(
                  customer.date_naissance
                    ? moment(customer.date_naissance).format("DD/MM/yyyy")
                    : date
                );
                handleChange({
                  target: { name: "Date De Naissance", value: date },
                });
              }}
              disabled={customer.date_naissance ? true : false}
            />
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0 }}
            >
              <label className="label">
                Nationalité<span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="Nationalité"
                className="input-form"
                style={{ color: "black", marginBottom: "14px" }}
                onChange={handleChange}
              >
                <option value="Tunisienne">Tunisienne</option>
                <option value="Étrangère">Étrangère</option>
              </select>
            </div>
          </div>
          <div className="row col-md-10" style={{ marginTop: "25px" }}>
            <InputCustom2
              name="E-mail"
              label="email"
              onChange={handleChange}
              value={customer.email}
              disabled
            />
            <InputCustom2
              name="Téléphone"
              label="Téléphone"
              onChange={handleChange}
              value={customer.phone}
              disabled
            />
          </div>

          <div className="row col-md-10" style={{ marginTop: "35px" }}>
            <InputCustom2
              name="Adresse"
              label="Adresse"
              onChange={handleChange}
              value={infoPerson?.["Adresse"]}
              adressCla="6"
            />
          </div>
          <div className="row col-md-10" style={{ marginTop: "35px" }}>
            <InputCustom2
              name="Code postal"
              label="Code postal"
              onChange={handleChange}
              value={infoPerson?.["Code postal"]}
            />
            <InputCustom2
              name="Ville"
              label="Ville"
              onChange={handleChange}
              value={infoPerson?.["Ville"]}
            />
          </div>
          <div className="row col-md-10" style={{ marginTop: "25px" }}>
            <InputFileCustom
              name="PhotoCin"
              label="Copie recto de votre pièce d'identité"
              onChange={handleChange}
            />
          </div>
          <div
            className="row col-md-10"
            style={{
              marginTop: "35px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="calcule-btn"
              style={{
                backgroundColor: "#D4D4D4",
                borderColor: "#D4D4D4",
                width: "150px",
              }}
              onClick={() => history.goBack()}
            >
              Retour
            </button>
            <button
              style={{
                backgroundColor: "#C727CC",
                borderColor: "#C727CC",
                marginLeft: "20px",
                width: "150px",
              }}
              id="souscription-etape2"
              className="calcule-btn"
              onClick={onSubmit}
            >
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SouscriptionPage3;

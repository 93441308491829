import { useState } from "react";
import { useHistory } from "react-router";
import NavBar from "./Components/NavBar";
import { saveAs } from "file-saver";
import "./Souscription.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const NoticeInfo = () => {
  const history = useHistory();
  const product = useSelector(
    ({ userReducer }) => userReducer.formule.fomula.family.familyName
  );
  const [auth, setAuth] = useState(false);
  const [q, setQ] = useState();
  const saveFile = () => {
    if (product === "Optique") {
      saveAs(
        `${process.env.PUBLIC_URL}/assets/pdf/NoticeOptique.pdf`,
        "NoticeOptique.pdf"
      );
      setAuth(true);
    } else {
      saveAs(
        `${process.env.PUBLIC_URL}/assets/pdf/Notice d'information.pdf`,
        "Notice d'information.pdf"
      );
      setAuth(true);
    }
  };
  const handleChange = (e) => {
    const { checked, name } = e.target;
    setQ((q) => ({ ...q, [name]: checked }));
  };
  const onSubmit = () => {
    if (auth && q?.Q1 && q?.Q2) {
      history.push("/confirmation");
    } else {
      toast.error("Veuillez lire attentivement la notice d’information.");
    }
  };
  //console.log(auth && q?.Q1 && q?.Q2);
  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12 margin0">
      <NavBar />
      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 question-souscription">
        <h2 className="question-title">Notice D'information</h2>
      </div>
      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 d-flex-j-c">
        <div className="col-md-8 paragraphe-notice">
          <p>
            Veuillez lire attentivement cette notice d’information, ce document
            d'information a pour but de vous informer des principales
            couvertures et exclusions relatives à cette assurance
          </p>
        </div>
        <div className="col-md-5">
          <button
            type="button"
            class="btn btn-primary btn-telecharger"
            onClick={saveFile}
          >
            Téléchargez la notice d'information
          </button>
        </div>

        {/* <div className="d-flex-a-c col-md-8" style={{ marginTop: "60px" }}>
          <input className="checkboxx" type="checkbox"></input>
          <label className="label-checkboxx">
            En cochant cette case, je reconnais avoir pris connaissance des
            conditions générales et particulières du produit d'assurance
            résumées dans la notice d'information ci-jointe, et je les accepte.
          </label>
        </div> */}
        <div
          className=" col-md-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5px",
          }}
        >
          <div
            className=" col-md-8 d-flex-a-c"
            style={{
              marginTop: "16px",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div className="d-flex-a-c" style={{ marginBottom: "10px" }}>
              <input
                className="checkboxx"
                type="checkbox"
                name="Q1"
                onClick={handleChange}
              ></input>
              <label className="label-checkboxx">
                En cochant cette case, je reconnais avoir pris connaissance des
                conditions d'utilisation du site web www.garanty.tn ainsi que sa
                politique de confidentialité, et je les accepte
              </label>
            </div>
            <div className="d-flex-a-c">
              <input
                className="checkboxx"
                type="checkbox"
                name="Q2"
                onClick={handleChange}
              ></input>
              <label className="label-checkboxx">
                En cochant cette case, je reconnais avoir pris connaissance des
                conditions générales et particulières du produit d'assurance
                résumées dans la notice d'information ci-jointe, et je les
                accepte.
              </label>
            </div>
          </div>
        </div>
        <div className=" col-md-8">
          <button
            type="button"
            class="btn btn-primary btn-continuer"
            onClick={onSubmit}
            id="souscription-etape3"
          >
            Continuer
          </button>
          <button
            type="button"
            class="btn btn-primary btn-annuler"
            onClick={() => history.push("/Souscription/Etape1Page3")}
          >
            Retour
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoticeInfo;

import { adminAxios } from "../../axiosConfig/InstancesAxios";

export default {
  getProductInfo: (idproduct) => {
    return adminAxios.post("/getAllproductinfobyproduct", null, {
      params: { idproduct },
    });
  },

  getQuestions: (idfamily) => {
    return adminAxios.post("/getAllquestionsbyFamily", null, {
      params: { idfamily },
    });
  },
  getInfoBa: (id) => {
    return adminAxios.post("/getValueInfo", null, { params: { id } });
  },

  getAllBreakdownbyformula: (idformula) => {
    return adminAxios.post("getAllBreakdownbyformula", null, {
      params: { idformula },
    });
  },

  getcontractProductByIdContract: (id_contract) => {
    return adminAxios.post("/getcontractProductByIdContract", null, {
      params: { id_contract },
    });
  },

  getProductByName: (name) => {
    return adminAxios.post("/getProductByName", null, { params: { name } });
  },
  getMarqueByProduct: (idProduct) => {
    return adminAxios.post("/getMarqueByProduct", null, {
      params: { idProduct },
    });
  },
  sendContact: (Model) => {
    return adminAxios.post("/sendContact", Model);
  },
  addToNewsletter: (Model) => {
    return adminAxios.post("/addToNewsletter", Model);
  },
 
};

import React from "react";
import ButtonsWrapper from "../../SubComponents/ButtonsWrapper ";

const FAQ = () => {
  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        FAQ
      </h4>
      <h6
        style={{
          font: "normal normal bold 15px/22px Century Gothic",
          color: "gray",
        }}
      >
        Poser une question
      </h6>
      <div className="body-sinistre" style={{ paddingLeft: "50px" }}>
        <div
          className="row col-md-12 col-lg-12 col-sm-12"
          style={{ paddingRight: "100px" }}
        >
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Civilité</label>
            <select
              style={{
                paddingLeft: "10px",
                color: "black",
                fontSize: "medium",
              }}
              className="input-form"
              name="civility"
              placeholder="civility"
              //   onChange={handelChange}
            >
              <option
              //   defaultValue={customer.civility}
              >
                civility{/* {customer.civility} */}
              </option>
              <option value="Mr">Mr</option>
              <option value="Mme">Mme</option>
              <option value="Mlle">Mlle</option>
            </select>
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Nom</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="lastName"
              type="text"
              placeholder="Nom"
              //   defaultValue={customer.lastName}
              //   onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Prénom</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="firstName"
              type="text"
              placeholder="Prénom"
              //   defaultValue={customer.firstName}
              //   onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Mobile</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="date_naissance"
              type="text"
              placeholder="Mobile"
              //   defaultValue={customer.date_naissance}
              //   onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Email</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="cin"
              type="text"
              placeholder="Email"
              //   defaultValue={customer.cin}
              //   onChange={handelChange}
            />
          </div>
          <div className="col-md-6 d-flex flex-d-c mb-3"></div>
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Votre souci concerne?</label>
            <select
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="email"
              //   defaultValue={customer.email}
              //   onChange={handelChange}
            >
              <option value="">sélectionnez</option>
            </select>
          </div>
          <div className="col-md-12 d-flex flex-d-c mb-3">
            <label className="label">Votre message</label>
            <textarea
              placeholder="Description..."
              name="description"
              id=""
              cols="20"
              rows="3"
            ></textarea>
          </div>
          <div
            className="col-md-12 d-flex flex-d-c mb-3"
            style={{ alignItems: "flex-end" }}
          >
            <ButtonsWrapper
            //  onSubmit={updateCustomer} onCancel={dashboard}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

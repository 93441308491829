import * as ACTION from "./actionSouscription";

const initialState = {
  data: null,
  step0: {},
  step1: {},
  step2: {},
  step3: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.INIT:
      return {
        ...state,
        data: null
      };
    case ACTION.SET_STEP_0:
      return {
        ...state,
        step0: action.payload
      };
      
    case ACTION.SET_STEP_1:
      return {
        ...state,
        step1: action.payload
      };
    case ACTION.SET_STEP_2:
      return {
        ...state,
        step2: action.payload
      };
    case ACTION.SET_STEP_3:
      return {
        ...state,
        step3: action.payload
      };

    default:
      return state;
  }
};

export default reducer;

import { AiOutlineEye } from 'react-icons/ai';
export const data = [
    {
        idsinister: "2A3B4C",
        idcontract_product: "AkAProdigy1",
        date: "15/06/2001",
        location: "Kram west",
        comment: "aloha beaches",
        date_creation: "28/06/2022",
        status: "1",
        num: "2112",
        numContrat:"123",
        constatedate: "31/02/1997",
        damagecause: "accidenté",
        simplace: "azqedfez",
        prechhand: "qsfvqf",
        plliquidecontact: "qsvfsvef",
        repdev: "qsdvqsfv",
        prodrep: "sggsd",
        damagetype: "fgdgfn",
        prodfonc: "dfbdfba",
        prodallu: "sdbgsdb",
        prodcharge: "dfqbfdsq",
        connectfonc: "sdfbq",
        ecrantac: "regerqg",
        ecran: "qsrgettz",
        affecran: "trhtyzs",
        facadearriere: "svqvve",
        prodsound: "azuhfpiu",
        facturephoto: "azilrfhaz.jpg",
        prodphoto: "orz,afor.jpg",
        phone: "98745236",
       
    },
    {
        idsinister: "2A3B4C",
        idcontract_product: "AkAProdigy1",
        date: "15/06/2001",
        location: "Kram west",
        comment: "aloha beaches",
        date_creation: "28/06/2022",
        status: "1",
        num: "2112",
        constatedate: "31/02/1997",
        damagecause: "accidenté",
        simplace: "azqedfez",
        prechhand: "qsfvqf",
        plliquidecontact: "qsvfsvef",
        repdev: "qsdvqsfv",
        prodrep: "sggsd",
        damagetype: "fgdgfn",
        prodfonc: "dfbdfba",
        prodallu: "sdbgsdb",
        prodcharge: "dfqbfdsq",
        connectfonc: "sdfbq",
        ecrantac: "regerqg",
        ecran: "qsrgettz",
        affecran: "trhtyzs",
        facadearriere: "svqvve",
        prodsound: "azuhfpiu",
        facturephoto: "azilrfhaz.jpg",
        prodphoto: "orz,afor.jpg",
        phone: "98745236",
       
    },
    {
        idsinister: "2A3B4C",
        idcontract_product: "AkAProdigy1",
        date: "15/06/2001",
        location: "Kram west",
        comment: "aloha beaches",
        date_creation: "28/06/2022",
        status: "1",
        num: "2112",
        constatedate: "31/02/1997",
        damagecause: "accidenté",
        simplace: "azqedfez",
        prechhand: "qsfvqf",
        plliquidecontact: "qsvfsvef",
        repdev: "qsdvqsfv",
        prodrep: "sggsd",
        damagetype: "fgdgfn",
        prodfonc: "dfbdfba",
        prodallu: "sdbgsdb",
        prodcharge: "dfqbfdsq",
        connectfonc: "sdfbq",
        ecrantac: "regerqg",
        ecran: "qsrgettz",
        affecran: "trhtyzs",
        facadearriere: "svqvve",
        prodsound: "azuhfpiu",
        facturephoto: "azilrfhaz.jpg",
        prodphoto: "orz,afor.jpg",
        phone: "98745236",
       
    }

]
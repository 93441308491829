import React, { useState } from "react";
// import * as FaIcons from "react-icons/fa";

import { Link, useHistory } from "react-router-dom";
import { SidebarData } from "../SidebarData";
import { IconContext } from "react-icons";
import NavBar from "../../souscription/Components/NavBar";
import { useDispatch } from "react-redux";
import { setSideBarStatus } from "../../../store/actions";
import "./NavBar.css";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";

function NavBarSide(props) {
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(true);
  const [showSub, setShowSub] = useState(-1);
  const [showSubSub, setShowSubSub] = useState(-1);
  const [isVisible, setIsVisible] = useState(false);
  const stdd = history.location.pathname === "/dashboard/contactez-nous";
  const stdd1 =
    history.location.pathname === "/dashboard/contactez-nous/assistance";
  const stdd2 =
    history.location.pathname ===
    "/dashboard/contactez-nous/assistance/nos-partenaire";
  React.useEffect(() => {
    dispatch(setSideBarStatus(sidebar));
  }, []);

  const showSidebar = () => {
    dispatch(setSideBarStatus(!sidebar));
    setSidebar(!sidebar);
  };
  React.useEffect(() => {
    if (windowWidth <= 900) {
      dispatch(setSideBarStatus(false));
      setSidebar(false);
    }
  }, [windowWidth]);

  return (
    <>
      <IconContext.Provider value={{ color: "black" }}>
        <NavBar onClickMenu={showSidebar} />
        <nav
          className={
            windowWidth <= 899
              ? sidebar
                ? "col-lg-3 col-md-3 nav-menu-s active "
                : "nav-menu-s"
              : sidebar
              ? "col-lg-3 col-md-3 nav-menu-bar active "
              : "nav-menu-bar"
          }
        >
          <ul className="nav-menu-items">
            {SidebarData.map((item, index) => {
              if (item.path) {
                return (
                  <li
                    style={{ display: "flex", flexDirection: "column" }}
                    key={index}
                    className={`${item.cName} ${
                      showSub === index && "selected"
                    }`}
                    onClick={() => {
                      setShowSub(index);
                      windowWidth <= 900 && showSidebar();
                    }}
                  >
                    <Link to={item.path}>
                      {item.icon}
                      <span
                        style={{
                          font: "normal normal normal 18px/25px Century Gothic",
                        }}
                      >
                        {item.title}
                      </span>
                    </Link>
                  </li>
                );
              } else {
                return (
                  <div key={index}>
                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      key={index}
                      className={`${item.cName}  ${
                        showSub === index && "selected"
                      }`}
                      onClick={() => {
                        setShowSub(index);
                        setIsVisible(!isVisible)
                        windowWidth <= 900 && showSidebar();
                      }}
                    >
                      <div className="div" type="button">
                        {item.icon}
                        <span
                          style={{
                            font:
                              "normal normal normal 18px/25px Century Gothic",
                          }}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                    {showSub === index && isVisible &&
                      item.child.map((childItem, indx) => {
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "column",marginTop:'-5px' }}
                            key={indx}
                            className={`${item.cName}  ${
                              showSubSub === indx && "selected"
                            }`}
                            onClick={() => {
                              // setShowSub(index);
                              windowWidth <= 900 && showSidebar();
                            }}
                          >
                            <Link
                              to={childItem.path}
                              onClick={() => {
                                setShowSubSub(indx);
                              }}
                              style={{
                                width: "90%",
                                fontSize: "12px",
                                marginLeft: "15px",
                              }}
                            >
                              {/* {childItem.icon} */}
                              {childItem.title}
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                );
              }

              // return (

              //   // <li
              //   //   style={{ display: "flex", flexDirection: "column" }}
              //   //   key={index}
              //   //   className={`${
              //   //     (stdd || stdd1 || stdd2) && item.cName1
              //   //       ? item.cName1
              //   //       : item.cName
              //   //   } ${showSub === index && "selected"}`}
              //   //   onClick={() => {
              //   //     setShowSub(index);
              //   //     windowWidth <= 900 && showSidebar();
              //   //   }}
              //   // >
              //   //   <Link to={item.path}>
              //   //     {item.icon}
              //   //     <span
              //   //       style={{
              //   //         font: "normal normal normal 18px/25px Century Gothic",
              //   //       }}
              //   //     >
              //   //       {item.title}
              //   //     </span>
              //   //   </Link>
              //   //   {item.child && showSub === index ? (
              //   //     <div>
              //   //       {item.child.map((childItem, indx) => {
              //   //         return (
              //   //           <li
              //   //             key={indx}
              //   //             className="nav-text"
              //   //             style={{ display: "flex", flexDirection: "column" }}
              //   //           >
              //   //             {childItem.type === "link" ? (
              //   //               <Link
              //   //                 to={childItem.path}
              //   //                 onClick={() => {
              //   //                   setShowSubSub(indx);
              //   //                 }}
              //   //               >
              //   //                 {/* {childItem.icon} */}
              //   //                 {childItem.title}
              //   //               </Link>
              //   //             ) : (
              //   //               ""
              //   //             )}
              //   //             {childItem.child && showSubSub === indx ? (
              //   //               <div>
              //   //                 {childItem.child.map((el, ind) => {
              //   //                   return (
              //   //                     <li key={ind} className="nav-text">
              //   //                       <Link
              //   //                         to={el.path}
              //   //                       >
              //   //                         {el.icon}
              //   //                         {el.title}
              //   //                       </Link>
              //   //                     </li>
              //   //                   );
              //   //                 })}
              //   //               </div>
              //   //             ) : (
              //   //               ""
              //   //             )}
              //   //           </li>
              //   //         );
              //   //       })}
              //   //     </div>
              //   //   ) : (
              //   //     ""
              //   //   )}
              //   // </li>
              // );
              // }
              // )
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default NavBarSide;

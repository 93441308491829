import { useState } from "react";

import Footer from "../Footer/Footer";
import NavBarHome from "../home/NavBarHome";
import DocumentMeta from "react-document-meta";
import "./apropos.css";
import { useHistory, useLocation } from "react-router-dom";

const AproposNous = () => {
  const history= useHistory()
  const location = useLocation()
  const family= location.state
  const meta = {
    title: 'Garanty Tunisie : assurance mobile, multimédia, optique, électroménager',
    description: "Assurance digitale proposant des formules de garantie personnalisées pour smartphone, multimédia, optique, électroménager: souscrivez et payez en ligne en toute sécurité ",
    canonical: 'http://example.com/path/to/page',
    meta: {
      charset: 'utf-8',
      'http-equiv': {
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
        expires: '0',
        pragma: 'no-cache',
      },
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };
  return (
    <DocumentMeta {...meta}>
      <NavBarHome dark relative />
      {/*  part1 */}
      <div
        style={{
          color: "white",
          font: " normal normal bold 60px/48px Century Gothic",
          display: "flex",
          justifyContent: "left",
          paddingLeft: "10%",
          alignItems: "center",

          background: `url(
            ${process.env.PUBLIC_URL}/assets/images/aboutSlide.jpg) no-repeat center `,

          backgroundSize: "cover",

          height: "640px",
        }}
      >
        <div>
          <p style={{ display: "inline" }}> Nous, c'est </p>
          <span style={{ color: "#C727CC" }}> Garanty.</span>
          <p style={{ fontSize: "40px", marginTop: "10px" }}>
            La première assurance qui vous ressemble.
          </p>
        </div>
      </div>
      {/*  part 2 */}
      <div
        style={{
          width: "100%",
          backgroundColor: "#0C015F",
          padding: "80px",
          display: "flex",
          // justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          alignContent: "space-between",
          color: "white",
        }}
      >
        <p style={{ font: "normal normal bold 45px/40px Century Gothic" }}>
          La seule assurance 100%...
        </p>

        <div
          style={{
            marginTop: "100px",
            color: "#40678A",
            font: "normal normal bold 100px/120px Century Gothic;",
          }}
        >
          <p
            style={{
              font: "normal normal bold 40px/40px Century Gothic",
              marginBottom: "60px",
            }}
          >
            Digitale
          </p>
          <p
            style={{
              font: "normal normal bold 40px/40px Century Gothic",
              marginBottom: "60px",
            }}
          >
            Flexible
          </p>
          <p
            style={{
              font: "normal normal bold 40px/40px Century Gothic",
              marginBottom: "100px",
            }}
          >
            Personnalisée
          </p>
        </div>

        <div>
          <img
            style={{ maxWidth: "90%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/about3.png`}
          />
        </div>
      </div>
      {/*  part 3*********** */}
      <div
        style={{
          font: "normal normal bold 45px/60px Century Gothic",
          color: "#0C015F",
          textAlign: "center",
          padding: "50px",
        }}
      >
        <p>
          Pourquoi assurer mes <br /> appareils ?
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            WebkitFlexWrap: "wrap",
            marginTop: "80px",
          }}
        >
          <Card
            num="80"
            subText="%"
            text="Des personnes ont déjà cassé un de leurs produits high-tech"
          ></Card>
          <Card
            num="400"
            subText="DT"
            text="Coût moyen de la réparation d’un appareil"
          ></Card>
          <Card
            num="36"
            subText="h"
            text="Temps passé à vous prendre la tête pour la réparation
            Nous prolongeons la durée de vie de vos
            appareils"
          ></Card>
        </div>
      </div>
      {/*  part 4*****************/}

      <div
        style={{ width: "100%", backgroundColor: "#D1F2EF", padding: "80px" }}
      >
        <p
          style={{
            textAlign: "center",
            font: "normal normal bold 45px/60px Century Gothic",
            letterSpacing: "0px",
            color: "#0C015F",
          }}
        >
          Nous avons réinventé <br /> l'assurance
        </p>

        <div
          style={{
            width: "100%",
            flex: "1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "80px",
            flexWrap: "wrap",
          }}
        >
          <img
            style={{ maxWidth: "300px", borderRadius: "10px" }}
            src={`${process.env.PUBLIC_URL}/assets/images/about1.png`}
          />
          <div style={{ maxWidth: "400px" }}>
            <p
              style={{
                font: "normal normal bold 40px/40px Century Gothic",
              }}
            >
              … en commençant par vous
            </p>
            <p
              style={{
                font: "normal normal normal 20px/24px Century Gothic",
              }}
            >
              Dans nos produits, nos idées, nos décisions, vous êtes toujours au
              centre. Nous protégeons ce qui est important à vos yeux.
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            flex: "1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "80px",
            flexWrap: "wrap-reverse",
          }}
        >
          <div style={{ maxWidth: "430px" }}>
            <p
              style={{
                font: "normal normal bold 40px/40px Century Gothic",
              }}
            >
              ...en étant transparent
            </p>
            <p
              style={{
                font: "normal normal normal 20px/24px Century Gothic",
              }}
            >
              Nous avons compris que vous deviez pouvoir nous faire confiance.
              Garanty  est la solution parfaite pour protéger votre style de
              vie.
            </p>
          </div>
          <img
            style={{ maxWidth: "300px", borderRadius: "10px" }}
            src={`${process.env.PUBLIC_URL}/assets/images/about2.png`}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: "12rem" }}>
          <p
            style={{
              textAlign: "center",
              font: "normal normal bold 40px/40px Century Gothic",
              letterSpacing: "0px",
              color: "#0C015F",
            }}
          >
            ...en voulant améliorer votre vie <br /> On a tout prévu pour faire
            face à l'imprévu !
          </p>
          <p
            style={{
              font: "normal normal normal 20px/24px Century Gothic",
            }}
          >
            Parce que nous pensons qu'être bien <br />
            protégé, c'est dèja vivre mieux.
          </p>
        </div>
      </div>
      {/*  part 5*****************/}
      <div
        style={{
          textAlign: "center",
          padding: "100px",
          backgroundColor: "#0C015F",
        }}
      >
        <p
          style={{
            font: "normal normal bold 60px/68px Century Gothic",
            letterSpacing: "0px",
            color: "#7BD2C7",
            marginBottom: "90px",
          }}
        >
          C'est pour ça que nous avons <br /> créé Garanty.
        </p>
        <img
          style={{ maxWidth: "70rem", width: "-webkit-fill-available" }}
          src={`${process.env.PUBLIC_URL}/assets/images/images.png`}
        />
        <p
          style={{
            font: "normal normal normal 20px/24px Century Gothic",
            letterSpacing: "0px",
            color: " #FFFFFF",
            marginTop: "80px",
          }}
        >
          Une assurance que vous contrôlez pleinement, en quelques
          <br /> clics, et qui améliore votre vie
        </p>

        <p
          style={{
            font: "normal normal bold 44px/40px Century Gothic",
            letterSpacing: "0px",
            color: "#FFFFFF",
            marginTop: "100px",
          }}
        >
          Alors bienvenue au futur de l'assurance
        </p>
      </div>
      {/*  ********************part 6*****************/}
      <div
        style={{
          backgroundColor: "#7BD2C7",
          width: "100%",
          textAlign: "center",
          padding: "80px",
        }}
      >
        <p
          style={{
            font: "normal normal normal 28px/34px Century Gothic",
            letterSpacing: "0px",
            color: "#FFFFFF",
          }}
        >
          N'hésitez pas à nous contacter
        </p>
        <p
          style={{
            font: "normal normal normal 18px/34px Century Gothic",
            letterSpacing: "0px",
            color: "#FFFFFF",
          }}
        >
          Une question ? Une demande ? Ou même une idée
        </p>
        <button
          type="button"
          onClick={()=>{
            history.push({pathname:"/contact",state:family})
          }}
          style={{
            marginTop: "20px",
            background: "linear-gradient(#6e65be,#372884) 0% 0% no-repeat padding-box",
            borderRadius: "40px",
            color: "white",
            font: "normal normal bold 25px/27px Century Gothic",
            paddingBlock: "12px",
            paddingInline: "50px",
          }}
        >
          Parlons-en !
        </button>
      </div>
      {/*  ********************footer*****************/}

      <Footer />
    </DocumentMeta>
  );
};

const Card = ({ num, text, subText }) => {
  return (
    <div
      style={{
        width: "300px",
        textAlign: "left",
      }}
    >
      <p
        style={{
          font: "normal normal bold 60px/60px Century Gothic",
          color: "#AD81EA",
        }}
      >
        {num}
        <sub
          style={{
            verticalAlign: "super",
            font: "font: normal normal bold Century Gothic;",
            fontSize: "20px",
            top: "-9px",
            color: "#C41AB4",
          }}
        >
          {subText}
        </sub>
      </p>
      <p
        style={{
          textAlign: "left",
          font: "normal normal normal 20px/24px Century Gothic",
          letterSpacing: "0px",
          color: "#3B3B3B",
        }}
      >
        {text}
      </p>
    </div>
  );
};
export default AproposNous;

import React from "react";

export const SidebarData = [
  {
    title: "Tableau de bord",
    path: "/dashboard",
    icon: (
      <img
        width="30"
        height="50"
        style={{ marginLeft: "14px", marginRight: "10px" }}
        src={`${process.env.PUBLIC_URL}/assets/images/dashIcon.svg`}
      />
    ),
    cName: "nav-text",
    state: false,
  },
  {
    title: "Mes objets assurés",
    path: "/dashboard/contract",
    icon: (
      <img
        width="50"
        src={`${process.env.PUBLIC_URL}/assets/images/dash-1.svg`}
      />
    ),
    cName: "nav-text",
    state: false,
  },
  {
    title: "Mes Sinistres",
    path: "/dashboard/sinistre",
    icon: (
      <img
        width="50"
        src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`}
      />
    ),
    cName: "nav-text",
    state: false,
    // child: [
    //   {
    //     title: "Tous les sinistres",
    //     path: "/dashboard/Sinistre/TousLesSinistres",
    //     icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "Nouveaux Sinistres",
    //     path: "/dashboard/Sinistre/NouveauxSinistres",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente De Compléments De Documents",
    //     path: "/dashboard/Sinistre/EnAttenteDeComplémentsDeDocuments",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente DeValidation De La Conformité",
    //     path: "/dashboard/Sinistre/EnAttenteDeValidationDeLaConformité",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente Du Diagnostic Du Réparateur",
    //     path: "/dashboard/Sinistre/EnAttenteDuDiagnosticDuRéparateur",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente Du Retrait De Chez Client",
    //     path: "/dashboard/Sinistre/EnAttenteDuRetraitDeChezLeClient",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente De l'Expertise Préréparation",
    //     path: "/dashboard/Sinistre/EnAttenteDelExpertisePréréparation",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente Du Transfert Vers Le Réparateur",
    //     path: "/dashboard/Sinistre/EnAttenteDuTransfertVersLeRéparateur",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente De La Validation Du Devis",
    //     path: "/dashboard/Sinistre/EnAttenteDeLaValidationDuDevis",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente Du Retrait De Chez Le Réparateur",
    //     path: "/dashboard/Sinistre/EnAttenteDuRetraitDeChezLeRéparateurs",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente De l'expertise Post-réparations",
    //     path: "/dashboard/Sinistre/EnAttenteDelexpertisePostréparation",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "En Attente De La Livraison",
    //     path: "/dashboard/Sinistre/EnAttenteDeLaLivraison",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   },
    //   {
    //     title: "Clôturés",
    //     path: "/dashboard/Sinistre/Clôturés",
    //     // icon: <img width="50" src={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`} />,
    //     cName: "nav-text",
    //     state: false,
    //     type: "link",
    //   }
   
    // ],
  },
  {
    title: "Mes Remboursements",
    path: "/dashboard/Sinistre/remboursements",
    icon: (
      <img
        width="50"
        src={`${process.env.PUBLIC_URL}/assets/images/dash-3.svg`}
      />
    ),
    cName: "nav-text",
    state: false,
  },
  {
    title: "Mon Compte",
    path: "/dashboard/mon-compte",
    icon: (
      <img
        width="50"
        src={`${process.env.PUBLIC_URL}/assets/images/dash-4.svg`}
      />
    ),
    cName: "nav-text",
    state: false,
  },
  {
    title: "Contactez-Nous",
    path: "/dashboard/contactez-nous",
    icon: (
      <img
        width="50"
        src={`${process.env.PUBLIC_URL}/assets/images/dash-4.svg`}
      />
    ),
    // cName1:"nav-text1",
    cName: "nav-text",
    active: false,
  },
  {
    title: "Nos partenaires",
    path: "/dashboard/contactez-nous/assistance/nos-partenaire",
    icon: (
      <img
        width="50"
        src={`${process.env.PUBLIC_URL}/assets/images/dash-4.svg`}
      />
    ),
    cName: "nav-text",
    active: false,
  },
  {
    title: "Mentions légales",
    path: "/dashboard/mentions-legales",
    icon: (
      <img
        height="50"
        width="30"
        style={{ marginLeft: "7px", marginRight: "10px" }}
        src={`${process.env.PUBLIC_URL}/assets/images/open-book.svg`}
      />
    ),
    cName: "nav-text",
    state: false,
  },
];

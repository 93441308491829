import React from "react";

function SelectCustom({ name, label, mrq, ...props }) {
  if (label == "Type de lunette") {
    return (
      <div className="col-md-6 d-flex flex-d-c mb-3">
        <label className="label">
          {label}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          name={name}
          className="input-form"
          style={{ color: "black" }}
          {...props}
        >
          <option value="">Choisir...</option>
          <option value="Lunette de vue">Lunette de vue</option>
          <option value="Lunette de soleil">Lunette de soleil</option>
        </select>
      </div>
    );
  } else if (label == "Types de verres") {
    return (
      <div className="col-md-6 d-flex flex-d-c mb-3">
        <label className="label">
          {label}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          name={name}
          className="input-form"
          style={{ color: "black" }}
          {...props}
        >
          <option value="">Choisir...</option>
          <option value="Verres de correction">Verres de correction</option>
          <option value="Verres solaires">Verres solaires</option>
          <option value="Verres de correction et solaires">
            Verres de correction et solaires
          </option>
        </select>
      </div>
    );
  } else {
    return (
      <div className="col-md-6 d-flex flex-d-c mb-3">
        <label className="label">
          {label} <span style={{ color: "red" }}>*</span>
        </label>
        <select
          name={name}
          className="input-form"
          style={{ color: "black" }}
          {...props}
        >
          <option value="">Choisir...</option>
          {mrq.map((el, key) => (
            <option key={key} value={el}>
              {el}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default SelectCustom;

export const ADD_USERS_REQUEST = "ADD_USERS_REQUEST";
export const ADD_FORMULE_REQUEST = "ADD_FORMULE_REQUEST";

export const saveUser = (data) => {
  return (dispatch) => {
    dispatch(addUserRequest(data));
  };
};

export const saveFormule = (data) => {
  return (dispatch) => {
    dispatch(addFormuleRequest(data));
  };
};

export const addUserRequest = (user) => {
  return {
    type: ADD_USERS_REQUEST,
    payload: user,
  };
};

export const addFormuleRequest = (formule) => {
  return {
    type: ADD_FORMULE_REQUEST,
    payload: formule,
  };
};

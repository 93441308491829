import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ButtonsWrapper from "../SubComponents/ButtonsWrapper ";
import NavBar from "./Components/NavBar";
import Stepper from "./Components/Stepper";
import { useSelector } from "react-redux";
import "./Souscription.css";
import contractService from "../../services/contract/contractService";
import adminService from "../../services/adminService/adminService";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import { toast } from "react-toastify";
import { contarctAxios } from "../../axiosConfig/InstancesAxios";
function Payment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const [paymentType, setPaymentType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#aa74e8");
  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
  `;
  const formule = useSelector(({ userReducer }) => userReducer.formule);
  const customer = useSelector(({ userReducer }) => userReducer.user);
  const question = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step1
  );
  const myPhone = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step2
  );
  //console.log(myPhone);
  const personelInfo = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step3
  );
  //console.log(personelInfo);
  // //console.log(personelInfo["Date De Naissance"].toISOString());
  const [typePayment, setTypePayment] = useState({});
  const [idproduct, setIdProduct] = useState();
  const [numBon, setNumBon] = useState();
  const [payment, setPayment] = useState({
    paymentmode: "",
  });
  var newDate = new Date();
  var thisMonth = newDate.getUTCMonth();
  var plusMois = 12;
  var endDate = new Date();
  endDate.setUTCMonth(thisMonth + plusMois);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setNumBon(value);
  };
  React.useEffect(() => {
    adminService
      .getProductByName(formule?.fomula?.family?.familyName)
      .then((res) => {
        setIdProduct(res.data.idproduct);
      });
    if (!formule) {
      history.push("/");
    }
  }, []);
  const onSubmit = async () => {
    setLoading(!loading);
    let contract = {
      customerContractDto: {
        adress: personelInfo["Adresse"],
        birthdate: personelInfo["Date De Naissance"]?.toISOString(),
        cin: customer.cin,
        city: personelInfo["Ville"],
        idcountry: "1203e7fc-60a8-4cf7-a93f-ae369eacfa08",
        idcustomer: customer.idcustomer,
        postalCode: personelInfo["Code postal"] * 1,
        street: personelInfo["Rue"],
        photocin: personelInfo.PhotoCin,
      },
      listproducts: {
        idproduct: idproduct,
        listvalueinfosDto: Object.keys(myPhone).map((el) => ({
          idproductInfo: myPhone[el].id,
          value: myPhone[el].value,
        })),
      },
      listquestioncontractsDto: Object.keys(question).map((el) => ({
        idQuestion: el,
        value: question[el] * 1,
      })),
      cguAssurance: 0,
      cguWebSite: 0,
      dateCreation: new Date().toISOString(),
      endDate: endDate.toISOString(),
      idformula: formule.fomula.idformula,
      idcountry: "1203e7fc-60a8-4cf7-a93f-ae369eacfa08",
      idtypePayment: payment.paymentmode,
      noticeDownloaded: 0,
      startDate: new Date().toISOString(),
      frontingHtAssurance: formule.fomula.frontingHtAssurance,
      frontingTtcAssurance: formule.fomula.frontingTtcAssurance,
      frontingHtCourtier: formule.fomula.frontingHtCourtier,
      margeDistributeurHt: formule.fomula.margeDistributeurHt,
      margeDistributeurTtc: formule.fomula.margeDistributeurTtc,
      netPriceHt: formule.fomula.netPriceHt,
      netPriceTtc: formule.fomula.netPriceTtc,
      tua: formule.fomula.tua,
    };
    if (paymentType === 6) {
      console.log("inn");
      contractService.voucherCheck(numBon,contract.idformula).then((res) => {
        if (res.status === 200) {
          contractService.createContract(contract).then(async (res) => {
            await contractService.generateContrat(res.data.idcontract);
            contractService
              .sign({
                failUrl: `${window.location.origin}/404`,
                id_contract: res.data.idcontract,
                successUrl: `${window.location.origin}/dashboard/contract?creation?=ok`,
              })
              .then((res) => {
                window.open(res.data, "_self");
              })
              .catch((Error) => {
                //console.log(Error);
              });
          });
        }
      });
    } else {
      contractService.createContract(contract).then(async (res) => {
        console.log(res);
        if (paymentType === 1) {
          contractService
            .payment({
              amountTotal: formule.fomula.price,
              failUrl: `${window.location.origin}/404`,
              idcontract: res.data.idcontract,
              returnUrl: `${window.location.origin}/membership`,
            })
            .then((result) => {
              window.open(result.data.formUrl, "_blank");
            });
        } else if (paymentType === 2) {
          await contractService.generateContrat(res.data.idcontract);
          contractService
            .sign({
              failUrl: `${window.location.origin}/404`,
              id_contract: res.data.idcontract,
              successUrl: `${window.location.origin}/dashboard/contract?creation?=ok`,
            })
            .then((res) => {
              window.open(res.data, "_self");
            })
            .catch((Error) => {
              //console.log(Error);
            });
        }
        // else if (paymentType === 3) {
        //   toast.error(
        //     "Le bon d'achat saisi ne correspond pas à la formule sélectionnée. Prière de choisir la bonne formule ou contacter notre service client au 71  169 000"
        //   );
        //   toast.error(
        //     "Le bon d'achat est erroné. Prière de réessayer ou contacter notre service client au 71  169 000"
        //   );
        // }
      });
    }
  };
  const onCancel = () => {
    history.push("/confirmation");
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setPayment({ [name]: value });
  };

  React.useEffect(() => {
    contractService.getTypePayment().then((response) => {
      setTypePayment(response.data);
    });
  }, []);

  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
      <NavBar />
      <Stepper activeStep={3} />
      <div className="row col-12 d-flex-j-c">
        <div className="col-lg-10 col-md-12 mt-5 mb-5">
          <p className="dot-value mb-5">Mode de paiement </p>

          <div className="form-check radio-center">
            <input
              className="form-check-input"
              type="radio"
              name="paymentmode"
              onClick={() => setPaymentType(1)}
              onChange={onChange}
              value={typePayment[0]?.idpaymentType}
            />
            <label
              style={{ marginLeft: "1rem" }}
              className="form-check-label"
              for="flexRadioDefault1"
            >
              <img
                style={{ objectFit: "contain" }}
                width="70"
                height="30"
                src={`${process.env.PUBLIC_URL}/assets/images/visa.png`}
              />
              <img
                style={{ objectFit: "contain" }}
                width="80"
                height="40"
                src={`${process.env.PUBLIC_URL}/assets/images/master2.png`}
              />
            </label>
          </div>
          <div className="form-check radio-center">
            <input
              className="form-check-input "
              type="radio"
              name="paymentmode"
              onClick={() => setPaymentType(2)}
              onChange={onChange}
              value={typePayment[1]?.idpaymentType}
            />
            <label
              style={{ marginLeft: "1rem" }}
              className="form-check-label "
              for="flexRadioDefault2"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  style={{ objectFit: "contain" }}
                  width="70"
                  height="40"
                  src={`${process.env.PUBLIC_URL}/assets/images/pngegg.png`}
                />
                <p
                  style={{
                    display: "inline",
                    whiteSpace: "pre-wrap",
                    textAlign: "justify",
                    marginBottom: "0",
                    font: "normal normal normal 14px/22px Century Gothic",
                  }}
                >
                  {"Paiement \nà la livraison du Contrat"}
                </p>
              </div>
            </label>
          </div>
          <div className="form-check radio-center">
            <input
              className="form-check-input "
              type="radio"
              name="paymentmode"
              onClick={() => setPaymentType(6)}
              onChange={onChange}
              value={typePayment[5]?.idpaymentType}
            />
            <label
              style={{ marginLeft: "1rem" }}
              className="form-check-label "
              for="flexRadioDefault2"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  style={{ objectFit: "contain" }}
                  width="70"
                  height="40"
                  src={`${process.env.PUBLIC_URL}/assets/images/bonA.png`}
                />
                <p
                  style={{
                    display: "inline",
                    whiteSpace: "pre-wrap",
                    textAlign: "justify",
                    marginBottom: "0",
                    font: "normal normal normal 14px/22px Century Gothic",
                  }}
                >
                  {"Paiement \npar Bon d'achat"}
                </p>
              </div>
            </label>
          </div>

          {paymentType === 6 ? (
            <div
              className="row col-md-3"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "5%",
              }}
            >
              <label htmlFor="" style={{ marginBottom: "12px" }}>
                N° du bon:
              </label>
              <input
                type="text"
                name="numbon"
                onChange={handelChange}
                value={numBon}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* buttons */}
        <div className="col-lg-6"></div>
        <div className="col-lg-6">
          {loading ? (
            <></>
          ) : (
            <ButtonsWrapper onSubmit={onSubmit} onCancel={onCancel} />
          )}
        </div>
      </div>
      <BeatLoader
        color={color}
        loading={loading}
        css={override}
        speedMultiplier={0.75}
        size={50}
      />
    </div>
  );
}

export default Payment;

import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import CardShortened from "../components/CardShortened";
import MiniCard from "../components/MiniCard";
import "./homeDashboard.css";
function HomeDashboard() {
  const history = useHistory();
  const Deconnecté = () => {
    localStorage.clear();
    history.push("/");
  };
  const contarct = () => {
    history.push("/dashboard/contract");
  };
  const sinistre = () => {
    history.push("/dashboard/sinistre");
  };
  const remboursement = () => {
    history.push("/dashboard/rembourssement");
  };
  const monCompte = () => {
    history.push("/dashboard/mon-compte");
  };
  return (
    <>
      <div className="home">
        <MiniCard
          onClick={contarct}
          text="Mes Objets assurés"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-1.svg`}
          color="#F9F4E1"
        />
        <MiniCard
          onClick={sinistre}
          text="Mes Sinistres"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`}
          color="#FBEDE8"
        />
        <MiniCard
          onClick={remboursement}
          text="Mes Remboursements"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-3.svg`}
          color="#EAF9F1"
        />
        <MiniCard
          onClick={monCompte}
          text="Mon Compte"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-3.svg`}
          color="#F9EAF9"
        />
        <MiniCard
          onClick={() => {
            history.push("/");
          }}
          text="Garantie Smartphone"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-c-1.svg`}
          color="#F9F4E1"
        />
        <MiniCard
          onClick={() => {
            toast.info("Prochainement disponible");
          }}
          text="Garantie Multimedia"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-2.svg`}
          color="#FBEDE8"
        />
        <MiniCard
          onClick={() => {
            toast.info("Prochainement disponible");
          }}
          text="Garantie Électroménager"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-c-3.svg`}
          color="#EAF9F1"
        />
        <MiniCard
          onClick={() => {
            history.push({
              pathname: "/",
              state: ["Optique", "b460b428-82b2-46a0-8877-400cf5377770"],
            });
          }}
          text="Garantie Optique"
          icon={`${process.env.PUBLIC_URL}/assets/images/dash-c-4.svg`}
          color="#F9EAF9"
        />
      </div>
      <div className="home">
      </div>
      <div className=" home" style={{ marginTop: "50px" }}>
        <CardShortened
          color="#E5F0FC"
          icon={`${process.env.PUBLIC_URL}/assets/images/doc-icon.svg`}
          text={"Mes documents"}
          onClick={()=>{history.push("/dashboard/mes-documents");}}
        />
        {/* <CardShortened
          color="#E5F0FC"
          icon={`${process.env.PUBLIC_URL}/assets/images/Groupe 333.svg`}
          text={"Réclamation"}
          onClick={()=>{history.push("/dashboard/Reclamation");}}
        /> */}
        <CardShortened
          color="#E5F0FC"
          icon={`${process.env.PUBLIC_URL}/assets/images/Groupe 201.svg`}
          text={"Aide"}
          onClick={()=>{history.push("/dashboard/contactez-nous");}}
        />
        <CardShortened
          color="red"
          icon={`${process.env.PUBLIC_URL}/assets/images/logout copy.svg`}
          text={"Déconnexion"}
          textColor="white"
          onClick={Deconnecté}
        />
      </div>
    </>
  );
}

export default HomeDashboard;

import { contarctAxios } from "../../axiosConfig/InstancesAxios";

export default {
  createContract: (Model) => {
    return contarctAxios.post("/createContract", Model);
  },

  getAllContractsByCustomer: (idcustomer) => {
    return contarctAxios.post("/getAllcontractsbyCustomer", null, {
      params: { idcustomer },
    });
  },

  getAllContractsByProducts: (IdProduct) => {
    return contarctAxios.post("/getallcontractsbyproducts", {
      params: { IdProduct },
    });
  },

  getContractbyId: (idContract) => {
    return contarctAxios.post("/getContractbyId", null, {
      params: { idContract },
    });
  },
  getContractInclusion: (matricule, num) => {
    return contarctAxios.post("/getContractInclusion", null, {
      params: {matricule, num },
    });
  },

  getTypePayment: () => {
    return contarctAxios.get("/getListTypePayment", {});
  },

  payment: (data) => {
    return contarctAxios.post("/payment", data);
  },

  sign: (model) => {
    return contarctAxios.post("/ngsign", model);
  },
  checkStepers: (Model) => {
    return contarctAxios.post("/checkStepers", Model);
  },
  generateContrat: (idConstructionSite) => {
    return contarctAxios.post("/generateContrat", null, {
      params: { idConstructionSite },
    });
  },
  validatePayment: (orderID) => {
    return contarctAxios.post("/validatePayment", null, {
      params: { orderID },
    });
  },
  updateStatusOfContract: (model) => {
    return contarctAxios.post("/updateStatusOfContract", model);
  },
  upload: (file) => {
    return contarctAxios.post("/upload", file);
  },
  uploadFiles: (files) => {
    return contarctAxios.post("/uploadM", files);
  },
  generateContratBase64: (idConstructionSite) => {
    return contarctAxios.post("/generateContratB64", null, {
      params: { idConstructionSite },
    });
  },
  voucherCheck: (code, fomula) => {
    return contarctAxios.post("/checkVoucher", null, {
      params: { code, fomula },
    });
  },
};

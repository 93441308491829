export const INIT = "[subscription] init state";
export const SET_STEP_0 = "[subscription] SET_STEP_0";
export const SET_STEP_1 = "[subscription] SET_STEP_1";
export const SET_STEP_2 = "[subscription] SET_STEP_2";
export const SET_STEP_3 = "[subscription] SET_STEP_3";

export const savedataStepZero = (data) => {
  return (dispatch) => {
    dispatch(setStepZeroAction(data));
   
  };
};

export const savedataStepOne = (data) => {
  return (dispatch) => {
    dispatch(setStepOneAction(data));
   
  };
};

export const savedataStepTwo = (data) => {
  return (dispatch) => {
    dispatch(setStepTwoAction(data));
   
  };
};

export const savedataStepThree = (data) => {
  return (dispatch) => {
    dispatch(setStepThreeAction(data));
   
  };
};

export const init = (data) => {
  return {
    type: INIT,
  };
};

export const setStepZeroAction = (payload) => {
  return {
    type: SET_STEP_0,
    payload,
  };
};

export const setStepOneAction = (payload) => {
  return {
    type: SET_STEP_1,
    payload,
  };
};
export const setStepTwoAction = (payload) => {
  return {
    type: SET_STEP_2,
    payload,
  };
};
export const setStepThreeAction = (payload) => {
  return {
    type: SET_STEP_3,
    payload,
  };
};

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import customerService from "../../services/customer/customerService";
import familyService from "../../services/family/familyService";
import Footer from "../Footer/Footer";
import NavBarHome from "../home/NavBarHome";
import NavBarInscrit from "../home/NevBarInscrit";

const ResetPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const [familyList, setFamilyList] = useState();
  const [pass, setPass] = useState(false);
  const [updatePasDto, setUpdatePassDto] = useState({
    comfirmPassword: "",
    password: "",
    email: location.state,
  });
  const [verifDto, setVerifDto] = useState({
    code: "",
    email: location.state,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    familyService.getAllFamily().then((res) => {
      setFamilyList(res.data.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 4 ));
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVerifDto((verifDto) => ({ ...verifDto, [name]: value }));
    setUpdatePassDto((updatePasDto) => ({ ...updatePasDto, [name]: value }));
  };

  const onsubmit = async () => {
    if (!pass) {
      if (verifDto.code) {
        await customerService.checkCode(verifDto).then((res) => {
          //console.log(res);
          if (res.data == 2) {
            setPass(true);
          } else if (res.data == 1) {
            toast.error("Code vérification expiré");
          } else if (res.data == 0) {
            toast.error("Vérifier votre e-mail code incorrect !!!!");
          }
        });
      }
    } else {
      if (updatePasDto.password && updatePasDto.comfirmPassword) {
        await customerService.resetPassword(updatePasDto).then((res) => {
          if (res.data == 1) {
            toast.error("Veuillez saisir deux mots de passe identiques.");
          } else if (res.data == 2) {
            history.push("/connexion");
            toast.success("Votre mot de passe a été changé avec succès.");
          }
        });
      } else {
        toast.error("Veuillez saisir deux mots de passe identiques.");
      }
    }
  };
  return (
    <div>
      <NavBarHome dark relative />
      <div
        className="body-inscrit"
        style={{ marginBottom: "12%", marginTop: "8%" }}
      >
        <h1
          style={{
            fontFamily: "Century Gothic",
            fontSize: "30px",
            textAlign: "center",
            fontWeight: "bold",
            color: "#0C015F",
            marginTop: "7%",
          }}
        >
          {pass ? "Réinitialisation du mot de passe" : "Code de vérification"}
        </h1>
        <div
          className="row col-md-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div
            className="col-sm-3 aide"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                textAlign: "left",
                font: "normal normal bold 14px/26px Century Gothic",
                letterSpacing: "0px",
                color: "#000000",
                opacity: "1",
                marginLeft: "4%",
                marginBottom: "13px",
              }}
            >
              {pass ? "Nouveau mot de passe :" : "Entrer votre code :"}
            </label>
            <input
              name={pass ? "password" : "code"}
              type={pass ? "password" : "text"}
              className="login"
              placeholder={
                pass ? "Entrer votre nouveau mot de passe" : "Entrer votre code"
              }
              value={pass ? updatePasDto.password : verifDto.code}
              onChange={handleChange}
            />
          </div>
        </div>
        {pass ? (
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div
              className="col-sm-3 aide"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  textAlign: "left",
                  font: "normal normal bold 14px/26px Century Gothic",
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: "1",
                  marginLeft: "4%",
                  marginBottom: "13px",
                }}
              >
                Confirmation mot de passe :
              </label>
              <input
                name="comfirmPassword"
                type="password"
                className="login"
                placeholder="Confirmer votre mot de passe"
                value={updatePasDto.comfirmPassword}
                onChange={handleChange}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          className="row col-md-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
          }}
        >
          <div
            className="col-md-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="connect-btn">
              <button className="connect-btn" onClick={onsubmit}>
                {pass ? "Modifier" : "Vérifier le Code"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer listFamily={familyList} />
    </div>
  );
};

export default ResetPassword;

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import customerService from "../../services/customer/customerService";
import familyService from "../../services/family/familyService";
import formuleService from "../../services/formule/formuleService";
import Footer from "../Footer/Footer";
import NavBarHome from "../home/NavBarHome";
const generator = require("generate-password");

const CreateCompte1 = () => {
  const history = useHistory();
  const location = useLocation();
  const [customerDto, setCustomerDto] = useState({
    adress: location?.state?.adress,
    cin: location?.state?.cin,
    city: location?.state?.city,
    codeVerification: location?.state?.codeVerification,
    dateCreation: location?.state?.dateCreation,
    email: location?.state?.email,
    firstName: location?.state?.firstName,
    isblacklisted: location?.state?.isblacklisted,
    iscgu: location?.state?.iscgu,
    isnewsletter: location?.state?.isnewsletter,
    lastName: location?.state?.lastName,
    password: location?.state?.password,
    phone: location?.state?.phone,
    postalCode: location?.state?.postalCode,
    socialReason: location?.state?.socialReason,
    status: "V",
    street: location?.state?.street,
    taxRegistrationNumber: location?.state?.taxRegistrationNumber,
    typeCustomer: "P",
  });
  const [familyList, setFamilyList] = useState();
  const [confirmPass, setConfirmPass] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    familyService.getAllFamily().then((res) => {
      setFamilyList(res.data.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 4 ));
    });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDto((customerDto) => ({ ...customerDto, [name]: value }));
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setConfirmPass((confirmPass) => ({ ...confirmPass, [name]: value }));
  };
  const valider = async (e) => {
    e.preventDefault();
    if (!customerDto.password && !confirmPass?.confPass) {
      toast.error("Veuillez saisir votre mot de passe");
    } else if (customerDto.password === confirmPass?.confPass) {
      await customerService.createCustomer(customerDto).then((response) => {
        if (response) {
          customerService.sendCode(response.data.idcustomer);
        }
        let id =response.data.idcustomer
        history.push({
          pathname: "/home/CreateUser/step3",
          state: customerDto, id 
        });
      });
    } else {
      toast.error("Veuillez saisir deux mots de passe identiques");
    }
  };
  return (
    <div className="container-fluid">
      <NavBarHome dark relative />

      <div className="row col-md-12 margin0 d-flex-j">
        <h2
          style={{
            textAlign: "center",
            font: "normal normal bold 26px/24px Century Gothic",
            color: "#0C015F",
            marginTop: "70px",
          }}
        >
          Créer votre compte Garanty en 3 étapes !
        </h2>
        <div className="row col-md-10 steppr-user">
          <div
            className="row col-md-9 d-flex-j"
            style={{ marginTop: "70px", position: "relative" }}
          >
            <hr />
            <div className="col-md-4 d-flex-a flex-d-c">
              <div className="div-icon-steps d-flex-a">
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/personal-information.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#CCCCCC",
                  marginTop: "20px",
                }}
              >
                Vos données personnelles
              </h3>
            </div>
            <div className="col-md-4 d-flex-a flex-d-c">
              <div
                className="div-icon-steps d-flex-a"
                style={{ backgroundColor: "#AA74E8" }}
              >
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/secure.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#000000",
                  marginTop: "20px",
                }}
              >
                Votre mot de passe
              </h3>
            </div>
            <div className="col-md-4 d-flex-a flex-d-c">
              <div className="div-icon-steps d-flex-a">
                <img
                  className="icon-steps"
                  src={`${process.env.PUBLIC_URL}/assets/images/checked.png`}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  font: "normal normal bold 16px/24px Century Gothic",
                  color: "#CCCCCC",
                  marginTop: "20px",
                }}
              >
                Validation
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 d-flex-j">
        <div className="col-md-9">
          <div className="col-md-12 d-flex-j flex-d-c marginTop20">
            <label className="label">
              Mot de passe<span style={{ color: "red" }}>*</span>
            </label>
            <input
              name="password"
              className="input2"
              type="password"
              placeholder="Entrer votre Mot de passe"
              value={customerDto.password}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12 d-flex-j flex-d-c marginTop20">
            <label className="label">
              Confirmez votre mot de passe
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="input2"
              type="password"
              name="confPass"
              placeholder="Entrer votre Mot de passe"
              onChange={handleChange1}
            />
          </div>
          <div className="col-md-12 d-flex-j flex-d-c marginTop20">
            <p
              style={{
                textAlign: "left",
                font: "normal normal normal 14px/26px Century Gothic",
                color: "#000000",
                paddingLeft: "15px",
              }}
            >
              Votre mot de passe doit contenir au minimum 8 caractères dont au
              moins une majuscule, un chiffre et des symboles
            </p>
          </div>
          <div className="row col-md-12 marginTop20 margin0">
            <div className="col-md-6 d-flex-j flex-d-c">
              <div
                className="calcule-btn"
                style={{
                  backgroundColor: "#D4D4D4",
                  border: "1px solid #D4D4D4",
                }}
              >
                <button
                  className="calcule-btn"
                  style={{
                    backgroundColor: "#D4D4D4",
                    border: "1px solid #D4D4D4",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/home/CreateUser/step1",
                    });
                  }}
                >
                  Retour
                </button>
              </div>
            </div>
            <div className="col-md-6 d-flex-j flex-d-c">
              <div className="calcule-btn" id="step2validation">
                <button className="calcule-btn" id="step2validation" onClick={valider}>
                Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer listFamily={familyList} />
    </div>
  );
};

export default CreateCompte1;

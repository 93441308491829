import React,{useEffect,useState} from 'react'
import {data} from './Fakedata'

export default function Details() {
    const [index,setIndex]=useState(null)

    useEffect(()=>{
        setIndex(localStorage.getItem("index"))
    },[])
  return (
    <div>
    {index!=null &&  
        <div>
        <div>Numéro de contrat</div>
        <div>{data[index].num}</div>
        <div>Numéro du sinistre</div>
        <div>{data[index].numContrat}</div>
        <div>Date de prise en charge du sinistre</div>
        <div>{data[index].date}</div>
        </div>
    }
    </div> 
   
  )
}

import React from "react";
import "./ButtonsWarapper.css";
function ButtonsWrapper({ onSubmit, onCancel }) {
  return (
    <div className="btn-wrapper">
      <button onClick={onCancel} type="button"  className="btn btn-back-w ">
        Retour
      </button>
      <button onClick={onSubmit} type="button" id="confirmation" className="btn btn-valid-w">
        Confirmer
      </button>
    </div>
  );
}

export default ButtonsWrapper;

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonsWrapper from "../../SubComponents/ButtonsWrapper ";
import InputDate from "../../SubComponents/inputCustom/InputDate";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { registerLocale } from "react-datepicker";
import { GoInfo } from "react-icons/go";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import fr from "date-fns/locale/fr";
import Footer from "../../Footer/Footer";
import NavBarHome from "../../home/NavBarHome";
registerLocale("fr", fr);
const Etape1 = () => {
  const location = useLocation();
  const prod = location?.state?.prod;
  const history = useHistory();
  const [startDate, setStartDate] = useState(false);
  const [remborDate, setRemborDate] = useState(false);
  const [rembVar, setRembVar] = useState(null)
  const [sinistre, setSinistre] = useState({
    affecran: "",
    comment: "",
    connectfonc: "",
    constatedate: "",
    damagecause: "",
    damagetype: "",
    date: new Date().toISOString(),
    dateCreation: new Date().toISOString(),
    ecran: "",
    ecrantac: "",
    facadearriere: "",
    facturephoto: "",
    idCP: location?.state?.idCP,
    listIdBreakDownType: Object?.values(location?.state?.listBre),
    location: "",
    plliquidecontact: "",
    prechhand: "",
    prodallu: "",
    prodcharge: "",
    prodfonc: "",
    prodphoto: "",
    prodrep: "",
    prodsound: "",
    inclusion: 1,
    repdev: "",
    simplace: "",
    status: 0,
    phone: location.state.phone,
  });

  const handelChangeDate = (e) => {
    const { name, value } = e.target;
    setSinistre((sinistre) => ({ ...sinistre, [name]: value.toISOString() }));
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setSinistre((sinistre) => ({ ...sinistre, [name]: value }));
  };
  const handelRem = (e)=>{
    const { name, value } = e.target;
    setRembVar(value)
  }
  const onSubmit = () => {
    if (
      sinistre.constatedate &&
      sinistre.damagecause &&
      sinistre.damagetype &&
      sinistre.date &&
      sinistre.listIdBreakDownType &&
      sinistre.location &&
      sinistre.plliquidecontact &&
      sinistre.prechhand &&
      sinistre.prodrep &&
      sinistre.repdev
    ) {
      history.push({
        pathname: "/inclusion/etape2",
        state: [sinistre, prod],
      });
    } else {
      toast.error("Tous les champs sont obligatoires.");
    }
  };
  const onCancel = () => {
    history.goBack();
  };
  return (
    <>
      <NavBarHome dark relative />
      <div className="body-sinistre">
        <h4
          style={{
            font: "normal normal bold 25px/27px Century Gothic",
            color: "#0C015F",
          }}
        >
          Déclarer un sinistre <br />
          <br />
          Complétez le formulaire ci-dessous :
        </h4>
        <br />
        <div className="row col-md-10 col-lg-10 col-sm-10 col-10">
          <InputDate
            placeholderText="Date"
            label={
              prod == "Smartphone"
                ? "À quelle date avez-vous constaté le dommage de votre produit ?"
                : "À quelle date avez-vous constaté le dommage de vos lunettes?"
            }
            selected={startDate}
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeSelect
            minDate={addDays(new Date(), -4)}
            onChange={(date) => {
              setStartDate(date);
              handelChangeDate({
                target: { name: "constatedate", value: date },
              });
            }}
          />
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
            Avez-vous bénéficiez d’un remboursement auprès de votre assureur :
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir lieux..."
              onChange={handelRem}
            >
              <option value="">Choisir...</option>
              <option value={1}>Oui</option>
              <option value={0}>Non</option>
            </select>
          </div>
          {rembVar == 1 ?
            <InputDate
            placeholderText="Date"
            label={
              "Précisez la date du remboursement"
            }
            selected={remborDate}
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy"
            // minDate={addDays(new Date(),0)}
            onChange={(date) => {
              setRemborDate(date);
              handelChangeDate({
                target: { name: "constatedate", value: date },
              });
            }}
          /> : <></>
          }
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
              Le dommage fait suite à :<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="damagecause"
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir lieux..."
              onChange={handelChange}
            >
              <option value="">Choisir...</option>
              <option value="Un accident">Un accident</option>
              <option value="Un défaut de fabrication">
                Un défaut de fabrication
              </option>
              <option value="Un choc">Un choc</option>
              <option value="Une chute ">Une chute </option>
              <option value="Un autre évènement">Un autre évènement</option>
            </select>
          </div>
          <div className="col-md-6 form-group">
            <label className="label" for="exampleFormControlTextarea1">
              Précisez les circonstances :{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              style={{ minHeight: "7rem" }}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              name="comment"
              onClick={handelChange}
            ></textarea>
          </div>
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
              Lieu du sinistre ? <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="location"
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir lieux..."
              onChange={handelChange}
            >
              <option value="">Choisir...</option>
              <option value="Maison">Maison</option>
              <option value="Bureau">Bureau</option>
              <option value="Voiture">Voiture</option>
              <option value="Dans la rue">Dans la rue</option>
              <option value="Autres">Autres</option>
            </select>
          </div>
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
              {prod == "Smartphone"
                ? "Le produit vous a-t-il échappé des mains ?"
                : "Les lunettes ont-ils échappé de vos mains ?"}
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="prechhand"
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir..."
              onChange={handelChange}
            >
              <option value="">Choisir...</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </div>
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
              {prod == "Smartphone"
                ? "Le produit a-t-il été en contact avec un liquide ?"
                : "Un tiers a cassé vos lunettes ?"}
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="plliquidecontact"
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir..."
              onChange={handelChange}
            >
              <option value="">Choisir...</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </div>
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
              Avez-vous effectué un devis pour la réparation ?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="repdev"
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir..."
              onChange={handelChange}
            >
              <option value="">Choisir...</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </div>
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
              {prod == "Smartphone"
                ? "Le produit est-il réparable ?"
                : "Les lunettes sont-ils réparables ?"}
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="prodrep"
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir..."
              onChange={handelChange}
            >
              <option value="">Choisir...</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </div>
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <label className="label">
              {" "}
              Les dommages sont-ils de nature esthétique ?
              <span style={{ color: "red" }}>*</span>
              <span>
                <GoInfo
                  title="rayures, égratignures, écaillements, tâches indélébiles,
                    décoloration progressive par l’action du soleil, etc ?"
                  size="18px"
                  color="#3389cc"
                />
              </span>
            </label>
            <select
              name="damagetype"
              className="input-form"
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              placeholder="Choisir..."
              onChange={handelChange}
            >
              <option value="">Choisir...</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </div>
        </div>

        <div style={{ marginTop: "25px", float: "right", marginRight: "45px" }}>
          <ButtonsWrapper onSubmit={onSubmit} onCancel={onCancel} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Etape1;

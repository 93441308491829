import React, { useEffect, useState } from "react";
import moment from "moment";
import contractService from "../../../services/contract/contractService";
import { BeatLoader } from "react-spinners";
const ContractList = () => {
  const [list, setList] = useState([]);
  useEffect(() => {
    const idContract = window.location.pathname.split("/")[3];
    const id = JSON.parse(localStorage.getItem("user")).id;
    // contractService.updateStatusOfContract({
    //   idContract: idContract,
    //   status: "V",
    // });
    contractService.getAllContractsByCustomer(id).then((response) => {
      setList(response.data);
    });
  }, []);
  console.log(list);
  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Mes objets assurés
      </h4>
      <div className="tab-scroll">
        <div className="row col-md-12 tab-sinistres margin0">
          <div className="row col-md-12 tab-container">
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">N° contrat/ Formule/ Marque</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Date d'effet</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Date d'échéance</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Modèle</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">Produit</h6>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-2 col-2">
              <h6 className="title-tab">État</h6>
            </div>
          </div>
          {list.length != 0 ? (
            list?.map((el, key) => {
              if (el.status === "AP" && el.canalVente == "web")
                return (
                  <div className="row col-md-12 row-tab">
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{el.formattedName}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {moment(el.startDate).format("DD/MM/yyyy")}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{moment(el.endDate).format("DD/MM/yyyy")}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family == "Optique" ? el.marque : el.model}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family}
                      </h6>
                    </div>
                    <div
                      className="col-md-2 col-lg-2 col-sm-2 col-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h6
                        className="value-tab"
                        style={{ marginRight: "5px", width: "fit-content" }}
                      >
                        En attente de paiement
                      </h6>
                      <div
                        className="boule-couleur"
                        style={{ backgroundColor: "#029AD9" }}
                      ></div>
                    </div>
                  </div>
                );
              if (el.status === "E")
                return (
                  <div className="row col-md-12 row-tab">
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{el.formattedName}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {moment(el.startDate).format("DD/MM/yyyy")}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {moment(el.endDate).format("DD/MM/yyyy")}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family == "Optique" ? el.marque : el.model}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family}
                      </h6>
                    </div>
                    <div
                      className="col-md-2 col-lg-2 col-sm-2 col-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h6
                        className="value-tab"
                        style={{ marginRight: "5px", width: "fit-content" }}
                      >
                        Expiré
                      </h6>
                      <div
                        className="boule-couleur"
                        style={{ backgroundColor: "#EEE501" }}
                      ></div>
                    </div>
                  </div>
                );
              if (el.status === "V" || el.canalVente === "Distributeur")
                return (
                  <div className="row col-md-12 row-tab">
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{el.formattedName}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {moment(el.startDate).format("DD/MM/yyyy")}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {moment(el.endDate).format("DD/MM/yyyy")}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family == "Optique" ? el.marque : el.model}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family}
                      </h6>
                    </div>
                    <div
                      className="col-md-2 col-lg-2 col-sm-2 col-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h6
                        className="value-tab"
                        style={{ marginRight: "5px", width: "fit-content" }}
                      >
                        Validé
                      </h6>
                      <div
                        className="boule-couleur"
                        style={{ backgroundColor: "#26876B" }}
                      ></div>
                    </div>
                  </div>
                );
              if (el.status == "AS")
                return (
                  <div className="row col-md-12 row-tab">
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">{el.formattedName}</h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {moment(el.startDate).format("DD/MM/yyyy")}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {moment(el.endDate).format("DD/MM/yyyy")}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family == "Optique" ? el.marque : el.model}
                      </h6>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-2">
                      <h6 className="value-tab">
                        {el.family}
                      </h6>
                    </div>
                    <div
                      className="col-md-2 col-lg-2 col-sm-2 col-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h6
                        className="value-tab"
                        style={{ marginRight: "5px", width: "fit-content" }}
                      >
                        En attente de signature
                      </h6>
                      <div
                        className="boule-couleur"
                        style={{ backgroundColor: "#029AD9" }}
                      ></div>
                    </div>
                  </div>
                );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <img width="100" src="/assets/images/dash-1.svg" />
              <h3>Vous n'avez pas encore de contrat d'assurance actif</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContractList;

import React from "react";

const MenLegale = () => {
  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Mentions légales
      </h4>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="col-md-8 d-flex flex-d-c">
          <h3
            style={{
              textAlign: "center",
              fontSize: "22px",
              fontFamily: "Century Gothic",
              marginTop: "30px",
            }}
          >
            Conformément à la loi organique n°2004-63 du 27 juillet 2004,
            portant sur la protection des données à caractère personnel, je
            déclare être informé du traitement de mes données personnelles
            renseignées au niveau du présent formulaire dans le cadre de la
            souscription d’un contrat d’assurance établi par BH Assurance. Je
            consens expressément au traitement de mes données personnelles et en
            cas de réalisation des finalités pour lesquelles elles ont été
            collectées ou lorsqu’elles deviennent inutiles pour l’activité de BH
            Assurance, ces dernières se verront détruites. De même, je déclare
            être informé de mon droit d’accès, de rectification de mes données
            personnelles et de mon droit d’opposition au traitement de ces
            dernières ainsi que mon droit de porter plainte auprès de l’INPDP
            pour toute violation constatée
          </h3>
        </div>
      </div>
    </div>
  );
};

export default MenLegale;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import customerService from "../../services/customer/customerService";
import { getFamiliesList } from "../../store/actions";
import { saveUser } from "../CreateUser/store/userAction";

import "./Home.css";
const NavBarHome = ({ dark, relative }) => {
  const listFamily = useSelector(({ global }) => global.familiesList);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [connect, setConnect] = useState("Se connecter");
  const [user, setUser] = useState();
  React.useEffect(() => {
    if (!listFamily) dispatch(getFamiliesList());
  }, []);
  const deconnecter = function () {
    localStorage.clear();
    history.push("/connexion");
  };
  const handleToggle = () => {
    setActive(!isActive);
  };
  const handleToggle2 = () => {
    setActive2(!isActive2);
  };
  const handleToggle3 = () => {
    setActive1(!isActive1);
  };

  const goToApropos = () => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/apropos`,
      state: listFamily,
    });
  };
  const goToHHelp = () => {
    history.push(`${process.env.PUBLIC_URL}/faq`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let user = JSON.parse(localStorage.getItem("user"));
    customerService.getCustomerById(user?.id).then((res) => {
      if (res.status === 200) {
        setConnect(res.data.firstName);
        setUser(res.data);
        dispatch(saveUser(res.data));
      }
    });
  }, []);
  return (
    <div
      className="row col-12 nav-menu"
      style={{
        position: relative && "relative",
        boxShadow: relative && "0pt 10pt 22pt #0000000d",
        backgroundColor: "white",
        height: "95px",
      }}
    >
      <div className="headweb">
        <div
          className="col-md-10"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-6">
            <div
              className="logo-garanty"
              style={{
                display: "flex",
                justifyContent: "center",
                borderRight: `2px solid ${dark ? "black" : "#aeaeae"} `,
                alignItems: "center",
                marginLeft: "6px",
              }}
              onClick={() => {
                history.push("/");
              }}
            >
              <img
                className="garantie1"
                style={{ cursor: "pointer" }}
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  dark ? "Logo Garanty Bleu.png" : "Logo Garanty Bleu.png"
                }`}
              />
            </div>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://bh-assurance.com/"
              target="_blank"
              className="logo-bh"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                cursor: "pointer",
              }}
            >
              <img
                className="garantie"
                // style={{ width: "75%" }}
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  dark ? "bhassurance.png" : "bhassurance.png"
                }`}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="contain2"></div>
      <div className="col-8 contain">
        <div className="col-md-12 bar">
          <div className="col-md-1"></div>
          <div className="col-md-8 bar">
            <div className="produit">
              <li class="menu-item" aria-haspopup="true">
                <span
                  className="text-menu"
                  style={{ color: dark ? "#0C015F" : "rgb(252, 6, 231)" }}
                >
                  Nos Garanties
                </span>
                <i
                  style={{
                    marginLeft: "3px",
                    fontSize: "15px",
                    color: dark ? "black" : "black",
                  }}
                  className="fa fa-angle-down arrow-menu"
                ></i>

                <ol
                  className="sub-menu"
                  style={{ backgroundColor: dark ? "#0C015F" : "white" }}
                  aria-label="submenu"
                >
                  {listFamily?.map((el, key) => (
                    <li className="menu-item">
                      <span
                        className="text-menu-name"
                        style={{ color: dark ? "white" : "#4e4d50" }}
                        onClick={() => {
                          if (el.familyName == "Smartphone") {
                            history.push({
                              pathname: "/garanties/smartphone",
                              state: el.idfamily,
                            });
                          } else if (el.familyName == "Optique") {
                            history.push({
                              pathname: "/garanties/optique",
                              state: el.idfamily,
                            });
                          } else if (el.familyName == "Multimédia") {
                            history.push({
                              pathname: "/garanties/multimedia",
                              state: el.idfamily,
                            });
                          } else if(el.familyName == "Meubles"){
                            history.push({
                              pathname: "/garanties/meubles",
                              state: el.idfamily,
                            });
                          }else {
                            history.push({
                              pathname: "/garanties/electromenager",
                              state: el.idfamily,
                            });
                          }
                        }}
                      >
                        Garantie {el.familyName}
                      </span>
                      <Line />
                    </li>
                  ))}
                </ol>
              </li>
            </div>
            <div className="apropos">
              <li class="menu-item" aria-haspopup="true">
                <span
                  style={{ color: dark ? "#0C015F" : "rgb(252, 6, 231)" }}
                  className="text-menu"
                  onClick={goToApropos}
                >
                  À propos de nous
                </span>
              </li>
            </div>
            <div className="aide" style={{ justifyContent: "center" }}>
              <li class="menu-item" aria-haspopup="true">
                <span
                  style={{ color: dark ? "#0C015F" : "rgb(252, 6, 231)" }}
                  className="text-menu"
                  onClick={() => {
                    history.push("/devenir-partenaire");
                  }}
                >
                  Nos partenaires
                </span>
              </li>
            </div>
            <div className="aide" style={{ justifyContent: "center" }}>
              <li class="menu-item" aria-haspopup="true">
                <span
                  style={{ color: dark ? "#0C015F" : "rgb(252, 6, 231)" }}
                  className="text-menu"
                >
                  Contact
                </span>
                <i
                  style={{
                    marginLeft: "3px",
                    fontSize: "15px",
                    color: dark ? "black" : "black",
                  }}
                  className="fa fa-angle-down arrow-menu"
                ></i>
                <ol
                  className="sub-menu"
                  style={{ backgroundColor: dark ? "#0C015F" : "white" }}
                  aria-label="submenu"
                >
                  <li className="menu-item">
                    <span
                      style={{ color: dark ? "white" : "#4e4d50" }}
                      className="text-menu-name"
                      onClick={() =>
                        user
                          ? history.push(`${process.env.PUBLIC_URL}/dashboard`)
                          : history.push(`${process.env.PUBLIC_URL}/connexion`)
                      }
                    >
                      Espace Client
                    </span>
                    <Line />
                  </li>
                  <li className="menu-item">
                    <span
                      style={{ color: dark ? "white" : "#4e4d50" }}
                      className="text-menu-name"
                      onClick={() =>
                        history.push({
                          pathname: "/contact",
                          state: listFamily,
                        })
                      }
                    >
                      Contact
                    </span>
                  </li>
                </ol>
              </li>
            </div>
            <div
              className="aide none"
              style={{ justifyContent: "center" }}
            ></div>
          </div>
          <div
            className="col-md-2"
            style={{ display: "grid", position: "relative" }}
          >
            <li class="menu-item" aria-haspopup="true">
              <div
                className="seconnecterr1"
                id="seconnecter"
                onClick={() => {
                  if (user) {
                    history.push("/dashboard");
                  } else {
                    history.push("/connexion");
                  }
                }}
              >
                <h6 className="text-conx ff">{connect}</h6>
              </div>
              {user ? (
                <ol
                  className="sub-menu"
                  style={{
                    backgroundColor: dark ? "#0C015F" : "white",
                    width: "90%",
                  }}
                  aria-label="submenu"
                >
                  <li
                    className="menu-item"
                    style={{ width: "80%", textAlign: "center" }}
                  >
                    <span
                      className="text-menu-name"
                      style={{
                        color: dark ? "white" : "#4e4d50",
                        textAlign: "center",
                      }}
                      onClick={deconnecter}
                    >
                      Déconnecter
                    </span>
                  </li>
                </ol>
              ) : (
                <></>
              )}
            </li>
          </div>
        </div>
      </div>
      <div className="col-1 menu-mobile">
        <div
          className={isActive ? "triplebar" : "change"}
          onClick={handleToggle}
        >
          <div
            style={{ backgroundColor: dark ? "#232e85" : "#232e85" }}
            className="bar1"
          ></div>
          <div
            style={{ backgroundColor: dark ? "#232e85" : "#232e85" }}
            className="bar2"
          ></div>
          <div
            style={{ backgroundColor: dark ? "#232e85" : "#232e85" }}
            className="bar3"
          ></div>
        </div>
        <div className={isActive ? "dropdown" : "dropdownNone"}>
          <div className="dropdown-content">
            <a className="list-menu" href="#" onClick={handleToggle3}>
              Nos Garanties
              <i
                style={{
                  marginLeft: "3px",
                  fontSize: "15px",
                  color: dark ? "black" : "black",
                }}
                className="fa fa-angle-down arrow-menu"
              ></i>
            </a>

            <ul className={isActive1 ? "dropdown1" : "dropdownNone1"}>
              {listFamily?.map((el, key) => (
                <li className="menu-item">
                  <span
                    className="text-menu-name"
                    style={{ color: dark ? "black" : "#4e4d50" }}
                    onClick={() => {
                      if (el.familyName == "Smartphone") {
                        history.push({
                          pathname: "/garanties/smartphone",
                          state: el.idfamily,
                        });
                      } else if (el.familyName == "Optique") {
                        history.push({
                          pathname: "/garanties/optique",
                          state: el.idfamily,
                        });
                      } else if (el.familyName == "Multimédia") {
                        history.push({
                          pathname: "/garanties/multimedia",
                          state: el.idfamily,
                        });
                      } else if(el.familyName == "Meubles"){
                        history.push({
                          pathname: "/garanties/meubles",
                          state: el.idfamily,
                        });
                      } else {
                        history.push({
                          pathname: "/garanties/electromenager",
                          state: el.idfamily,
                        });
                      }
                    }}
                  >
                    Garantie {el.familyName}
                  </span>
                </li>
              ))}
            </ul>
            <a className="list-menu" href="#" onClick={goToApropos}>
              À propos de nous{" "}
            </a>
            <a
              className="list-menu"
              onClick={() => {
                history.push("/devenir-partenaire");
              }}
              href="#"
            >
              Nos partenaires{" "}
            </a>
            <a className="list-menu" onClick={handleToggle2} href="#">
              Contact
              <i
                style={{
                  marginLeft: "3px",
                  fontSize: "15px",
                  color: dark ? "black" : "black",
                }}
                className="fa fa-angle-down arrow-menu"
              ></i>
            </a>
            <ul className={isActive2 ? "dropdown1" : "dropdownNone1"}>
              {/* <li className="menu-item" onClick={goToHHelp}>
                FAQ
              </li> */}
              <li
                className="menu-item"
                onClick={() =>
                  user
                    ? history.push(`${process.env.PUBLIC_URL}/dashboard`)
                    : history.push(`${process.env.PUBLIC_URL}/connexion`)
                }
              >
                Espace Client
              </li>
              <li
                className="menu-item"
                onClick={() =>
                  history.push({
                    pathname: "/contact",
                    state: listFamily,
                  })
                }
              >
                Contact
              </li>
            </ul>
            <a
              className="connect"
              href="#"
              onClick={() => {
                if (user) {
                  history.push("/dashboard");
                } else {
                  history.push("/connexion");
                }
              }}
            >
              {connect}
            </a>
            {user ? (
              <a className="list-menu" href="#" onClick={deconnecter}>
                Déconnecter
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarHome;

const Line = () => (
  <div
    style={{
      marginTop: "8px",
      width: "25%",
      height: "1px",
      backgroundColor: "#F1F1F1",
    }}
  ></div>
);

import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ButtonsWrapper from "../SubComponents/ButtonsWrapper ";
import NavBar from "./Components/NavBar";
import Stepper from "./Components/Stepper";
import NumberFormat from "react-number-format";
import "./Souscription.css";
function Confirmation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const user = useSelector(({ userReducer }) => userReducer.user);
  const formule = useSelector(({ userReducer }) => userReducer?.formule);
  const personelInfo = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step3
  );
  const myPhone = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step2
  );
  const onSubmit = () => {
    history.push("/payment");
  };
  const onCancel = () => {
    history.push("/home/NoticeInfo");
  };
  var newDate = new Date();
  var thisMonth = newDate.getUTCMonth();
  var plusMois = 12;
  var endDate = new Date();
  endDate.setUTCMonth(thisMonth + plusMois);

  React.useEffect(() => {
    if (!formule) {
      history.push("/");
    }
  }, []);
  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12 margin0">
      <NavBar />
      <Stepper activeStep={2} />
      <div className="row col-12 form-souscription d-flex-j-c">
        <div className="col-lg-10 col-md-12 mt-5">
          <span
            style={{
              font: "normal normal bold 25px/27px Century Gothic",
              color: "#0C015F",
            }}
          >
            Votre Commande
          </span>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              {/* card-1 */}
              <div className="card mt-2 pt-3 pb-3 card-box">
                <div className="card-body row">
                  <div className="col-2">
                    <img
                      style={{ width: "75%" }}
                      src={`${process.env.PUBLIC_URL}/assets/images/Tout marque@2x.png`}
                    />
                  </div>
                  <div className="col-10">
                    <p
                      className="card-title"
                      style={{
                        font: "normal normal bold 26px/24px Century Gothic",
                      }}
                    >
                      {formule?.fomula?.family?.familyName === "Optique"
                        ? myPhone?.Marque?.value
                        : myPhone?.Marque?.value + " " + myPhone?.Modele?.value}
                    </p>
                    <p
                      style={{
                        font: "normal normal bold 16px/24px Century Gothic",
                        letterSpacing: "0px",
                        color: "#AFAEB8",
                      }}
                    >
                      {formule?.fomula?.family?.familyName === "Smartphone"
                        ? "IMEI:"
                        : "N° Série :"}
                      {myPhone?.["Numero serie"]?.value}
                    </p>
                  </div>
                </div>
              </div>

              {/* card-2 */}
              <div className="card mt-5 pt-3 pb-3 card-box">
                <div className="card-body row">
                  <p
                    className="card-title title-c"
                    style={{
                      letterSpacing: "0px",
                      font: "normal normal bold 26px/24px Century Gothic",
                      marginBottom: "50px",
                    }}
                  >
                    Informations personnelles
                  </p>
                  <div className="col-1 mt-3 d-flex justify-content-start">
                    <div
                      className="vr pt-2"
                      style={{
                        height: "100%",
                        width: "1.5px",
                        backgroundColor: "#D9F9F6",
                      }}
                    ></div>
                  </div>
                  <div className="col-11 mt-3">
                    <div className="dot "></div>
                    <div>
                      <p className="dot-label">Prénom</p>
                      <p className="dot-value  ">{user?.lastName}</p>
                    </div>

                    <div>
                      <div className="dot"></div>
                      <p className="dot-label">Nom :</p>
                      <p className="dot-value "> {user?.firstName}</p>
                    </div>
                    <div>
                      <div className="dot "></div>
                      <p className="dot-label">Adresse Email : </p>
                      <p className="dot-value "> {user?.email}</p>
                    </div>
                    <div>
                      <div className="dot "></div>
                      <p className="dot-label">Téléphone :</p>
                      <p className="dot-value ">+216 {user?.phone}</p>
                    </div>
                    <div>
                      <div className="dot "></div>
                      <p className="dot-label">Adresse : </p>
                      <p className="dot-value "> {personelInfo?.Adresse}</p>
                    </div>
                    <div>
                      <div className="dot "></div>
                      <p className="dot-label">Ville : </p>
                      <p className="dot-value "> {personelInfo?.Ville} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              {/* card-3 */}
              <div className="card mt-2 pt-3 pb-3 card-box p-3">
                <div className="card-body row">
                  <p
                    className="card-title title-c"
                    style={{
                      letterSpacing: "0px",
                      font: "normal normal bold 26px/24px Century Gothic",
                      marginBottom: "50px",
                    }}
                  >
                    Votre Devis
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "rox",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <span className="dot-label"> Date d'adhésion :</span>
                    <span className="dot-value">
                      {new Date().toLocaleDateString()}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "rox",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="dot-label"> Date de fin :</span>
                    <span className="dot-value">
                      {endDate.toLocaleDateString()}
                    </span>
                  </div>
                  <div className="_divider"></div>
                  <div className="row col-md-12 col-lg-12 col-sm-12 col-12">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <span className="dot-label"> Formule de garantie :</span>
                      <div className="value-text mb-3 ">
                        {" "}
                        Dommage accidentel
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-6 col-sm-6"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <img
                        className="img-formule-extend"
                        src={
                          `${process.env.PUBLIC_URL}/assets/images/` +
                          formule?.fomula.name +
                          ".svg"
                        }
                        style={{ width: "70%" }}
                      />
                    </div>
                  </div>
                  {formule?.listBreakDown?.map((br, ke) =>
                    br.value ? (
                      <div
                        key={ke}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: "16px",
                        }}
                      >
                        <CheckIcon />{" "}
                        <div
                          style={{ alignSelf: "center", marginLeft: "10px" }}
                        >
                          {br.name}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                  {formule?.fomula?.vol_protection ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "16px",
                      }}
                    >
                      <CheckIcon />{" "}
                      <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                        Option VOL
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="_divider"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "rox",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <span className="dot-label"> Plafond de garantie :</span>
                    <span className="dot-value">
                      {formule?.fomula.ceilingGuarantee}.000 Dt
                    </span>
                    {formule?.fomula?.vol_protection ? (
                      <>
                        <span className="dot-label">
                          {" "}
                          Plafond de garantie VOL :
                        </span>
                        <span className="dot-value">
                          {formule?.fomula.ceilingGuarantee}.000 Dt
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "rox",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <span className="dot-label"> Prime d'assurance :</span>
                    <span className="dot-value">
                      {
                        <NumberFormat
                          value={(formule?.fomula?.price)?.toFixed(3)}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }{" "}
                      DT TTC par an TUA comprise
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6"></div>
        <div className="col-lg-6" style={{ marginTop: "25px" }}>
          <ButtonsWrapper onSubmit={onSubmit} onCancel={onCancel} />
        </div>
      </div>
    </div>
  );
}

export default Confirmation;

function CheckIcon() {
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: "#D9F9F6",
      }}
    >
      <img
        style={{ marginBottom: "7px", marginLeft: "2px" }}
        width="15"
        height="15"
        src={`${process.env.PUBLIC_URL}/assets/images/check.png`}
      />
    </div>
  );
}

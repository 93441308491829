import React, { useState } from "react";
import { useLocation, useHistory } from "react-router";
import NavBar from "../souscription/Components/NavBar";
import InputCustom2 from "../SubComponents/inputCustom/InputCustom2";
import ButtonsWrapper from "../SubComponents/ButtonsWrapper ";
import Footer from "../Footer/Footer";
import { toast } from "react-toastify";
import DocumentMeta from "react-document-meta";
import adminService from "../../services/adminService/adminService";
import NavBarHome from "../home/NavBarHome";
import StepperContact from "../souscription/Components/StepperContact";
const Contact = () => {
  const meta = {
    title:
      "Contactez un expert Garanty : Besoin d’aide, de conseils ou de soutien",
    description:
      "Pour toute question sur une garantie, un contrat d'assurance ou pour déclarer un sinistre, contactez nos experts Garanty en ligne ou par téléphone: à votre écoute 24/7",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      'http-equiv': {
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
        expires: '0',
        pragma: 'no-cache',
      },
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  const [btn, setBtn] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const family = location.state;
  const [etape, setEtape] = useState(0);
  const [contactezNous, setContactezNous] = useState({
    produit: "",
    name: "",
    email: "",
    phone: "",
    categorie: btn ? "null" : "",
    text: "",
    subject: "test",
  });
  const [newsletter, setNewsletter] = useState({ email: "" });
  const handelChangeNews = (e) => {
    const { name, value } = e.target;
    setNewsletter((newsletter) => ({ ...newsletter, [name]: value }));
  };
  const next = () => {
    console.log(contactezNous);
    setEtape(1);
  };
  const confirmer = () => {
    console.log(contactezNous);
    if (
      // contactezNous.categorie &&
      contactezNous.email &&
      contactezNous.name &&
      contactezNous.phone &&
      contactezNous.produit &&
      contactezNous.text
    ) {
      adminService.sendContact(contactezNous).then((res) => {
        if (res.status === 200) {
          // history.push({ pathname: "contact=confirmation?=ok", state: family });
          setEtape(2);
          setTimeout(() => {
            history.push({
              pathname: "contact=confirmation?=ok",
              state: family,
            });
          }, 10000);
        }
      });
    } else {
      toast.error("Tous les champs sont obligatoires.");
    }
  };
  const handelChange = (e) => {
    const { name, value } = e.target;
    setContactezNous((contactezNous) => ({ ...contactezNous, [name]: value }));
  };
  return (
    <DocumentMeta {...meta}>
      <NavBarHome dark={true} />
      <div className="row col-md-12 header-container-slideContact"></div>
      <h1 className="title-contact" style={{ marginTop: "15px" }}>
        FORMULAIRE CONTACT
      </h1>
      <p className="text-contact">
        Vous avez besoin de résilier un contrat ? D'indiquer un changement
        d'adresse ? De déclarer un <br />
        sinistre ? Contactez votre agent pour tout acte de gestion, sa prise en
        charge sera plus rapide ! <br />
        sur le
        <span style={{ color: "#e94475", fontWeight: "bold" }}>71 169 000</span>
      </p>
      <StepperContact activeStep={etape} />
      {etape === 0 ? (
        <>
          <h2 className="title-contact">VOTRE DEMANDE</h2>
          <p className="qts-contact">Étes-vous client Garanty ?</p>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <div className="col-md-6 btn-contratct-div">
              <div
                className="col-md-6 oui-btn-div"
                onClick={() => {
                  setBtn(false);
                }}
              >
                <div
                  className="oui-non-btn"
                  style={
                    btn
                      ? { backgroundColor: "white" }
                      : { backgroundColor: "gray" }
                  }
                >
                  Oui
                </div>
              </div>
              <div
                className="col-md-6 non-btn-div"
                onClick={() => {
                  setBtn(true);
                }}
              >
                <div
                  className="oui-non-btn"
                  style={
                    btn
                      ? { backgroundColor: "gray" }
                      : { backgroundColor: "white" }
                  }
                >
                  Non
                </div>
              </div>
            </div>
          </div>
          {btn ? (
            <></>
          ) : (
            <div
              className="row col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1%",
              }}
            >
              <div className="col-md-6 btn-contratct-div">
                <input
                  name="categorie"
                  type="number"
                  placeholder="Renseignez votre de contrat : (facultatif)"
                  style={{
                    width: "100%",
                    height: "45px",
                    paddingLeft: "10px",
                    textAlign: "center",
                  }}
                  value={contactezNous.categorie}
                  onChange={handelChange}
                />
              </div>
            </div>
          )}
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <div
              className="col-md-6 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Votre demande concerne :
              </label>
              <select
                name="produit"
                style={{ color: "black", width: "100%", height: "45px" }}
                onChange={handelChange}
              >
                <option value="">Choisir...</option>
                {family.map((el, key) => (
                  <option key={key} value={el.familyName}>
                    {el.familyName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "3%",
            }}
          >
            <div
              className="col-md-6 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Votre message
              </label>
              <textarea
                style={{
                  width: "100%",
                  minHeight: "7rem",
                  paddingLeft: "10px",
                }}
                name="text"
                placeholder="Merci de préciser votre demande"
                onChange={handelChange}
              ></textarea>
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "3%",
            }}
          >
            <div className="col-md-6 d-flex flex-d-c mb-3">
              <div>
                <button
                  onClick={next}
                  type="button"
                  className="btn btn-valid"
                >
                  Étape suivante
                </button>
              </div>
            </div>
          </div>
        </>
      ) : etape === 1 ? (
        <>
          <h2 className="title-contact" style={{ marginTop: "15px" }}>
            COORDONNÉES
          </h2>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <label
              style={{
                textAlign: "center",
                color: "#232e85",
                fontFamily: "Century Gothic",
                fontWeight: "bold",
                fontSize: "",
                marginBottom: "15px",
              }}
            >
              Civilité
            </label>
            <div className="col-md-6 btn-contratct-div">
              <div
                className="col-md-6 oui-btn-div"
                onClick={() => {
                  setBtn(false);
                }}
              >
                <div
                  className="oui-non-btn"
                  style={
                    btn
                      ? { backgroundColor: "white" }
                      : { backgroundColor: "gray" }
                  }
                >
                  Madame
                </div>
              </div>
              <div
                className="col-md-6 non-btn-div"
                onClick={() => {
                  setBtn(true);
                }}
              >
                <div
                  className="oui-non-btn"
                  style={
                    btn
                      ? { backgroundColor: "gray" }
                      : { backgroundColor: "white" }
                  }
                >
                  Monsieur
                </div>
              </div>
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <div
              className="col-md-6 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Nom
              </label>
              <input
                name="name"
                value={contactezNous.name}
                onChange={handelChange}
                style={{ color: "black", width: "100%", height: "45px" }}
              />
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <div
              className="col-md-6 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Prénom
              </label>
              <input
                name="nom"
                style={{ color: "black", width: "100%", height: "45px" }}
                // onChange={handelChange}
              />
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <div
              className="col-md-6 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Numéro de télèphone *
              </label>
              <input
                name="phone"
                value={contactezNous.phone}
                style={{ color: "black", width: "100%", height: "45px" }}
                onChange={handelChange}
              />
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <div
              className="col-md-6 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Adresse *
              </label>
              <input
                name="email"
                value={contactezNous.email}
                style={{ color: "black", width: "100%", height: "45px" }}
                onChange={handelChange}
              />
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <div
              className="col-md-3 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Code postal
              </label>
              <input
                name="codeP"
                style={{ color: "black", width: "100%", height: "45px" }}
                // onChange={handelChange}
              />
            </div>
            <div
              className="col-md-3 btn-contratct-div"
              style={{ flexDirection: "column" }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#232e85",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                  fontSize: "",
                  marginBottom: "15px",
                }}
              >
                Ville
              </label>
              <input
                name="codeP"
                style={{ color: "black", width: "100%", height: "45px" }}
                // onChange={handelChange}
              />
            </div>
          </div>
          <div
            className="row col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "3%",
            }}
          >
            <div className="col-md-6 d-flex flex-d-c mb-3">
              <div>
                <button
                  onClick={confirmer}
                  type="button"
                  className="btn btn-valid"
                >
                  Valider votre demande ici
                </button>
              </div>
            </div>
          </div>
        </>
      ) : etape === 2 ? (
        <>
          <h2 className="title-contact" style={{ marginTop: "15px" }}>
            CONFIRMATION
          </h2>
          <p className="qts-contact">
            Merci nous avons bien reçu votre demande.
          </p>
          <p className="qts-contact">
            Votre demande de contact a bien été prise en compte. Un conseiller
            garanty vous contactera sous peu.
          </p>
        </>
      ) : (
        <></>
      )}

      <div
        className="row col-md-12 col-lg-12 col-sm-12 col-12 newsletter d-flex-a"
        style={{ height: "140px" }}
      >
        <div
          className="row col-md-7 col-lg-7 col-sm-7 col-7"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-9">
            <input
              style={{ paddingLeft: "15px" }}
              className="newletter-input"
              name="email"
              type="text"
              placeholder="Votre adresse e-mail....."
              onChange={handelChangeNews}
            />
          </div>
          <div className="col-md-3">
            <button
              id="s'abonner"
              className="newletter-btn"
              onClick={() => {
                if (!newsletter.email) {
                  toast.error("Entrez vous votre e-mail");
                } else {
                  adminService.addToNewsletter(newsletter).then((res) => {
                    if (res.status === 202) {
                      toast.success(
                        "Vous êtes enregistré dans notre newsletter."
                      );
                      history.push({ pathname: "/newsletter?envoyer=ok" });
                      window.scrollTo(500, 0);
                    } else if (res.status === 204) {
                      toast.error("Email déjà enregistré");
                    }
                  });
                }
              }}
            >
              s'abonner
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </DocumentMeta>
  );
};

export default Contact;

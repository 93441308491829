import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import customerService from "../../../services/customer/customerService";
import ButtonsWrapper from "../../SubComponents/ButtonsWrapper ";
const ContactezNous = () => {
  const history = useHistory();
  const [customer, setCustomer] = useState({});
  const [mail, setMail] = useState({
    emailcustomer: JSON.parse(localStorage.getItem("user")).email,
    sendTo: "contact@garanty.tn",
    subject: "",
    text: "",
    type: "contacteznous",
  });
  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("user")).id;
    customerService.getCustomerById(id).then((response) => {
      console.log(response.data);
      setCustomer(response.data);
    });
  }, []);
  const handelChange = (e) => {
    const { value, name } = e.target;
    setMail((mail) => ({ ...mail, [name]: value }));
  };
  const onSubmit = () => {
    customerService.sendMailCustomer(mail).then((res) => {
      if (res.status === 200) {
        history.push("/dashboard");
      }
    });
  };
  const dashboard = () => {
    history.push("/dashboard");
  };

  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Contactez-Nous
      </h4>

      <div className="body-sinistre" style={{ paddingLeft: "50px",padding:"10px" }}>
        <div
          className="row col-md-12 col-lg-12 col-sm-12 CNDA"
        >
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Mobile</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="date_naissance"
              type="text"
              placeholder="Mobile"
              defaultValue={customer.phone}
              disabled
            />
          </div>

          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Email</label>
            <input
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="email"
              type="text"
              placeholder="Email"
              defaultValue={customer.email}
              disabled
            />
          </div>

          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">Sujet</label>
            <select
              style={{ paddingLeft: "10px" }}
              className="input-form"
              name="subject"
              value={mail.subject}
              onChange={handelChange}
            >
              <option value="">sélectionnez</option>
              <option value="Demande d'informations à propos d'un remboursement">
                Demande d'informations à propos d'un remboursement
              </option>
              <option value="Demande d'informations à propos d'une offre">
                Demande d'informations à propos d'une offre
              </option>
              <option value="Demande d'informations à propos d'un sinistre">
                Demande d'informations à propos d'un sinistre
              </option>
              <option value="Demande d'informations à propos d'un contrat">
                Demande d'informations à propos d'un contrat
              </option>
              <option value="Votre question concerne un autre motif">
                Votre question concerne un autre motif
              </option>
            </select>
          </div>
          <div className="col-md-12  d-flex flex-d-c mb-3">
            <label className="label">Votre message</label>
            <textarea
              style={{ fontSize: "large", paddingLeft: "10px" }}
              placeholder="Description..."
              name="text"
              id=""
              cols="20"
              rows="3"
              value={mail.text}
              onChange={handelChange}
            ></textarea>
          </div>
          <div
            className="col-md-12 d-flex flex-d-c mb-3"
            style={{ alignItems: "flex-end" }}
          >
            <ButtonsWrapper onSubmit={onSubmit} onCancel={dashboard} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactezNous;

import familyService from "../services/family/familyService";

export const INIT = "init state";
export const SET_SIDEBAR_STATUS = "[global]SET_SIDEBAR_STATUS";
export const SET_FAMILIES_LIST = "SET_FAMILIES_LIST";

export const init = (data) => {
  return {
    type: INIT,
    payload: data,
  };
};
export const setSideBarStatus = (payload) => {
  return {
    type: SET_SIDEBAR_STATUS,
    payload,
  };
};

export const setListFamiliesListAction = (payload) => {
  return {
    type: SET_FAMILIES_LIST,
    payload,
  };
};

export const getFamiliesList = () => {
  return (dispatch) => {
    familyService.getAllFamily().then((res) => {
      //console.log(res);
      dispatch(
        setListFamiliesListAction(
          res?.data?.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 6 )
        )
      );
    });
  };
};

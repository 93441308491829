import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { getFamiliesList } from "../../store/actions";
import { saveAs } from "file-saver";
import { SocialIcon } from "react-social-icons";
const Footer = () => {
  const dispatch = useDispatch();
  const listFamily = useSelector(({ global }) => global.familiesList);
  let Reglement_jeu_concours = `${process.env.PUBLIC_URL}/assets/pdf/Reglement_jeu_concoursCAN.pdf`
  let Reglement_jeu_Ramadan = `${process.env.PUBLIC_URL}/assets/pdf/RéglementJeu_Romdhanek_mabrouk_maa_Garanty.pdf`
  const saveFile = () => {
    saveAs(
      `${process.env.PUBLIC_URL}/assets/pdf/Mentions légales et CGU GARANTY.pdf`,
      "Mentions légales et CGU GARANTY.pdf"
    );
  };
  React.useEffect(() => {
    if (!listFamily) dispatch(getFamiliesList());
  }, []);
  const history = useHistory();
  return (
    <div className="d-flex-j">
      <div className=" row col-md-10 col-lg-10 col-sm-10 col-10 Footer-container d-flex-j">
        <div
          className="col-md-12 d-flex-j"
          style={{ justifyContent: "flex-start" }}
        >
          <div className="col-md-11" style={{ marginTop: "30px" }}>
            <img
              style={{ width: "100%", maxWidth: "170px" }}
              src={`${process.env.PUBLIC_URL}/assets/images/Logo Garanty Bleu.png`}
            />
          </div>
        </div>

        <div
          className="col-md-6 col-lg-12 col-sm-6 col-12 d-flex-j"
          style={{ marginBottom: "50px" }}
        >
          <div className="col-md-11  col-lg-11 col-sm-12 col-12 container-footer">
            <div className="div-foot">
              <h4
                className=" color-font-title"
                style={{ whiteSpace: "nowrap" }}
              >
                CONTACTEZ-NOUS
              </h4>
              <div className=" color-font-HomePageFooter">
                <a style={{ cursor: "pointer", marginLeft: "5px" }}>
                  Avenue du Japon, Montplaisir - Tunis
                </a>
                <br />

                <a
                  href="tel:71169000"
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                  (+216) 71 169 000
                </a>
                <br />

                <a
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                  href="mailto:contact@garanty.tn"
                >
                  contact@garanty.tn
                </a>
                <br />

                <a style={{ cursor: "pointer", marginLeft: "5px" }}>
                  Du lundi au vendredi 9h00 à 19h00 <br />
                  Samedi et dimanche de 9h00 à 13h00
                </a>
              </div>
              <div className="div-icon-social">
                <SocialIcon
                  target="_blank"
                  url="https://www.facebook.com/Garanty.Tunisie"
                  style={{ height: 32, width: 32, marginRight: "5px" }}
                />
                <SocialIcon
                  target="_blank"
                  url="https://www.instagram.com/garanty_tn/?igshid=MzRlODBiNWFlZA%3D%3D"
                  style={{ height: 32, width: 32, marginRight: "5px" }}
                />
                <SocialIcon
                  target="_blank"
                  url="https://www.youtube.com/@garantytunisie"
                  style={{ height: 32, width: 32, marginRight: "5px" }}
                />
                <SocialIcon
                  target="_blank"
                  url="https://www.linkedin.com/company/garantytunisie/"
                  style={{ height: 32, width: 32 }}
                />
              </div>
            </div>
            <div className="div-foot">
              <h4
                className=" color-font-title"
                style={{ whiteSpace: "nowrap" }}
              >
                À PROPOS
              </h4>
              <div className=" color-font-HomePageFooter">
                <a
                  onClick={() =>
                    history.push({
                      pathname: `${process.env.PUBLIC_URL}/apropos`,
                      state: listFamily,
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  À propos de nous{" "}
                </a>
                <br />
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/devenir-partenaire");
                  }}
                >
                  {" "}
                  Nos partenaires
                </a>
                <br />
                {/* <a
                  style={{ cursor: "pointer" }}
                 href={Reglement_jeu_Ramadan} target="_blank"
                 rel="noreferrer"
                >
                  {" "}
                  Règlement Jeu Romdhanek Mabrouk m3a Garanty
                </a>
                <br /> */}
                <a
                  onClick={() => toast.info("Prochainement disponible")}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Blog
                </a>
              </div>
            </div>
            <div className="div-foot">
              <h4 className=" color-font-title">GARANTIES</h4>
              <div className=" color-font-HomePageFooter">
                {listFamily?.map((el, key) => (
                  <div key={key}>
                    <a
                      style={{ cursor: "pointer" }}
                      key={key}
                      onClick={() => {
                        if (el.familyName == "Smartphone") {
                          history.push({
                            pathname: "/garanties/smartphone",
                            state: el.idfamily,
                          });
                        } else if (el.familyName == "Optique") {
                          history.push({
                            pathname: "/garanties/optique",
                            state: el.idfamily,
                          });
                        } else if (el.familyName == "Multimédia") {
                          history.push({
                            pathname: "/garanties/multimedia",
                            state: el.idfamily,
                          });
                        } else if(el.familyName == "Meubles"){
                          history.push({
                            pathname: "/garanties/meubles",
                            state: el.idfamily,
                          });
                        }else {
                          history.push({
                            pathname: "/garanties/electromenager",
                            state: el.idfamily,
                          });
                        }
                      }}
                    >
                      Garantie {el.familyName}
                    </a>
                    <br />
                  </div>
                ))}
              </div>
            </div>
            <div className="div-foot">
              <h4 className=" color-font-title">AIDE</h4>
              <div className=" color-font-HomePageFooter">
                {/* <a
                  onClick={() => history.push(`${process.env.PUBLIC_URL}/faq`)}
                  style={{ cursor: "pointer" }}
                >
                  FAQ
                </a>
                <br /> */}
                <a
                  onClick={() =>
                    history.push(`${process.env.PUBLIC_URL}/connexion`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Espace client
                </a>
                <br />
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push({ pathname: "/contact", state: listFamily })
                  }
                >
                  {" "}
                  Contact
                </a>
                <br />
                <a style={{ cursor: "pointer" }} onClick={saveFile}>
                  {" "}
                  CGU
                </a>
              </div>
            </div>
            <div className="img-footer-garanty">
              <img
                className="img-foot"
                src={`${process.env.PUBLIC_URL}/assets/images/Groupe 246.png`}
              />
            </div>
          </div>
        </div>
        <footer
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "14px",
            marginBottom: "10px",
          }}
        >
          &copy; 2021 Garanty <br />
          Made by <a href="http://veganet.com.tn">VEGANET</a>
        </footer>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { useHistory, useLocation } from "react-router";

const Succes = () => {
  const history = useHistory();
  const location = useLocation();
  const numSinistre = location.state;
  const onSubmit = () => {
    history.push("/");
  };
  return (
    <div>
      <h2 className="succ" onClick={onSubmit}>
        Nous vous confirmons que votre déclaration de sinistre N°{numSinistre} a
        bien été enregistrée
      </h2>
    </div>
  );
};

export default Succes;

import React from "react";
import { GoInfo } from "react-icons/go";

function InputCustom({ name, type, label, ...props }) {
  return (
    <div className="col-md-6 d-flex flex-d-c mb-3">
      <label className="label">
        {label}
        <span style={{ color: "red" }}>*</span>
        {label == "Numéro IMEI" ? (
          <GoInfo
            title="Tapez: *#06# sur le clavier de votre mobile pour obtenir votre numéro d’IMEI"
            size="18px"
            color="#3389cc"
          />
        ) : (
          <></>
        )}
        {label == "Numéro de série" ? (
          <GoInfo
            title="Votre numéro de série se trouve à l'intérieur de vos montures"
            size="18px"
            color="#3389cc"
          />
        ) : (
          <></>
        )}
      </label>
      <input
        style={{ paddingLeft: "10px" }}
        className="input-form"
        placeholder={label}
        type={type}
        name={name}
        {...props}
      />
      <div class="invalid-feedback">Please provide a valid {label}.</div>
    </div>
  );
}

export default InputCustom;
// value={listProductInfo.N_serie} onChange={handleChange}

import React from 'react'
import NavBar from './Components/NavBar'

 const SuccessRequest = () => {
    return (
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 margin0">
      <NavBar text="Souscription"/>
      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 question-souscription">
        <h6 className="question-title">Demande crée avec succès</h6>
      </div>
      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 form-souscription d-flex-j-c">
        <h4 style={{marginTop:"30px", textAlign: "center"}}>Notre service vous contactera dans les plus brefs délais pour finaliser votre demande.</h4>
      </div>
    </div>
    )
}
export default SuccessRequest

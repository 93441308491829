import { useState } from "react";
import formuleService from "../../services/formule/formuleService";
import { useEffect } from "react";
import { savedataStepZero } from "../souscription/store/actionSouscription";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import { subDays } from "date-fns";
import fr from "date-fns/locale/fr";
import DocumentMeta from "react-document-meta";
import { saveFormule } from "../CreateUser/store/userAction";
registerLocale("fr", fr);
const Optique = ({ idfamily, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [formuleList, setFormuleList] = useState([]);
  const [keyFormula, setKeyFormula] = useState(0);
  const [show, setShow] = useState(false);
  const [simulation, setSimulation] = useState([]);

  const subscribe = (index) => {
    dispatch(saveFormule(simulation[index]));
    if (localStorage.getItem("user")) {
      history.push({
        pathname: "/Souscription/Etape1",
      });
    } else {
      history.push({
        pathname: "/connexion",
      });
    }
  };
  const [formuleDto, setFormuleDto] = useState({
    amount: "",
    dateAchat: "",
    idFamille: idfamily,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormuleDto((formuleDto) => ({ ...formuleDto, [name]: value }));
  };
  useEffect(() => {
    formuleService.getAllFormulaById(idfamily).then((res) => {
      //console.log(res);
      setFormuleList(res.data.sort((a, b) => a.fomula.price - b.fomula.price));
    });
  }, []);

  const Continuer = async (e) => {
    e.preventDefault();
    if (formuleDto.amount && formuleDto.dateAchat) {
      dispatch(savedataStepZero(formuleDto));
      await formuleService
        .calculateFormula(formuleDto)
        .then(async (response) => {
          if (response.status === 200) {
            //console.log(response);
            setSimulation(response.data);
            setShow(true);
            window.scrollTo(0, 1350);
          } else {
            toast.error("Format incorrect du coût d'achat");
          }
        });
    } else if (!formuleDto.amount) {
      toast.error("Enter votre prix d'achat du produit");
    } else if (!formuleDto.dateAchat) {
      toast.error("Enter votre date d'achat du produit");
    }
  };
  const onClick = () => {
    history.push({ pathname: "/garanties/optique", state: idfamily });
  };
  return (
    <div>
      <div className="simula-div">
        <div className="row col-sm-12 simulation-prix">
          <div className="col-md-12 text-center">
            <h4 className="simulezz-prix">SIMULATION GRATUITE EN 2 CLICS</h4>

            <h6 className="col-8 dd">
              Précisez le prix de vos lunettes et la date de son achat pour
              découvrir la formule de garantie qui correspond à vos besoins et à
              votre profil.
            </h6>
          </div>
        </div>
        <div className="row col-md-12 simulation-input">
          <div className="row col-md-8">
            <div className="col-md-4 aide">
              <input
                name="amount"
                type="number"
                className="input-prix"
                placeholder="Coût d'achat"
                value={formuleDto.amount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 aide">
              <DatePicker
                minDate={subDays(new Date(), 0)}
                maxDate={addDays(new Date(), 0)}
                value={formuleDto.dateAchat}
                className="input-prix"
                selected={startDate}
                dateFormat="dd/MM/yyyy"
                locale="fr"
                placeholderText="Date d’achat"
                onChange={(date) => {
                  setStartDate(date);
                  handleChange({ target: { name: "dateAchat", value: date } });
                }}
              />
            </div>
            <div className="col-md-4 aide div-btn-calculate">
              <div className="calcule-btn" id="continuer">
                <button
                  className="calcule-btn"
                  type="submit"
                  onClick={Continuer}
                  id="continuer"
                >
                  Continuer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-sm-12 smartphone-body">
        <div
          className="row col-lg-12 col-md-12 formule-offer"
          style={!show ? { display: "none" } : { marginBottom: "4%" }}
        >
          <div className="row col-md-8">
            <div
              className="col-md-12 text-center"
              style={{ marginBottom: "30px" }}
            >
              <h4 className="simulez-prix">
                LA MEILLEURE PROTECTION POUR VOS LUNETTES
              </h4>
            </div>
            <div className="formula-bloc">
              {simulation?.map((el, i) => (
                <div className="formule-bloc">
                  <div
                    className="div-extend-formule"
                    style={{ height: "auto" }}
                  >
                    <h4 className="text-align">Formule</h4>
                    <img
                      className="img-formule-extend"
                      style={
                        el?.fomula?.price === 11.5 ? { maxWidth: "312px" } : {}
                      }
                      src={
                        `${process.env.PUBLIC_URL}/assets/images/` +
                        el?.fomula?.name +
                        ".svg"
                      }
                    />
                    <div className="d-flex">
                      <h2
                        style={{
                          fontSize: "28px",
                          fontWeight: "bold",
                          marginTop: "15%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        {(el?.fomula?.price/12)?.toFixed(3)} DT / mois
                      </h2>
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          marginBottom: "6px",
                        }}
                      >
                        {el?.fomula?.price?.toFixed(3)} DT / an
                      </h6>
                      <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
                        prix des lunettes de {el?.fomula?.minValueProd}
                        {el?.fomula?.maxValueProd >= 9998
                          ? " et +"
                          : `${-el?.fomula?.maxValueProd} DT`}
                      </h6>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          {el?.fomula?.maxValueProd <= 500 &&
                          el?.fomula?.minValueProd <= 200
                            ? "Lunettes de vue"
                            : "Lunettes de vue et solaires"}
                        </h6>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          {el?.fomula?.maxValueProd < 500 &&
                          el?.fomula?.minValueProd === 1
                            ? "4 paires du foyer couvertes"
                            : "1 paire couverte"}
                        </h6>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-10" style={{ fontSize: "12px" }}>
                          {el?.fomula?.nbrIntervYear > 1
                            ? "4 interventions /an"
                            : "1 intervention /an"}
                        </h6>
                      </div>
                      {el?.listBreakDown?.map((br, ke) =>
                        br.value ? (
                          <>
                            <div
                              key={ke}
                              className="row col-md-12 detail-formule"
                            >
                              <h6
                                className="col-md-10"
                                style={{ fontSize: "12px" }}
                              >
                                {br.name}
                              </h6>
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "13px" }}>
                          Assistance téléphonique 7j/7
                        </h6>
                      </div>
                      <h6
                        style={{
                          fontSize: "17px",
                          fontWeight: "bold",
                          marginTop: "5px",
                        }}
                      >
                        Plafond de garantie {el?.fomula?.ceilingGuarantee} DT
                      </h6>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          Dont verre {el?.fomula?.dontverre} DT
                        </h6>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          Dont montures {el?.fomula?.dontmontures} DT
                        </h6>
                      </div>
                    </div>
                    <div
                      className="simulation-btn"
                      key={i}
                      onClick={() => subscribe(i)}
                      style={{ marginTop: "30px" }}
                      id="souscrire"
                    >
                      <h4
                        style={{
                          font: "normal normal bold 14px/40px Century Gothic",
                          fontWeight: "bold",
                          color: "#FFFFFF",
                        }}
                      >
                        Souscrire
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row col-lg-12 col-md-12 formule-offer">
          <div
            className="row col-lg-12 col-md-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-12 col-lg-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="col-md-12 text-center">
                <h4 className="simulez-prix">NOS FORMULES GARANTY OPTIQUE</h4>
                <h6 className="col-8" style={{ marginBottom: "3%" }}>
                  Nous vous proposons plusieurs formules de garantie selon le
                  prix d’achat de vos lunettes. Découvrez le détail de chaque
                  formule et assurez votre optique pour une utilisation en toute
                  sérénité !
                </h6>
              </div>
              <div
                className="col-xl-12 col-lg-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="form1" style={{ paddingRight: "1%" }}>
                  {formuleList.map((el, index) => (
                    <div
                      className={
                        keyFormula === index
                          ? "col-12 formula-select"
                          : "col-12 formula"
                      }
                      key={index}
                      onClick={() => {
                        setKeyFormula(index);
                      }}
                    >
                      <div
                        className="form"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h4
                          className="dispnone"
                          style={
                            keyFormula === index
                              ? { color: "#ffffff", paddingLeft: "5px" }
                              : { color: "#888888", paddingLeft: "5px" }
                          }
                        >
                          Formule
                        </h4>
                        <img
                          className={
                            keyFormula === index
                              ? "img-formula-select"
                              : "img-formula"
                          }
                          src={
                            `${process.env.PUBLIC_URL}/assets/images/` +
                            el.fomula?.name +
                            ".svg"
                          }
                        />
                      </div>
                      <div className="col-2 dispnone"></div>
                      <div className="col-4 text-formula">
                        <h6
                          style={
                            keyFormula === index
                              ? { color: "#ffffff" }
                              : { color: "#888888" }
                          }
                        >
                          {(el?.fomula?.price/12)?.toFixed(3)} DT / mois
                        </h6>
                        <h6
                          style={
                            keyFormula === index
                              ? { color: "#ffffff" }
                              : { color: "#888888" }
                          }
                        >
                          {el?.fomula?.price?.toFixed(3)} DT / an
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="form2" style={{ paddingLeft: "1%" }}>
                  <div
                    className="div-extend-formule"
                    style={{ height: "500px" }}
                  >
                    <h4 className="text-align">Formule</h4>
                    <img
                      className="img-formule-extend"
                      src={
                        `${process.env.PUBLIC_URL}/assets/images/` +
                        formuleList[keyFormula]?.fomula?.name +
                        ".svg"
                      }
                    />
                    <div className="d-flex">
                      <h2
                        style={{
                          fontSize: "23px",
                          fontWeight: "bold",
                          marginTop: "15%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        {(formuleList[keyFormula]?.fomula?.price / 12).toFixed(
                          3
                        )}{" "}
                        DT / mois
                      </h2>
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          marginBottom: "6px",
                        }}
                      >
                        {formuleList[keyFormula]?.fomula?.price?.toFixed(3)} DT
                        / an
                      </h6>
                      <h6 style={{ fontWeight: "bold", fontSize: "11px" }}>
                        prix des lunettes de{" "}
                        {formuleList[keyFormula]?.fomula?.minValueProd}
                        {formuleList[keyFormula]?.fomula?.maxValueProd >= 9998
                          ? " et +"
                          : `${-formuleList[keyFormula]?.fomula
                              ?.maxValueProd} DT`}
                      </h6>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          {formuleList[keyFormula]?.fomula?.maxValueProd <=
                            500 &&
                          formuleList[keyFormula]?.fomula?.minValueProd <= 200
                            ? "Lunettes de vue"
                            : "Lunettes de vue et solaires"}
                        </h6>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          {formuleList[keyFormula]?.fomula?.maxValueProd <
                            500 &&
                          formuleList[keyFormula]?.fomula?.minValueProd === 1
                            ? "4 paires du foyer couvertes"
                            : "1 paire couverte"}
                        </h6>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-10" style={{ fontSize: "12px" }}>
                          {formuleList[keyFormula]?.fomula?.nbrIntervYear > 1
                            ? "4 interventions /an"
                            : "1 intervention /an"}
                        </h6>
                      </div>
                      {formuleList[keyFormula]?.listBreakDown?.map((br, ke) =>
                        br.value ? (
                          <>
                            <div
                              key={ke}
                              className="row col-md-12 detail-formule"
                            >
                              <h6
                                className="col-md-10"
                                style={{ fontSize: "12px" }}
                              >
                                {br.name}
                              </h6>
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "13px" }}>
                          Assistance téléphonique 7j/7
                        </h6>
                      </div>
                      <h6
                        style={{
                          fontSize: "17px",
                          fontWeight: "bold",
                          marginTop: "5px",
                        }}
                      >
                        Plafond de garantie{" "}
                        {formuleList[keyFormula]?.fomula?.ceilingGuarantee} DT
                      </h6>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          Dont verre{" "}
                          {formuleList[keyFormula]?.fomula?.dontverre} DT
                        </h6>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <h6 className="col-md-12" style={{ fontSize: "14px" }}>
                          Dont montures{" "}
                          {formuleList[keyFormula]?.fomula?.dontmontures} DT
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#393c8f" }}>
        <div className="d-flex-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <h4
              style={{
                fontFamily: "Century Gothic",
                fontWeight: "bold",
                color: "#ffffff",
              }}
            >
              CE QUI EST COUVERT
            </h4>
          </div>
          <div
            className="col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0",
              padding: "0",
            }}
          >
            <div className="col-md-8 gr-container1">
              <div
                className="row col-md-12 margin0 d-flex-j"
                style={{ position: "relative" }}
              >
                <div className="row col-md-12" style={{ position: "relative" }}>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/Broken optique.svg`}
                      />
                      <h6 className="text-img-couvert">Casse Verre</h6>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/Casse Monture.svg`}
                      />
                      <h6 className="text-img-couvert">Casse Monture</h6>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/Casse tte cause.svg`}
                      />
                      <h6 className="text-img-couvert">
                        {" "}
                        Casse toutes <br />
                        causes
                      </h6>
                    </div>
                  </div>
                </div>
                <div
                  className="row col-md-12 row-reverse margin"
                  style={{ position: "relative" }}
                >
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/assistance.svg`}
                      />
                      <h6 className="text-img-couvert">
                        Assistance &
                        <br /> livraison
                      </h6>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/Offre el 3ayla.svg`}
                      />
                      <h6 className="text-img-couvert">Offre IFRAH W FARAH</h6>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/Rembourssement.svg`}
                      />
                      <h6 className="text-img-couvert">Remboursement</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row col-sm-12 garantie-Smart">
            <div className="col-sm-12">
              <div className="btn-hold">
                <h4 className="smartphone-garanty">
                  Enfin une Garantie optique... smart!
                </h4>

                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="btn-decouvrezGarantie"
                    onClick={onClick}
                    id="decouvrezGarantie"
                  >
                    <h4 className="btn-garanty-smartphone">
                      DÉCOUVREZ NOTRE ASSURANCE OPTIQUE
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Optique;

import * as ACTION from "./actions";

const initialState = {
  data: 0,
  sideBarStatus: true,
  familiesList: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.INIT:
      return {
        ...state,
        data: action.payload,
      };

    case ACTION.SET_SIDEBAR_STATUS:
      return {
        ...state,
        sideBarStatus: action.payload,
      };

    case ACTION.SET_FAMILIES_LIST:
      return {
        ...state,
        familiesList: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

// import axios from "axios";

// const adminAxios = axios.create();
// adminAxios.defaults.baseURL =
//   // "https://be.garanty.tn:8443/garantie-api-admin/v1";
// "http://38.242.231.224:8080/garantie-api-admin/v1";

// adminAxios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
// adminAxios.interceptors.request.use(
//   function (request) {
//     return request;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
import { adminAxios } from "../../axiosConfig/InstancesAxios";
export default {
  getAllFormulaById: (idfamily) => {
    return adminAxios.post("/getAllformulasbyFamily", null, {
      params: {
        idfamily,
      },
    });
  },

  calculateFormula: (Model) => {
    return adminAxios.post("/calculateFormula", Model);
  },
  getFormulaByParentId: (idParent) => {
    return adminAxios.post("/getFormulabyIdParent", null, {
      params: { idParent },
    });
  },
};

import React from "react";
import { GoInfo } from "react-icons/go";

function InputFileCustom({ name, onChange, label, id, multiple, ...props }) {
  return (
    <div className="col-md-6 d-flex flex-d-c mb-3">
      <label className="label">
        {label} <span style={{ color: "red" }}>*</span>
        {name === "facture" ? (
          <GoInfo
            title="La facture d’achat doit mentionner le nom d’adhérent et/ou propriétaire, le modèle, le numéro de série ou IMEI, la date d’achat et la valeur de votre appareil"
            size="18px"
            color="#3389cc"
          />
        ) : (
          <></>
        )}
      </label>
      <input
        name={name}
        onChange={(e) => {
          onChange({
            target: { name: name, value: e.target.files[0], id: id },
          });
        }}
        style={{ paddingLeft: "10px" }}
        className="form-control form-control-lg"
        placeholder={name}
        accept="image/png, image/jpeg, image/jpg, .pdf"
        type="file"
        multiple={multiple}
      />
    </div>
  );
}

export default InputFileCustom;

import { ADD_USERS_REQUEST, ADD_FORMULE_REQUEST } from "./userAction";

const initialState = {
  loading: false,
  user: [],
  formule: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USERS_REQUEST:
      return {
        registering: true,
        user: action.payload,
        formule: state.formule,
        error: "",
      };

    case ADD_FORMULE_REQUEST:
      return {
        registering: true,
        user: state.user,
        formule: action.payload,
        error: "",
      };

    default:
      return state;
  }
};

export default reducer;

import { useEffect, useState } from "react";
import customerService from "../../services/customer/customerService";
import "./inscrit.css";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { saveUser } from "../CreateUser/store/userAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NavBarHome from "../home/NavBarHome";
import Footer from "../Footer/Footer";
import familyService from "../../services/family/familyService";
import { BsGoogle } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import { gapi } from "gapi-script";
const Inscrit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [forgetPass, setForgetPass] = useState(false);
  window.gapi.load("client:auth2", () => {
    window.gapi.client.init({
      clientId:
        "602346386490-sbctad5pa4o07ppilp8a4i837eif6srd.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });
  const clientId =
    "602346386490-sbctad5pa4o07ppilp8a4i837eif6srd.apps.googleusercontent.com";
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const formule = useSelector(({ userReducer }) => userReducer.formule);
  const [familyList, setFamilyList] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    familyService.getAllFamily().then((res) => {
      setFamilyList(res.data.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 4 ));
    });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer((customer) => ({ ...customer, [name]: value }));
  };
  const onSubmit = () => {
    setForgetPass(true);
  };
  const responseFacebook = (res) => {
    let name = res.name.split(" ");
    customerService
      .createFacebookCustomer({
        email: res.email,
        firstName: name[0],
        lastName: name[1],
        facebookid: res.userID,
      })
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        } else if (res.status == 202) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        }
      });
  };
  const onSuccess = (res) => {
    customerService
      .createGoogleCustomer({
        email: res.profileObj.email,
        firstName: res.profileObj.givenName,
        lastName: res.profileObj.familyName,
        googleid: res.profileObj.googleId,
      })
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        } else if (res.status == 202) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        }
      });
  };
  const onFailure = (res) => {
    console.log("login Failure:", res.details);
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    // isSignedIn: true,
    accessType: "offline",
  });

  const seConnecter = async (e) => {
    e.preventDefault();
    if (!forgetPass) {
      if (customer.email && customer.password) {
        await customerService
          .logincustomer(customer)
          .then(async (response) => {
            if (response.status === 200 && formule) {
              localStorage.setItem("user", JSON.stringify(response.data));
              await customerService
                .getCustomerById(response.data.id)
                .then((res) => {
                  let customer = res.data;
                  dispatch(saveUser(customer));
                });
              history.push({
                pathname: "/Souscription/Etape1",
              });
            } else if (response.status === 200) {
              localStorage.setItem("user", JSON.stringify(response.data));
              await customerService
                .getCustomerById(response.data.id)
                .then((res) => {
                  let customer = res.data;
                  dispatch(saveUser(customer));
                });
              history.push({
                pathname: "/",
              });
            }
          })
          .catch((Error) => {
            //console.log(Error);
            toast.error(
              "Veuillez vérifier votre nom d'utilisateur ou votre mot de passe"
            );
          });
      } else {
        toast.error(
          "Veuillez saisir votre nom d'utilisateur et votre mot de passe"
        );
      }
    } else {
      if (customer.email) {
        await customerService
          .sendCodePassword({
            sendTo: customer.email,
            subject: "codeReset",
          })
          .then((res) => {
            if (res.status == 200) {
              history.push({
                pathname: "/home/resetpassword",
                state: customer.email,
              });
            }
            if (res.status != 200) {
              toast.error("Vérifier votre adresse e-mail");
            }
          });
      } else {
        toast.error("Veuillez entrer votre adresse email");
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      seConnecter(e);
    }
  };
  return (
    <div className="container-fluid">
      <NavBarHome dark relative />
      <div className="body-inscrit">
        <h1
          style={{
            fontFamily: "Century Gothic",
            fontSize: "30px",
            textAlign: "center",
            fontWeight: "bold",
            color: "#0C015F",
            marginTop: "7%",
          }}
        >
          {forgetPass ? "Mot de passe oublié" : "Connectez-vous"}
        </h1>
        <div
          className="row col-md-12 col-lg-12 col-sm-12 col-lg-12 col-sm-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div
            className="col-sm-6 col-md-4 col-lg-4 aide"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                textAlign: "left",
                font: "normal normal bold 14px/26px Century Gothic",
                letterSpacing: "0px",
                color: "#000000",
                opacity: "1",
                marginLeft: "4%",
                marginBottom: "13px",
              }}
            >
              Adresse email : <span style={{ color: "red" }}>*</span>
            </label>
            <input
              name="email"
              type="email"
              className="login"
              placeholder="Entrer votre Adresse email"
              value={customer.email}
              onChange={handleChange}
            />
          </div>
        </div>
        {forgetPass ? (
          <></>
        ) : (
          <>
            <div
              className="row col-md-12 col-lg-12 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "14px",
              }}
            >
              <div
                className="col-sm-6 col-md-4 col-lg-4 aide"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    textAlign: "left",
                    font: "normal normal bold 14px/26px Century Gothic",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: "1",
                    marginLeft: "4%",
                    marginBottom: "13px",
                  }}
                >
                  Mot de passe :<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="password"
                  type="password"
                  className="login"
                  placeholder="Entrer votre mot de passe"
                  value={customer.password}
                  onChange={handleChange}
                  onKeyPress={handleKeyDown}
                />
              </div>
            </div>
            <div
              className="row col-md-12 col-lg-12 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1%",
              }}
            >
              <div
                className="col-sm-6 col-md-4 col-lg-4"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label class="checkbox-div" style={{ height: "25px" }}>
                  Se souvenir de moi
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </label>

                <p
                  style={{
                    textDecoration: "underline",
                    fontSize: "12px",
                    color: "#C727CC",
                    cursor: "pointer",
                  }}
                  onClick={onSubmit}
                >
                  Mot de passe oublié ?
                </p>
              </div>
            </div>
          </>
        )}

        <div
          className="row col-md-12 col-lg-12 col-sm-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
          }}
        >
          <div
            className="col-sm-6 col-md-4 col-lg-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="connect-btn">
              <button
                className="connect-btn"
                id="connecter"
                onClick={seConnecter}
              >
                {forgetPass
                  ? "ENVOYER UN CODE DE RÉINITIALISATION"
                  : "SE CONNECTER"}
              </button>
            </div>
          </div>
        </div>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "Century Gothic",
            fontSize: "17px",
            marginTop: "18px",
            fontWeight: "bold",
          }}
        >
          Connectez-vous ou inscrivez-vous avec Google ou Facebook
        </h3>
        <div className="row col-md-6 col-lg-8 col-sm-8 socialmedia-div">
          <div
            className="col-sm-6 col-md-4 col-lg3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="connect-btn"
              style={{
                backgroundColor: "#ff2f19",
                // border: "1px solid #ff2f19",
                color: "white",
                fontSize: "15px",
                marginTop: "3px",
              }}
            >
              <button
                className="connect-btn"
                onClick={signIn}
                style={{
                  backgroundColor: "#ff2f19",
                  // border: "1px solid #ff2f19",
                  color: "white",
                  fontSize: "15px",
                  justifyContent: "center",
                }}
              >
                <BsGoogle size="30" style={{ marginRight: "14px" }} />
                Google
              </button>
            </div>
          </div>
          <div
            className="col-sm-6 col-md-4 col-lg3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="connect-btn"
              style={{
                backgroundColor: "#1e4799",
                border: "1px solid #1e4799",
                marginTop: "3px",
              }}
            >
              <button
                className="connect-btn"
                style={{
                  backgroundColor: "#1e4799",
                  border: "1px solid #1e4799",
                  fontSize: "15px",
                  justifyContent: "center",
                }}
              >
                <FacebookLogin
                  appId="1512537785825577"
                  autoLoad={false}
                  fields="name,email,picture"
                  textButton={false}
                  cssClass="fb"
                  callback={responseFacebook}
                />
                <FaFacebookSquare size="30" className="fb2" />
                Facebook
              </button>
            </div>
          </div>
        </div>
        <div
          className="row col-md-12 col-lg-12 col-sm-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1%",
          }}
        ></div>
        <div
          className="row col-md-12 col-lg-12 col-sm-12 col-lg-12 col-sm-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-sm-6 col-md-4 col-lg-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5%",
                marginBottom: "10%",
              }}
            >
              <h3
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/26px Century Gothic",
                  letterSpacing: "0px",
                  color: "#161616",
                  opacity: "1",
                }}
              >
                Vous n'avez pas un compte ?
              </h3>
              <a
                style={{
                  textAlign: "left",
                  textDecoration: "underline",
                  font: "normal normal normal 12px/26px Century Gothic",
                  letterSpacing: "0px",
                  color: "#C727CC",
                  opacity: "1",
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push({
                    pathname: "/home/CreateUser/step1",
                  });
                }}
              >
                Créer Maintenant
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-lg-12 col-sm-12">
        <div className="col-sm-6 col-md-4 col-lg-4"></div>
      </div>
      <Footer listFamily={familyList} />
    </div>
  );
};

export default Inscrit;

import React from "react";
// import './App.css';
// import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import NavBar from "../souscription/Components/NavBar";
import Sinistres from "./Pages/Sinistres";
import CreateSinistre from "./Pages/CreateSinistre";
import HomeDashboard from "./Pages/HomeDashboard";
import GeneralitySinistre from "./Pages/GeneralitySinistre";
import ContractList from "./Pages/ContractList";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import NavBarSide from "./components/NavBarSide";
import BreakDownType from "./Pages/BreakDownType";
import "./dashboard.css";
import { useSelector } from "react-redux";
import Succes from "./Pages/Succes";
import MonCompte from "./Pages/MonCompte";
import Rembourssement from "./Pages/Rembourssement";
import MesDoc from "./Pages/MesDoc";
import Reclamation from "./Pages/Reclamation";
import FAQ from "./Pages/FAQ";
import ContactezNous from "./Pages/ContactezNous";
import MenLegale from "./Pages/MenLegale";
import NosPartenaires from "./Pages/NosPartenaires";

import TousLesSinistres from "./sinistre/TousLesSinistres";
import NouveauxSinistres from "./sinistre/NouveauxSinistres";
import EnAttenteDeComplémentsDeDocuments from "./sinistre/EnAttenteDeComplémentsDeDocuments";
import EnAttenteDuRetraitDeChezLeClient from "./sinistre/EnAttenteDuRetraitDeChezLeClient";
import EnAttenteDeValidationDeLaConformité from "./sinistre/EnAttenteDeValidationDeLaConformité";
import EnAttenteDelexpertisePréréparation from "./sinistre/EnAttenteDelexpertisePréréparation";
import EnAttenteDuTransfertVersLeRéparateur from "./sinistre/EnAttenteDuTransfertVersLeRéparateur";
import EnAttenteDuDiagnosticDuRéparateur from "./sinistre/EnAttenteDuDiagnosticDuRéparateur";
import EnAttenteDeLaValidationDuDevis from "./sinistre/EnAttenteDeLaValidationDuDevis";
import EnAttenteDuRetraitDeChezLeRéparateur from "./sinistre/EnAttenteDuRetraitDeChezLeRéparateur";
import EnAttenteDelexpertisePostréparation from "./sinistre/EnAttenteDelexpertisePostréparation";
import EnAttenteDeLaLivraison from "./sinistre/EnAttenteDeLaLivraison";
import Clôturés from "./sinistre/Clôturés";
import Details from "./sinistre/Details";

function Dashboard() {
  const windowWidth = useWindowWidth();

  const sideBarStatus = useSelector(({ global }) => global.sideBarStatus);
  return (
    <div className="page-wrapper">
      <Route>
        <NavBarSide />
        <div
          style={{
            marginLeft: sideBarStatus && windowWidth >= 900 ? "328px" : "0px",
          }}
          className="main"
        >
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard`}
            exact
            component={HomeDashboard}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/details`}
            exact
            component={Details}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/contract/:id`}
            exact
            component={ContractList}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/contract`}
            exact
            component={ContractList}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/sinistre`}
            exact
            component={Sinistres}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/TousLesSinistres`}
            exact
            component={TousLesSinistres}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/NouveauxSinistres`}
            exact
            component={NouveauxSinistres}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDeComplémentsDeDocuments`}
            exact
            component={EnAttenteDeComplémentsDeDocuments}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDeValidationDeLaConformité`}
            exact
            component={EnAttenteDeValidationDeLaConformité}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDuDiagnosticDuRéparateur`}
            exact
            component={EnAttenteDuDiagnosticDuRéparateur}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDuRetraitDeChezLeClient`}
            exact
            component={EnAttenteDuRetraitDeChezLeClient}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDelExpertisePréréparation`}
            exact
            component={EnAttenteDelexpertisePréréparation}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDuTransfertVersLeRéparateur`}
            exact
            component={EnAttenteDuTransfertVersLeRéparateur}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDeLaValidationDuDevis`}
            exact
            component={EnAttenteDeLaValidationDuDevis}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDuRetraitDeChezLeRéparateurs`}
            exact
            component={EnAttenteDuRetraitDeChezLeRéparateur}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDelexpertisePostréparation`}
            exact
            component={EnAttenteDelexpertisePostréparation}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/EnAttenteDeLaLivraison`}
            exact
            component={EnAttenteDeLaLivraison}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Sinistre/Clôturés`}
            exact
            component={Clôturés}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/sinistre/add-sinistre`}
            exact
            component={CreateSinistre}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/sinistre/nature-sinistre`}
            exact
            component={BreakDownType}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/sinistre/gen-sinistre`}
            exact
            component={GeneralitySinistre}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/sinistre/success`}
            exact
            component={Succes}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/mon-compte`}
            exact
            component={MonCompte}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/rembourssement`}
            exact
            component={Rembourssement}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/mes-documents`}
            exact
            component={MesDoc}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/Reclamation`}
            exact
            component={Reclamation}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/FAQ`}
            exact
            component={FAQ}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/contactez-nous`}
            exact
            component={ContactezNous}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/mentions-legales`}
            exact
            component={MenLegale}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard/contactez-nous/assistance/nos-partenaire`}
            exact
            component={NosPartenaires}
          />
        </div>
      </Route>
    </div>
  );
}

export default Dashboard;

import "./Souscrire.css";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveFormule } from "../CreateUser/store/userAction";
import { logRoles } from "@testing-library/dom";
import NavBarHome from "../home/NavBarHome";
import Footer from "../Footer/Footer";

const Souscrire = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const formules = location.state;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subscribe = (index) => {
    dispatch(saveFormule(formules[index]));
    if (localStorage.getItem("user")) {
      history.push({
        pathname: "/Souscription/Etape1",
      });
    } else {
      history.push({
        pathname: "/connexion",
      });
    }
  };

  return (
    <div className="container-fluid">
      <NavBarHome dark relative />
      <div
        className="row col-md-12"
        style={{
          backgroundColor: "#0c015f",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="col-md-10 d-flex-j"
          style={{ marginTop: "10px", justifyContent: "flex-start" }}
        >
          {formules[0]?.fomula?.family?.familyName == "Smartphone" ? (
            <img
              style={{ width: "67px", marginRight: "25px" }}
              src={`${process.env.PUBLIC_URL}/assets/images/mobile-phone-blanc.svg`}
            />
          ) : (
            <img
              style={{ width: "100px", marginRight: "25px", marginTop: "25px" }}
              src={`${process.env.PUBLIC_URL}/assets/images/Casse Monture1.svg`}
            />
          )}
          <h6
            style={{
              color: "#FFFFFF",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "Century Gothic",
              marginTop: "30px",
            }}
          >
            La meilleure protection pour votre{" "}
            {formules[0]?.fomula?.family?.familyName}
          </h6>
        </div>
        <div className="col-md-9 d-flex-j ">
          {formules.map((el, index) => {
            //console.log(el);
            if (el.fomula.family.familyName === "Smartphone")
              return (
                <div className="col-md-6 body-souscrire">
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "4%",
                    }}
                  >
                    <div className="col-md-6 box-offer-souscrire">
                      <h4>Formule</h4>
                      <img
                        className="img-formule"
                        src={
                          `${process.env.PUBLIC_URL}/assets/images/` +
                          el.fomula.name +
                          ".svg"
                        }
                        style={{ width: "50%" }}
                      />
                      <div className="d-flex">
                        <h2
                          style={{
                            fontSize: "28px",
                            fontWeight: "bold",
                            marginTop: "10%",
                            marginBottom: "1%",
                            fontFamily: "Century Gothic",
                          }}
                        >
                          {el.fomula.price?.toFixed(3)} DT / mois
                        </h2>
                        <h6
                          style={{
                            fontSize: "17.5px",
                            fontWeight: "bold",
                            marginBottom: "16px",
                          }}
                        >
                          {(el.fomula.price * 12)?.toFixed(3)} DT / an
                        </h6>
                        {el.listBreakDown?.map((br, ke) =>
                          br.value ? (
                            <div className="row col-md-12 convention-formule">
                              <i class="fa fa-check sous col-md-2"></i>
                              <h6
                                key={ke}
                                className="col-md-10"
                                style={{
                                  fontSize: "15.5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {br.name}
                              </h6>
                            </div>
                          ) : (
                            <></>
                          )
                        )}
                        <div className="row col-md-12 convention-formule">
                          <i class="fa fa-check sous col-md-2"></i>
                          <h6
                            className="col-md-10"
                            style={{ fontSize: "15.5px", fontWeight: "bold" }}
                          >
                            1 intervention /an
                          </h6>
                        </div>
                      </div>
                      <h6
                        style={{
                          fontSize: "17px",
                          fontWeight: "600",
                          marginTop: "30px",
                          color: "#0c015f",
                        }}
                      >
                        Plafond de garantie {el.fomula.ceilingGuarantee} DT
                      </h6>
                    </div>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="col-md-6"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        className="btn-souscrire"
                        key={index}
                        onClick={() => subscribe(index)}
                      >
                        <h4
                          style={{
                            font: "normal normal bold 14px/40px Century Gothic",
                            fontWeight: "bold",
                            color: "#FFFFFF",
                          }}
                        >
                          Souscrire
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="col-md-6"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="btn-protegon">
                        <h4
                          style={{
                            fontFamily: "Century Gothic",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#180070",
                          }}
                          onClick={() => {
                            window.scrollTo(0, 2900);
                          }}
                        >
                          Ce que nous protégeons
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              );
            if (el.fomula.family.familyName === "Optique")
              return (
                <div className="col-md-6 body-souscrire">
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "4%",
                    }}
                  >
                    <div className="col-md-6 box-offer-souscrire">
                      <h4>Formule</h4>
                      <img
                        className="img-formule"
                        src={
                          `${process.env.PUBLIC_URL}/assets/images/` +
                          el.fomula.name +
                          ".svg"
                        }
                        style={{ width: "50%" }}
                      />
                      <div className="d-flex">
                        <h2
                          style={{
                            fontSize: "28px",
                            fontWeight: "bold",
                            marginTop: "10%",
                            marginBottom: "1%",
                            fontFamily: "Century Gothic",
                          }}
                        >
                          {el.fomula.price?.toFixed(3)} DT / mois
                        </h2>
                        <h6
                          style={{
                            fontSize: "17.5px",
                            fontWeight: "bold",
                            marginBottom: "16px",
                          }}
                        >
                          {(el.fomula.price * 12)?.toFixed(3)} DT / an
                        </h6>
                        <div className="row col-md-12 convention-formule">
                          <i class="fa fa-check sous col-md-2"></i>
                          <h6
                            className="col-md-10"
                            style={{ fontSize: "15.5px", fontWeight: "bold" }}
                          >
                            {el?.fomula?.name == "El Aila" ||
                            el?.fomula?.name == "Al awel"
                              ? "Lunettes de vue "
                              : "Lunettes de vue et solaires"}
                          </h6>
                        </div>
                        <div className="row col-md-12 convention-formule">
                          <i class="fa fa-check sous col-md-2"></i>
                          <h6
                            className="col-md-10"
                            style={{ fontSize: "15.5px", fontWeight: "bold" }}
                          >
                            {el?.fomula?.name == "El Aila"
                              ? "4 paires couvertes"
                              : "1 paire couverte"}
                          </h6>
                        </div>
                        <div className="row col-md-12 convention-formule">
                          <i class="fa fa-check sous col-md-2"></i>
                          <h6
                            className="col-md-10"
                            style={{ fontSize: "15.5px", fontWeight: "bold" }}
                          >
                            {el?.fomula?.name == "El Aila"
                              ? "4 interventions /an"
                              : "1 intervention /an"}
                          </h6>
                        </div>
                        {el.listBreakDown?.map((br, ke) =>
                          br.value ? (
                            <div className="row col-md-12 convention-formule">
                              <i class="fa fa-check sous col-md-2"></i>
                              <h6
                                key={ke}
                                className="col-md-10"
                                style={{
                                  fontSize: "15.5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {br.name}
                              </h6>
                            </div>
                          ) : (
                            <></>
                          )
                        )}
                      </div>
                      <h6
                        style={{
                          fontSize: "17px",
                          fontWeight: "bold",
                          marginTop: "30px",
                        }}
                      >
                        Plafond de garantie {el.fomula.ceilingGuarantee} DT
                      </h6>
                    </div>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="col-md-6"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        className="btn-souscrire"
                        key={index}
                        onClick={() => subscribe(index)}
                      >
                        <h4
                          style={{
                            font: "normal normal bold 14px/40px Century Gothic",
                            fontWeight: "bold",
                            color: "#FFFFFF",
                          }}
                        >
                          Souscrire
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="col-md-6"
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={() => window.scrollTo(0, 2900)}
                    >
                      <div className="btn-protegon">
                        <h4
                          style={{
                            fontFamily: "Century Gothic",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#180070",
                          }}
                        >
                          Ce que nous protégeons
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      </div>
      <div className="row col-md-12 d-flex-j-c margin0">
        <div className="col-md-10">
          <h6 className="title-bloc-couvert1">Comment ça fonctionne ?</h6>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            <div className="col-md-1 col-sm-6 bloc-sinistre-boostrap"></div>
            <div className="col-md-2 col-sm-6 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <img
                  style={{ width: "145px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Icone1.svg`}
                />
                <p
                  className="parahraphe-couvert"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Je crée mon compte en 2 mins
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <img
                  style={{ width: "145px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Icone2.svg`}
                />
                <p
                  className="parahraphe-couvert"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Laissez vous guider et protégez vos équipements en quelques
                  clics
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <img
                  style={{ width: "145px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Icone3.svg`}
                />
                <p
                  className="parahraphe-couvert"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  J’ajoute des équipements quand je veux
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <img
                  style={{ width: "145px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Icone4.svg`}
                />
                <p
                  className="parahraphe-couvert"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Je déclare mes sinistres en ligne ou par tel, réponse sous 24h
                  !
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <img
                  style={{ width: "145px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Icone5.svg`}
                />
                <p
                  className="parahraphe-couvert"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Mon équipement est réparé, remplacé ou remboursé
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 aventage-Garantie">
        <div className=" col-md-12 col-lg-10 col-sm-11 col-10 gr-container">
          <div className="row  col-md-12 col-lg-12 col-sm-12 col-12 ">
            <h1 className="text-0">Qu’est-ce qui rend Garanty unique ?</h1>
            <div className="col-md-6 padding1">
              <div className="margin">
                <h1 className="text-font">
                  100%
                  <br />
                  Digitale
                </h1>
                <br />
                <p className="color-font-gra">
                  Vous pouvez souscrire votre contrat totalement en ligne. Notre
                  modèle est 100% digital et approuvé par des algorithmes et des
                  équipes disponibles et a votre service.
                </p>
              </div>
            </div>
            <div className="col-md-6 padding d-flex-a">
              <img
                className="width95 img-avantage1"
                src={`${process.env.PUBLIC_URL}/assets/images/100 Digital.png`}
              />
            </div>
          </div>
          <div className="row col-md-12 row-reverse margin">
            <div className="col-md-6 padding1">
              <div className="margin">
                <h1 className="text-font">
                  100% adapté
                  <br />à vos besoins
                </h1>
                <br />
                <p className="color-font-gra">
                  L'assurance personnalisable et adaptée qui vous protège contre
                  les risques du quotidien.
                </p>
              </div>
            </div>
            <div className="col-md-6 padding d-flex-a">
              <img
                className="width95 img-avantage1"
                src={`${process.env.PUBLIC_URL}/assets/images/100 adaptes a vos besoin.png`}
              />
            </div>
          </div>
          <div className="row col-md-12  margin">
            <div className="col-md-6 padding1">
              <div className="margin">
                <h4 className="text-font">
                  Garanty , c'est aussi simple que ça.
                </h4>
                <br />
                <p className=" color-font-gra">
                  Une couverture sur laquelle vous pouvez compter, de la
                  protection de votre appareil à l'assistance de nos experts
                  7j/7.
                </p>
              </div>
            </div>
            <div className="col-md-6 padding d-flex-a">
              <img
                className="width95 img-avantage"
                src={`${process.env.PUBLIC_URL}/assets/images/7j 7j.png`}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 d-flex-j-c"
        style={{ backgroundColor: "#99ede9" }}
      >
        <div
          className="col-md-8"
          style={{ marginTop: "40px", marginBottom: "40px" }}
        >
          <h6 className="title-bloc-couvert">Ce qui est couvert</h6>
          {formules[0]?.fomula?.family?.familyName == "Smartphone" ? (
            <div
              className="row col-md-12 col-sm-12"
              style={{ marginRight: "0", marginTop: "50px" }}
            >
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert"
                  src={`${process.env.PUBLIC_URL}/assets/images/12.png`}
                />
                <h6 className="title-parahraphe-couvert">
                  Oxydation & Humidité
                </h6>
                <p className="parahraphe-couvert">
                  Un choc thermique important qui crée de la condensation ou
                  simplement l’humidité d’une salle de bain peuvent suffire à
                  oxyder les composants internes de votre smartphone.
                  L’oxydation empêche la circulation électrique dans votre
                  portable, qui devient inutilisable. Mais pas de soucis, vous
                  êtes aussi couverts !
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert"
                  src={`${process.env.PUBLIC_URL}/assets/images/21.png`}
                />
                <h6 className="title-parahraphe-couvert">Infiltration</h6>
                <p className="parahraphe-couvert">
                  Malheureusement, tous les modèles ne sont pas waterproof… Nous
                  réparons ou remplaçons votre smartphone en cas de dommages dus
                  à des infiltrations. Cependant sachez que les dégâts liés à
                  une chute dans une piscine ou dans des toilettes ne sont pas
                  couverts.
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert"
                  src={`${process.env.PUBLIC_URL}/assets/images/31.png`}
                />
                <h6 className="title-parahraphe-couvert">
                  Écran Cassé<span style={{ fontSize: "14px" }}>*</span>
                </h6>
                <p className="parahraphe-couvert">
                  Vous avez fait tomber votre téléphone accidentellement et il
                  ne fonctionne plus ? Déclarez simplement votre sinistre en
                  ligne ou par téléphone . Une fois votre déclaration approuvée,
                  un de nos réparateurs réparera ou remplacera votre smartphone.
                  À noter que les dommages purement esthétiques ne rentrent pas
                  dans cette catégorie.
                  <p
                    className="parahraphe-couvert"
                    style={{
                      fontSize: "11px",
                      fontFamily: "Century Gothic",
                    }}
                  >
                    * Hors formule AL AWAL
                  </p>
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert"
                  src={`${process.env.PUBLIC_URL}/assets/images/41.png`}
                />
                <h6 className="title-parahraphe-couvert">Court-Circuit</h6>
                <p className="parahraphe-couvert">
                  Un choc thermique important qui crée de la condensation ou
                  simplement l'humidité d'une salle de bain peuvent suffir à
                  oxyder les composants internes de votre smartphone
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert"
                  src={`${process.env.PUBLIC_URL}/assets/images/51.png`}
                />
                <h6 className="title-parahraphe-couvert">Casse accidentelle</h6>
                <p className="parahraphe-couvert">
                  Votre téléphone est assuré dans son intégralité. Au même titre
                  que l'écran, les boutons et pièces extérieures sont couverts
                  par notre garantie, si les dégâts reçus ne vous permettent
                  plus d'utiliser votre smartphone correctement. Soit on répare
                  l'appareil, soit on le remplace par un autre appareil, égal ou
                  équivalent.
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert"
                  src={`${process.env.PUBLIC_URL}/assets/images/61.png`}
                />
                <h6 className="title-parahraphe-couvert">
                  Face arrière cassé<span style={{ fontSize: "14px" }}>**</span>
                </h6>
                <p className="parahraphe-couvert">
                  Un choc thermique important qui crée de la condensation ou
                  simplement l'humidité d'une salle de bain peuvent suffir à
                  oxyder les composants internes de votre smartphone
                  <p
                    className="parahraphe-couvert"
                    style={{
                      fontSize: "11px",
                      fontFamily: "Century Gothic",
                    }}
                  >
                    ** Hors formule AL AWAL et RAHTI
                  </p>
                </p>
              </div>
            </div>
          ) : (
            <div
              className="row col-md-12 col-sm-12"
              style={{ marginRight: "0", marginTop: "50px" }}
            >
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert1"
                  src={`${process.env.PUBLIC_URL}/assets/images/Casse tte cause.svg`}
                />
                <h6 className="title-parahraphe-couvert">
                  Casse toutes causes
                </h6>
                <p className="parahraphe-couvert">
                  Les frais de remplacement ou de réparations de vos optiques
                  présentent des coûts élevés, nous couvrons l’ensemble de votre
                  équipement optique, montures et verres, à partir de la date
                  d’achat de vos lunettes.
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert1"
                  src={`${process.env.PUBLIC_URL}/assets/images/Broken optique.svg`}
                />
                <h6 className="title-parahraphe-couvert">Casse Verre</h6>
                <p className="parahraphe-couvert">
                  La casse de vos verres peut se révéler particulièrement
                  incommodante et inconfortable. Avec une assurance optique,
                  finit les dépenses imprévues qui grèvent le budget
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert1"
                  src={`${process.env.PUBLIC_URL}/assets/images/Casse Monture.svg`}
                />
                <h6 className="title-parahraphe-couvert">Casse Monture</h6>
                <p className="parahraphe-couvert">
                  Si vos montures sont cassées par un tiers (par exemple un de
                  vos amis s'est assis dessus ou un camarade de classe de votre
                  enfant lui a cassé), ne vous inquiétez pas Garanty prend en
                  chargent les frais de réparations ou de remplacements de vos
                  montures.
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert1"
                  src={`${process.env.PUBLIC_URL}/assets/images/assistance.svg`}
                />
                <h6 className="title-parahraphe-couvert">
                  Des avantages exclusifs
                </h6>
                <p className="parahraphe-couvert">
                  Garanty propose une gamme d’assurance optique complète et
                  évolutive. Elle couvre aussi bien vos montures que vos verres
                  correcteurs. Pour assurer les premières lunettes de vos
                  enfants où pour garantir vos montures, les différentes
                  formules d’assurance optique Garanty s’adaptent à vos besoins.
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert1"
                  src={`${process.env.PUBLIC_URL}/assets/images/Offre el 3ayla.svg`}
                />
                <h6 className="title-parahraphe-couvert">Offre El 3AYLA</h6>
                <p className="parahraphe-couvert">
                  Afin de vous apporter la meilleure garantie de service et
                  assurer au mieux votre investissement, pour seulement 138DT
                  par an, la formule El 3ayla vous permet de couvrir 4 paires de
                  lunettes optiques du foyer.
                </p>
              </div>
              <div className="col-md-4 col-sm-6 bloc-paragraphe-couvert">
                <img
                  className="image-parahraphe-couvert1"
                  src={`${process.env.PUBLIC_URL}/assets/images/Rembourssement.svg`}
                />
                <h6 className="title-parahraphe-couvert">Économique</h6>
                <p className="parahraphe-couvert">
                  En cas de casse, nous prenons en charge les frais de
                  réparations ou de remplacement de vos lunettes optiques ou
                  solaires. Garant vous permet de réaliser des économies de dès
                  la première année.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex-center row col-md-12">
        <div className="flex-center col-md-11">
          <div
            className="flex-center col-md-3"
            style={{ flexDirection: "column", padding: "10px" }}
          >
            <img
              style={{ width: "100%", maxWidth: "350px" }}
              src={`${process.env.PUBLIC_URL}/assets/images/img1.jpg`}
              alt=""
            />
            <h6 className="txt">
              Toute la sécurité que Garanty peut vous offrir
            </h6>
            <p className="txt-p">
              Tous nos services de protection de vos équipements peuvent être
              achetés en ligne ou en magasin.
            </p>
          </div>
          <div
            className="flex-center col-md-3"
            style={{ flexDirection: "column", padding: "10px" }}
          >
            <img
              style={{ width: "100%", maxWidth: "350px" }}
              src={`${process.env.PUBLIC_URL}/assets/images/img Delivery.jpg`}
              alt=""
            />
            <h6 className="txt">
              Livraison et retrait rapide de votre équipement à domicile
            </h6>
            <p className="txt-p">
              Vous n'avez même pas besoin de quitter la maison.
            </p>
          </div>
          <div
            className="flex-center col-md-3"
            style={{ flexDirection: "column", padding: "10px" }}
          >
            <img
              style={{ width: "100%", maxWidth: "350px" }}
              src={`${process.env.PUBLIC_URL}/assets/images/img3.jpg`}
              alt=""
            />
            <h6 className="txt">Couverture totale contre la casse</h6>
            <p className="txt-p">
              L'assurance téléphone portable est un moyen d'assurer votre
              téléphone portable en cas d'endommagement de votre téléphone.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Souscrire;

import React, { useEffect, useState } from "react";
import InputDate from "../../SubComponents/inputCustom/InputDate";
import ButtonsWrapper from "../../SubComponents/ButtonsWrapper ";
import "../Pages/CreateSinistre.css"
import { useHistory } from "react-router";
import contractService from "../../../services/contract/contractService";
import adminService from "../../../services/adminService/adminService";
import customerService from "../../../services/customer/customerService";
import CardPanne from "../components/CardPanne";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { setDefaultLocale } from "react-datepicker";
import { toast } from "react-toastify";

const CreateSinistre = (props) => {
  const history = useHistory();
  const [idcontractP, setIdContractP] = useState();
  const [breakDown, setBreakDown] = useState();
  const [data, setData] = useState();
  const [family, setFamily] = useState("");
  const [list, setList] = useState([]);
  const [listBreakDown, setListBreakDown] = useState({});
  const [phone, setPhone] = useState({ newPhone: "" });
  const [check, setCheck] = useState(true);
  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("user")).id;
    customerService.getCustomerById(id).then((res) => {
      setData(res.data);
    });
    contractService.getAllContractsByCustomer(id).then((response) => {
      setList(response.data);
    });
  }, []);

  const handelChange = (e) => {
    const { name, value } = e.target;
    contractService.getContractbyId(value).then((res) => {
      setFamily(res?.data?.formula?.family?.familyName);
      adminService
        .getAllBreakdownbyformula(res.data?.formula?.idformula)
        .then((response) => {
          setBreakDown(response.data);
        });
      adminService
        .getcontractProductByIdContract(res.data?.idcontract)
        .then((res) => {
          setIdContractP(res.data[0]?.idcontractProduct);
        });
    });
  };
  const handelPhoneChange = (e) => {
    const { name, value } = e.target;
    setPhone((phone) => ({ ...phone, [name]: value }));
  };

  const handelChangeBreak = (e) => {
    const { name, value } = e.target;
    const checked = e.target.checked;
    if (checked) {
      setListBreakDown((listBreakDown) => ({
        ...listBreakDown,
        [name]: value,
      }));
    }
  };
  console.log(phone);
  const onSubmit = () => {
    if (!idcontractP) {
      toast.error("Veuillez sélectionner un contrat");
    } else {
      history.push({
        pathname: "/dashboard/sinistre/gen-sinistre",
        state: {
          idCP: idcontractP,
          listBre: listBreakDown,
          phone: phone.newPhone,
          prod: family,
        },
      });
    }
  };
  const onCancel = () => {
    history.push("/dashboard/sinistre");
  };
  return (
    <div className="body-sinistre">
      <h4
        style={{
          font: "normal normal bold 25px/27px Century Gothic",
          color: "#0C015F",
        }}
      >
        Déclaration de sinistre :
      </h4>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="row col-lg-10 col-md-10 col-sm-10 col-10 space">
          <div className="col-md-6 d-flex flex-d-c mb-3">
            <label className="label">
              Contrat N°<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="Numéro de contrat"
              className="input-form"
              style={{ color: "black", fontSize: "large" }}
              onChange={handelChange}
              defaultValue={list[0]?.idcontract}
            >
              <option value="Sélectionnez votre contrat...">
                Sélectionnez votre contrat...
              </option>
              {list ? (
                list?.map((el, i) => {
                  return (
                    <option value={el.idcontract}>{el.formattedName}</option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </div>
        </div>

        <h4
          style={{
            font: "normal normal bold 25px/27px Century Gothic",
            color: "#0C015F",
            marginTop: "20px",
          }}
        >
          Choisissez la typologie du sinistre :
        </h4>
        <div className="row col-lg-11 col-md-11 col-sm-11 col-11">
          {breakDown?.map((el, key) => {
            return (
              <CardPanne
                key={key}
                name={el.breakdownType.name}
                value={el.breakdownType.idbreakdown}
                onChange={handelChangeBreak}
              />
            );
          })}
        </div>
        <h4
          style={{
            font: "normal normal bold 25px/27px Century Gothic",
            color: "#0C015F",
            marginTop: "20px",
          }}
        >
          Etes vous joignable sur ce téléphone ?
        </h4>
        <div className="row col-lg-10 col-md-10 col-sm-10 col-10 space">
          <div
            className="col-md-6 d-flex-j flex-d-c"
            style={{ paddingRight: 0 }}
          >
            <input
              style={{
                color: "black",
                marginBottom: "14px",
                height: "54px",
                fontSize: "medium",
              }}
              className="input-form"
              name="phone"
              type="text"
              defaultValue={data?.phone}
              onChange={handelPhoneChange}
              disabled
            />
          </div>
          <label class="checkbox-div" style={{ height: "25px" }}>
            Oui
            <input
              type="checkbox"
              checked={check}
              onClick={() => {
                setCheck(!check);
              }}
            />
            <span class="checkmark"></span>
          </label>
          {check ? (
            <></>
          ) : (
            <div
              className="col-md-6 d-flex-j flex-d-c"
              style={{ paddingRight: 0, marginTop: "15px" }}
            >
              <label className="label">
                Merci de nous indiquer un numéro sur lequel nous pourrions vous
                joindre
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{
                  color: "black",
                  marginBottom: "14px",
                  height: "54px",
                  fontSize: "medium",
                }}
                className="input-form"
                name="newPhone"
                type="number"
                value={phone.newPhone}
                onChange={handelPhoneChange}
              />
            </div>
          )}
        </div>
        <div className="row col-lg-11 col-md-11 col-sm-11 col-11 space">
          <div className="col-lg-6"></div>
          <div
            className="col-lg-6"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ButtonsWrapper onSubmit={onSubmit} onCancel={onCancel} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSinistre;

import { sinistreAxios } from "../../axiosConfig/InstancesAxios";

export default {
  createSinistre: (Model) => {
    return sinistreAxios.post("/createSinistre", Model);
  },

  getSinistreByCustomer: (Id_customer) => {
    return sinistreAxios.post("/getSinistreByCustomer", null, {
      params: { Id_customer },
    });
  },
};

import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { addDays } from "date-fns";
import { subDays } from "date-fns";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
function InputDate({ name, type, label, onchange, selected, ...props }) {
  return (
    <div className="col-md-6 d-flex flex-d-c mb-3">
      <label className="label">
        {label}
        <span style={{ color: "red" }}>*</span>
      </label>
      <DatePicker
        style={{ paddingLeft: "10px" }}
        className="input-form"
        name={name}
        placeholderText={label}
        selected={selected}
        dateFormat="dd/MM/yyyy"
        locale="fr"
        maxDate={addDays(new Date(), 0)}
        onChange={onchange}
        {...props}
      />
    </div>
  );
}

export default InputDate;
